
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './menuSearchPage.css';
import { useEffect, useState } from 'react';
import * as REQUEST_REQUIREMENTS from '../../../app/api/requestRequirements';

interface MenuSearchPageProps {
    optionClicked:  string | undefined;
    onClickOption: (option: string) => void;
  }


export default function MenuSearchPage({optionClicked, onClickOption}: MenuSearchPageProps){

    return(
            <>
                <div id='container-menu-search-page'>
                    <ul>
                        <li id={optionClicked === 'users' ? 'selected-option-search-menu' : 'deselected-option-search-menu'}
                            onClick={() => {onClickOption('users')}}>
                            <h1>users</h1>
                        </li>
                        <li id={optionClicked === 'groups' ? 'selected-option-search-menu' : 'deselected-option-search-menu'}
                            onClick={() => {onClickOption('groups')}}>
                            <h1>groups</h1>
                        </li>
                        <li id={optionClicked === 'projects' ? 'selected-option-search-menu' : 'deselected-option-search-menu'}
                            onClick={() => {onClickOption('projects')}}>
                            <h1>
                                projects
                            </h1>
                        </li>
                    </ul>
                </div>
            </>
    )
}