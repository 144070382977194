import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSearchBar, getUserNotificationsByUserNickname } from "../../api/sessionAPI"
import { AuthHeaders, Notification } from "../../#interfaces/interfaces";

export interface GetUserNotificationsRequestParams{
    authHeaders: AuthHeaders;
  }

export interface GetUserNotificationsUrlParams{
    currentUserId?: number;
}

interface UserNotifications {
    notifications: Notification[];
}

const initialState: UserNotifications = {
    notifications: []
}

export const getUserNotifications = createAsyncThunk(
    'sessionNotifications/getUserNotifications',
    async (payload: GetUserNotificationsRequestParams, {rejectWithValue}) => {
        const response = await getUserNotificationsByUserNickname(
            payload.authHeaders
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
)

const notificationsSlice = createSlice({
    name: 'sessionNotifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getUserNotifications.pending, (state) => {
        console.log("getUserNotifications.pending")
      })
      .addCase(getUserNotifications.fulfilled, (state, action: any) => {
        console.log("getUserNotifications.fulfilled", action)
      })
      .addCase(getUserNotifications.rejected, (state) => {
        console.log("getUserNotifications.rejected")
      })
      }
    })

export const notificationsSliceReducers = notificationsSlice.reducer
export const notificationsSliceActions = notificationsSlice.actions