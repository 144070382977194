import React from 'react';
import { PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { editContentInProject, getContentsInProject, getProjects, postProject } from '../../../api/sessionAPI';
import { Content, User } from '../../../#interfaces/interfaces';

export interface ProjectContentsRouteParams {
  userNicknameRoute?: any;
  projectIdRoute?: any;
}

export interface GetProjectsRequestData{
  authHeaders: AuthHeaders;
  urlParams: GetProjectsRouteParams;
}

export interface GetProjectsRouteParams{
  userNickname: string;
  page: number;
}

export interface CreateProjectHeaderBody{
  authHeaders: AuthHeaders;
  currentUser: User;
  postBody: PostProjectBody;
}

export interface GetProjectContentsData{
  authHeaders?: AuthHeaders;
  userNickname?: string;
  projectId?: string;
}

export interface PostProjectBody{
  title?: string;
  description?: string;
  privacity?: number;
}

export interface PatchProjectContentData {
  authHeaders?: AuthHeaders;
  projectContentsRouteParams?: ProjectContentsRouteParams;
  patchProjectContentBody?: PatchProjectBody;
} 

export interface PatchProjectBody {
  id?: number;
  startsIn?: number;
  useful?: boolean;
}

export interface CreateProjectData{
    authHeaders?: AuthHeaders;
    currentUser?: User; 
}

export interface AuthHeaders {
    accept?: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  }

export interface AuthHeaders {
    accept?: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  }

export interface Project {
    project: ProjectInfo;
    masterFiles: MasterFiles[];
}

export interface MasterFiles{
  createdAt: string;
  signedUrl: string;
}

export interface ProjectInfo{
  id: number;
  title?: string;
  description?: string;
  privacity?: number;
  nUsefulContents?: number;
  nUselessContents?: number;
  duration?: number;
  lengthVisits?: number;
  ownerId?: number;
  createdAt?: string;
  updatedAt?: string;
  loading?: boolean;
  user: User;
}

export interface ProjectAudioContent {
    content?: Content;

    id?: number;
    typeId?: number;
    contentId?: number;
    projectId?: number;
    startsIn?: number;
    volume?: number;
    createdAt?: string;
    updatedAt?: string;
    description?: string;
    fileUrl?: string;
    title?: string;
    useful?: string;
}

export interface ProjectAudioContents {
    audioContents?: ProjectAudioContent[];
}

interface ManageProjects {
    postRequeriments: CreateProjectHeaderBody;
    userRoutePublicProjects: Project[];
    userRoutePrivateProjects: Project[];
    getProjectContentsRequeriments: GetProjectContentsData;
    currentProjectContents: ProjectAudioContents;
    usefulContents: ProjectAudioContent[];
    uselessContents: ProjectAudioContent[];
    newPublicProjects: Project[];
    newPrivateProjects: Project[];
    alienPublicProjects: Project[];
    alienPrivateProjects: Project[];
    allAlienProjects: Project[];
    allUserRouteProjects: Project[];
    allProjects: Project[];
    allPublicProjects: Project[];
    allPrivateProjects: Project[];
    errors: [],
    loadingMoreProjects: boolean;
    loadingCreation: boolean;
    loading?: boolean;
}
  
const initialState: ManageProjects = {
    postRequeriments: {
        authHeaders: {
            accept: undefined,
            accessToken: undefined,
            client: undefined,
            uid: undefined
        },
        currentUser: {
            email: undefined,
            provider: undefined,
            uid: undefined,
            id: undefined,
            allowPasswordChange: undefined,
            name: undefined,
            // profileImage: undefined,
            nickname: undefined,
            role: undefined
    
        },
        postBody: {
            title: undefined,
            description: undefined,
            privacity: undefined
        }
    },
    getProjectContentsRequeriments:{
        authHeaders: {
            accept: undefined,
            accessToken: undefined,
            client: undefined,
            uid: undefined
        },
        userNickname: undefined,
        projectId: undefined
    },
    currentProjectContents:{
        audioContents: []
    },
    alienPublicProjects: [],
    alienPrivateProjects: [],
    allAlienProjects: [],
    allProjects: [],
    allUserRouteProjects: [],
    allPublicProjects: [],
    allPrivateProjects: [],
    usefulContents: [],
    uselessContents: [],
    newPublicProjects: [],
    newPrivateProjects: [],
    userRoutePublicProjects: [],
    userRoutePrivateProjects: [],
    errors: [],
    loadingCreation: false,
    loadingMoreProjects: false,
    loading: false
};


export const getUserProjects = createAsyncThunk(
    'sessionProjects/getUserProjects',
    async (payload: GetProjectsRequestData, {rejectWithValue}) => {
        const response = await getProjects(
            payload.authHeaders,
            payload.urlParams
        );
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
)

export const createProject = createAsyncThunk(
    'sessionProjects/createProject',
    async (payload: CreateProjectHeaderBody, {rejectWithValue}) => {
        const response = await postProject(
            payload.authHeaders,
            payload.currentUser,
            payload.postBody
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
        
    }
)

const projectsSlice = createSlice({
    name: 'sessionProjects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getUserProjects.pending, (state, action: any) => {
        state.loadingMoreProjects = true;

        if (action.meta.arg.urlParams.page === 1){
          state.allProjects = [];
          state.newPublicProjects = [];
          state.newPrivateProjects = [];
        }

        state.loading = true
      })
      .addCase(getUserProjects.fulfilled, (state, action: any) => {
        const payloadConverted = convertKeysToCamelCase(action.payload)
        state.loadingMoreProjects = false;

        state.allProjects = [...state.allProjects,...sortAndMergeGroups(payloadConverted.userRoutePublicPrivate[0],
                                                                             payloadConverted.userRoutePublicPrivate[1],
                                                                             payloadConverted.userRouteContributorPublicPrivate[0],
                                                                             payloadConverted.userRouteContributorPublicPrivate[1])];
        state.loading = false;
      })
      .addCase(createProject.pending, (state, action: any) => {
        state.loadingCreation = true;
      })
      .addCase(createProject.fulfilled, (state, action: any) => {

        state.currentProjectContents.audioContents = action.payload
        const newProject = convertKeysToCamelCase(action.payload);

        if (action.meta.arg.postBody.privacity == 0){
          state.newPublicProjects = [newProject,...state.newPublicProjects]
        } else if (action.meta.arg.postBody.privacity == 1) {
          state.newPrivateProjects = [newProject,...state.newPrivateProjects]
        }


        state.loadingCreation = false;
      })
      .addCase(createProject.rejected, (state, action: any) => {
        state.errors = action.payload;
        state.loadingCreation = false;
      })
      }
    })

export const manageProjectsSliceReducers = projectsSlice.reducer
export const manageProjectsSliceActions = projectsSlice.actions

export function convertKeysToCamelCase(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToCamelCase(item));
      } else if (obj.constructor === Object) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
              letter.toUpperCase()
            );
            newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
          }
        }
        return newObj;
      }
    }
    return obj;
}

export function sortAndMergeGroups(...lists: any[][]): any[] {
  const mergedList = ([] as any[]).concat(...lists);

  const sortMasterFiles = (obj: any) => {
      if (obj.masterFiles && Array.isArray(obj.masterFiles)) {
          obj.masterFiles.sort((a: any, b: any) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB.getTime() - dateA.getTime();
          });
      }
  };

  mergedList.forEach(sortMasterFiles);

  const mergedListConverted = convertKeysToCamelCase(mergedList);
  return mergedListConverted;
}
