import React, { useEffect, useState } from 'react';
import './projectContentsSelection.css'; // Importe o arquivo CSS para aplicar o estilo
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { useParams } from 'react-router-dom';
import { selectDeselectProjectPost } from '../../../features/sessions/sessionPostProject/sessionPostProject';

export function SelectProjectPost({projectId}: any) {
  const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
  const projectSelectedId = useSelector((state: RootState) => state.sessionProjectPosts.projectSelectedId);
  const [isCurrentProjectSelected, setIsCurrentObjectSelected] = useState(projectSelectedId === projectId);
  const { userNicknameRoute } = useParams();
  const [contentSelectedBool, setContentSelectedBool] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const somethingSelected = useSelector((state: RootState) => state.sessionProjectPlayerManagement.somethingSelected);
  const [ projectSelected, setProjectSelected] = useState(false);
   
  useEffect(() => {

    setIsCurrentObjectSelected(projectSelectedId == projectId);

  },[projectSelectedId])

  async function handleSelectAttachProject() {

      const response = await dispatch(selectDeselectProjectPost(projectId));
  }

  return (
    <div id='container-checkbox'>
      <label className={`checkbox-container ${(isCurrentProjectSelected) ? 'selected' : ''}`}>
        <input
          type='checkbox'
          className='checkbox-button-project'
          onChange={handleSelectAttachProject}
          checked={isCurrentProjectSelected}
        />
        <span className='checkmark'></span>
      </label>
    </div>
  );
}
