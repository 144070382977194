import React from 'react';
import { useEffect, useState } from 'react'
import { GeneralUserConfigForm } from '../../../../app/components/forms/userConfig/GeneralUserConfigForm'
import { UserConfigSideBar } from '../../../../app/components/sideBar/UserConfigSideBar'
import './userConfigurations.css'
import { PrivacityUserConfigForm } from '../../../../app/components/forms/userConfig/PrivacityUserConfigForm'
import { AccessibilityUserConfigForm } from '../../../../app/components/forms/userConfig/AccessibilityUserConfigForm';
import { AppDispatch, RootState } from '../../../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingModal from '../../../../app/components/modal/loadingModal/LoadingModal';
import { getUserGenericConfig } from '../../../../app/features/sessions/sessionConfig/sessionUserConfigurations';
import * as REQUEST_REQUIREMENTS from '../../../../app/api/requestRequirements';

export function UserConfigurations(){
    const { userNicknameRoute } = useParams<string>();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const loading = useSelector((state: RootState) => state.sessionUserConfig.loading);
    const userMoreInfo = useSelector((state: RootState) => state.sessionUserConfig.userMoreInfo);
    const navigate = useNavigate();
    const [sideBarOption, setSideBarOption] = useState('general');

    useEffect(()=>{

        function ensureCurrentUser(){
            if (userNicknameRoute != currentUser?.nickname){

                const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: {userNickname: currentUser?.nickname} })

                navigate(PRIVATE_ROUTES.HOME)
                }

            }

        async function getUserMoreInfo(){
            if (authHeaders && currentUser && currentUser.nickname) {
                const response = await dispatch(getUserGenericConfig({authHeaders: authHeaders, userNickname: currentUser?.nickname}));
            }
        }

        // function getUserPrivacity(){
        //     if (authHeaders && currentUser && currentUser.nickname) {
            
        //         dispatch(getUserGenericConfig({authHeaders: authHeaders, userNickname: currentUser?.nickname}));
        //     }
        // }
        ensureCurrentUser();

        // getUserPrivacity();
        getUserMoreInfo();

    },[])

    function handleChangeSideBarOption(option: string){
        setSideBarOption(option)
    }

    return(
        <>
        {loading?
            <LoadingModal/>
            :
            <div id="container-edit-user-configurations-informations">
                <div id='container-left-edit-configurations'>
                    <UserConfigSideBar setOptionClicked={handleChangeSideBarOption}
                                    optionSelected={sideBarOption}/>
                </div>
                <div id='container-center-edit-configurations'>
                    <div id='container-forms-user-configurations'>
                        {sideBarOption === 'general' 
                            
                            ?

                            <GeneralUserConfigForm userMoreInfo={userMoreInfo}
                        />
                        : sideBarOption === 'privacity' ?
                        <PrivacityUserConfigForm  />
                        :
                        <AccessibilityUserConfigForm/>}
                    </div>

                </div>
            </div>
        }
        </>
    )
}