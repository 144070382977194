import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import './myContentsUploadForm.css';
import { getInstruments, uploadContent } from "../../../features/sessions/sessionAudioContent/sliceUserContents";
import { authenticateUserFromStorage } from "../../../features/sessions/sessionSlice";
import LoadingModal from '../../modal/loadingModal/LoadingModal';
import { toast } from 'react-toastify';
import { getMinutes } from 'date-fns';
import { useParams } from 'react-router-dom';

export default function MyContentsUploadForm(){
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const errors = useSelector((state: RootState) => state.sessionUserContents.errors);
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector((state: RootState) => state.sessionUserContents.loading);
    const instrumentsForm = useSelector((state: RootState) => state.sessionUserContents.instrumentsForm);
    const [titleForm, setTitleForm] = useState<string>();
    const [descriptionForm, setDescriptionForm] = useState<string>();
    const [typeIdForm, setTypeIdForm] = useState<number>();
    const [downloadAbledForm, setDownloadAbledForm] = useState<string>('true');
    const [instrumentSelected, setInstrumentSelected] = useState<number>(1);
    const [addContentClicked, setAddContentClicked] = useState<boolean>(false);
    const { userNicknameRoute } = useParams();

    useEffect(() => {
        
        async function handleInstrumentsComboBox() {
            await dispatch(getInstruments());
        }

        handleInstrumentsComboBox();

    }, []);

    
    async function handlePostContent(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const fileInput = event.currentTarget.querySelector('input[type="file"]') as HTMLInputElement;

        if (fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const requestBody = {
                title: titleForm,
                description: descriptionForm,
                typeId: typeIdForm,
                file: file,
                downloadAbled: downloadAbledForm,
                instrumentId: instrumentSelected
            };

            const response = await dispatch(uploadContent({authHeaders, currentUser, requestBody}));

            if (response.meta.requestStatus == 'fulfilled'){
                handleBlankForm();
                setAddContentClicked(false);
            } else if (response.payload.errors && response.payload.errors.length > 0) {
                response.payload.errors.map((item: string) => {toast.error(item)})
            }
        }
    }

    function handleBlankForm(){
            setTitleForm('');
            setDescriptionForm('');
            setTypeIdForm(0);
            const fileInput = document.getElementById('fileInput') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
    }

    return(
        <>
        {/* {loading ?
            <LoadingModal/>
        : */}
            <div id='container-form-labels-create-content'>
            {addContentClicked == true
                ? 
                <form id='container-form-add-content' onSubmit={handlePostContent} encType="multipart/form-data">
                    <h3 style={{display:"flex", marginTop: "0px", marginBottom: "5px"}}>Post Content</h3>
                    <div id='container-title'>
                        <label>Title</label>
                        <input type="text" 
                            placeholder='content title'
                            name='title'
                            value={titleForm} 
                            onChange={(event)=>{setTitleForm(event.target.value)}}
                        />
                    </div>

                    <div id='container-description'>
                        <label>Description</label>
                        <textarea placeholder='Description' 
                                name='description'
                                value={descriptionForm} 
                                onChange={(event) => {setDescriptionForm(event.target.value)}}/>
                    </div>

                    <div id='container-content-type'>
                        <label>Content Type</label>
                        <div id='content-type'>
                            <input
                                type='radio'
                                name='radio'
                                value={1}
                                onChange={() => {setTypeIdForm(1)}}
                                checked={typeIdForm === 1}
                            />
                            <span>Audio</span>

                            <input
                                type="radio"
                                name="radio"
                                value={2}
                                onChange={() => {() => {console.log("content type field 2")}}}
                                checked={typeIdForm === 2}
                                disabled
                            />
                            <span style={{color: "gray"}} >Text</span>

                            <input
                                type="radio"
                                name="radio"
                                value={3}
                                onChange={() => {() => {console.log("content type field 3")}}}
                                checked={typeIdForm === 3}
                                disabled
                            />
                            <span style={{color: "gray"}} >Video</span>
                        </div>
                    </div>
                    <div id='container-download-abled'>
                        <label>Download Abled</label>
                        <div id='download-abled'>
                            <input
                                type='radio'
                                name='radio-download-abled'
                                value={'true'}
                                onChange={() => {setDownloadAbledForm('true')}}
                                checked={downloadAbledForm === 'true'}
                            />
                            <span>True</span>

                            <input
                                type="radio"
                                name="radio-download-abled"
                                value={'false'}
                                onChange={() => {setDownloadAbledForm('false')}}
                                checked={downloadAbledForm === 'false'}
                            />
                            <span>False</span>
                        </div>
                    </div>
                    <div id='container-instrument-selection'>
                        <label>Instrument</label>
                        <select name="filter-projects" onChange={(event: any) => setInstrumentSelected(event.target.value)}>
                            {instrumentsForm && instrumentsForm.map(instrument => (
                            <option key={instrument.id} value={instrument.id}>{instrument.name}</option>
                            ))}
                        </select>
                    </div>

                    <input type='file' id='fileInput'/>

                    <div id='submit-button-container'>
                        <button type='submit' id='upload-button'>Upload</button>
                    </div>
                </form>
                :
                <button id="button-open-form" onClick={()=>{setAddContentClicked(true);}}>+ Add Content</button>
            }
                
            </div>
        {/* } */}
        </>
    )
}