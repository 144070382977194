import React from 'react';

export const Duration = ({ centiseconds }: { centiseconds: number }) => {
  return (
    <time dateTime={`P${Math.round(centiseconds)}S`}>{formatTime(centiseconds)}</time>
  );
};

const formatTime = (centiseconds: number) => {
  const cs = Math.floor(centiseconds % 100);
  const ss = Math.floor((centiseconds / 100) % 60);
  const mm = Math.floor(centiseconds / 6000); 

  // Adicione zeros à esquerda se necessário
  const formattedSS = ss < 10 ? `0${ss}` : ss;
  const formattedMM = mm < 10 ? `0${mm}` : mm;
  const formattedCS = cs < 10 ? `0${cs}` : cs;

  return `${formattedMM}:${formattedSS}:${formattedCS}`;
};