import React from 'react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { authenticateUserFromStorage } from "../../../../app/features/sessions/sessionSlice";
import { getMergedAudioFile } from "../../../../app/features/sessions/sessionProjectManagementPlayer/sliceProjectManagementPlayer";
import { useParams } from "react-router-dom";
import mixProjectContentsIcon from '../../../../../src/app/assets/mix-contents-icon.png';
import { createSilentAudioBuffer, fetchAudioBuffer } from "./BufferToWave";
import Crunker from 'crunker';
import LoadingModal from '../../../../app/components/modal/loadingModal/LoadingModal';
import audioBufferToWav from 'audiobuffer-to-wav';
import './mixProjectContents.css';
import { toast } from "react-toastify";
import { getProjectContentsToMix, mixSelectedProjectContents } from '../../../../app/features/sessions/projectsManagement/sessionManageProject';


export default function MixProjectContents({setMixedAudioUrl}:any){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const currentProject = useSelector((state: RootState) => state.sessionProjectPlayerManagement.currentProject.project);
    const projectContentsSelectedToMix = useSelector((state: RootState) => state.sessionManageProjectContents.projectContentsToMix);
    const projectContentSelectedList = useSelector((state: RootState) => state.sessionManageProjectContents.projectContentSelectedList);
    const dispatch = useDispatch<AppDispatch>();
    const { userNicknameRoute, projectIdRoute } = useParams();
    const loading = useSelector((state: RootState) => state.sessionProjectPlayerManagement.loading);
    const mergedAudioUrl = useSelector((state: RootState) => state.sessionProjectPlayerManagement.mergedAudioUrl);
    const loadingSelection = useSelector((state: RootState) => state.sessionProjectPlayerManagement.loadingSelection);

    useEffect(() => {
        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }

        async function handleProjectContentsToMix() {
            await dispatch(getProjectContentsToMix({authHeaders: authHeaders, 
                                                                     userNickname: userNicknameRoute, 
                                                                     projectId: projectIdRoute, 
                                                                     listProjectContentsId: projectContentSelectedList}));
        }
        
        authenticateUser();
        handleProjectContentsToMix();
    },[])
      
    
      async function handleProjectContentsToMix() {

        setMixedAudioUrl('');

        if (projectIdRoute && projectContentSelectedList ){

            const response = await dispatch(mixSelectedProjectContents({projectId: parseInt(projectIdRoute), idListToMix: projectContentSelectedList}))

            if (response.meta.requestStatus == "rejected" && projectContentSelectedList.length == 1){
                toast.warning(response.payload.data.error);
            }

            if (response.meta.requestStatus == "fulfilled" || (response.meta.requestStatus == "rejected" && projectContentSelectedList.length == 1)){

                const response = await dispatch(getMergedAudioFile({ projectId: parseInt(projectIdRoute) }));

                if (response.meta.requestStatus == "fulfilled" || (response.meta.requestStatus == "rejected" && projectContentSelectedList.length == 1)) {
        
                    setMixedAudioUrl(response.payload['merged_file_url']);
                    return;
                }
            }
        }
      }
      
    
    return (
        <>
            {loading == true?
                
                <LoadingModal/>
                :
                <div id='container-mix-project-contents'>
                    { projectContentSelectedList.length > 0 ?
                            <button id='mix-contents-button'
                                onClick = {handleProjectContentsToMix}>
                                <img src = {mixProjectContentsIcon} style={{ width: "35px", height: "35px" }}></img>
                            </button>
                        :null
                    }
                 </div>
             }
        </>
    )
}