import React from 'react';
import LoadingAnimation from '../LoadingAnimation';
import './loadingModal.css';
import LogoRed from '../../../assets/logo-red.png'

function LoadingModal() {
    
    return(
        <div id='container-loading-modal'>
            <img src={LogoRed} alt='logo-red'/>
            <LoadingAnimation/>
        </div>
    )
}

export default LoadingModal;