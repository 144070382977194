import { PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { AuthHeaders, Instrument, Project, User, File, Content, ProjectContent } from '../../../#interfaces/interfaces';
import { editContentInProject, getProjectContentWithId, getContentsInProject, postContentInProject, 
         getContentsInProjectToMix, mixAudios, getMergedAudioFromProject, getProjectContentinProject, 
         createProjectContentCommentWithData, getProjectContentCommentsWithRequestParams, 
         deleteProjectContentCommentWithRequestParams} from '../../../api/sessionAPI';

export interface ProjectContentsRouteParams {
    userNicknameRoute?: any;
    projectIdRoute?: any;
}

export interface PatchProjectContentData {
    authHeaders?: AuthHeaders;
    projectContentsRouteParams?: ProjectContentsRouteParams;
    patchProjectContentBody?: PatchProjectBody;
}

export interface PatchProjectBody {
    id?: number;
    startsIn?: number;
}

export interface GetProjectBody {
    id?: number;
}

export interface PostProjectBody {
    contentId?: number;
}

export interface ContentId {
    id?: number;
}

export interface GetProjectContentsData {
    authHeaders?: AuthHeaders;
    userNickname?: string;
    projectId?: string;
}

export interface GetProjectContentData {
    authHeaders?: AuthHeaders;
    routeParams: GetProjectContentUrlParams;
}

export interface GetProjectContentUrlParams{
    userNickname?: string;
    projectContentId?: string;
}

export interface GetProjectContentsToMixData {
    authHeaders?: AuthHeaders;
    userNickname?: string;
    projectId?: string;
    listProjectContentsId?: number[];
}

export interface PostProjectContentData {
    authHeaders?: AuthHeaders;
    projectContentsRouteParams?: ProjectContentsRouteParams;
    postBody?: PostProjectBody;
}

export interface UpdateProjectContentData {
    authHeaders?: AuthHeaders;
    currentUser?: User;
    postProjectContentBody?: PostProjectBody;
}

export interface MixContentsData {
    projectId?: number;
    idListToMix: number[];
}

export interface GetMergedData {
    projectId?: number;
}

export interface CreateProjectContentCommentRouteParams {
    userNickname?: string;
    projectContentId?: string;
}

export interface CreateProjectContentBody {
    body?: string;
}

export interface CreateProjectContentCommentData {
    headers: AuthHeaders;
    routeParams: CreateProjectContentCommentRouteParams;
    body: CreateProjectContentBody;
}

export interface GetProjectContentCommentsUrlParams{
    userNickname: string;
    projectContentId: string;
    projectContentCommentsPage: string;
}

export interface GetProjectContentCommentsRequestInfo{
    headers: AuthHeaders;
    urlParams: GetProjectContentCommentsUrlParams;
}

export interface DeleteProjectContentCommentUrlParams{
    userNickname: string;
    projectContentId: string;
    projectContentCommentId: string;
}

export interface DeleteProjectContentCommentInfo{
    headers: AuthHeaders;
    urlParams: DeleteProjectContentCommentUrlParams;
}

export interface ProjectContentComment {
    id: number;
    body: string;
    userId: string;
    projectContentId: number;
    createdAt: string;
    createAt: string;
    user: User;
}

export interface ProjectContents {
    projectContent: ProjectContent;
    projectContents: ProjectContent[];
    currentProject: Project;
    playing: boolean;
    loading: boolean;
    loadingScreenUseful: boolean;
    loadingScreenUseless: boolean;
    projectContentSelected: number[];
    projectContentsToMix: number[];
    somethingSelected: boolean;
    errors: [];
    mergedAudioUrl: string;
    loadingSelection: boolean;
    boolUsefulProjectContentSelected: boolean;
    boolUselessProjectContentSelected: boolean;
    listUsefulSelected: number[];
    listUselessSelected: number[];
    currentProjectContentToShow?: ProjectContent;
    currentProjectContentCommentsToShow: ProjectContentComment[];
    loadingProjectContentsCommments: boolean;
    loadingProjectContentsInfo: boolean;
    lodingMoreProjectContensComments: boolean;
    newCurrentProjectContentComments: ProjectContentComment[];
}


const initialState: ProjectContents = {
    projectContent: {
        content: {
            createdAt: undefined,
            description: undefined,
            id: undefined,
            title: undefined,
            typeId: undefined,
            updatedAt: undefined,
            userId: undefined,
            instrument: undefined
            },
            project : {
                project:{
                    createdAt: undefined,
                    description: undefined,
                    id: undefined,
                    nUsefulContents: undefined,
                    nUselessContents: undefined,
                    nVisits: undefined,
                    privacity: undefined,
                    projectSketchDuration: 0,
                    projectDuration: 0,
                    title: undefined,
                    updatedAt: undefined,
                    userId: undefined,
                },
                masterFiles: []
            },
        contentId: undefined,
        createdAt: undefined,
        fileUrl: undefined,
        id: undefined,
        projectId: undefined,
        startsIn: 0,
        updatedAt: undefined,
        useFul: false,
        volume: 0
        },
    currentProject: {
        project:{
            createdAt: undefined,
            description: undefined,
            id: undefined,
            nUsefulContents: undefined,
            nUselessContents: undefined,
            nVisits: undefined,
            privacity: undefined,
            projectDuration: 0,
            projectSketchDuration: 0,
            title: undefined,
            updatedAt: undefined,
            userId: undefined,
        },
        masterFiles: []
    },
    boolUsefulProjectContentSelected: false,
    boolUselessProjectContentSelected: false,
    somethingSelected: false,
    projectContents: [],
    projectContentSelected: [],
    listUsefulSelected: [],
    listUselessSelected: [],
    projectContentsToMix: [],
    errors: [],
    playing: false,
    loading: false,
    loadingScreenUseful: false,
    loadingScreenUseless: false,
    loadingSelection: false,
    mergedAudioUrl: '',
    currentProjectContentToShow: undefined,
    currentProjectContentCommentsToShow: [],
    loadingProjectContentsCommments: false,
    loadingProjectContentsInfo: false,
    lodingMoreProjectContensComments: false,
    newCurrentProjectContentComments: []
}

// export const playAudio = createAsyncThunk(
//     'sessionProjectPlayerManagement/playAudio',
//     async (payload: ProjectContents, { rejectWithValue }) => {
//         const projectContent = payload;

//         if (projectContent.playing === false){
//             return rejectWithValue(projectContent.playing)
//         }else if (projectContent.playing === true){
//             return projectContent
//         }
//     }
// )

export const getProjectContent = createAsyncThunk (
    'sessionProjectPlayerManagement/getProjectContent',
    async (payload: GetProjectContentData, {rejectWithValue}) => {

        const response = await getProjectContentinProject(
            payload.authHeaders,
            payload.routeParams,
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const selectProjectContent = createAsyncThunk (
    'sessionProjectPlayerManagement/selectProjectContent',
    async (payload: GetProjectContentData, {rejectWithValue}) => {
        const response = await getProjectContentWithId (
            payload.authHeaders,
            payload.routeParams
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const postProjectContent = createAsyncThunk (
    'sessionProjectPlayerManagement/postProjectContent',
    async (payload: PostProjectContentData, {rejectWithValue}) => {

        const response = await postContentInProject(
            payload.authHeaders,
            payload.projectContentsRouteParams,
            payload.postBody
        );
        if (response.status>=200 && response.status<300){
            return response.data
        }
        else {
            return rejectWithValue(response.data)
        }
    }
)

export const getProjectContentsToMix = createAsyncThunk (
    'sessionProjectPlayerManagement/getProjectContentsToMix',
    async (payload: GetProjectContentsToMixData, {rejectWithValue}) => {
        const response = await getContentsInProjectToMix(
            payload.authHeaders,
            payload.userNickname,
            payload.projectId,
            payload.listProjectContentsId
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const patchProjectContent = createAsyncThunk(
    'sessionProjectPlayerManagement/patchProjectContent',
    async (payload: PatchProjectContentData, {rejectWithValue}) => {

        const response = await editContentInProject(
            payload.authHeaders,
            payload.projectContentsRouteParams,
            payload.patchProjectContentBody
        );

        if (response.status>=200 && response.status<300){
            return response.data
        }
        else {
            return rejectWithValue(response.data)
        }
    }
)


export const selectAllProjectContents = createAsyncThunk (
    'sessionProjectPlayerManagement/selectAllProjectContents',
    async (payload: GetProjectContentsData, {rejectWithValue}) => {
        const response = await getContentsInProject(
            payload.authHeaders,
            payload.userNickname,
            payload.projectId
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const mixSelectedProjectContents = createAsyncThunk (
    'sessionProjectPlayerManagement/mixSelectedProjectContents',
    async (payload: MixContentsData, {rejectWithValue}) => {
        const response = await mixAudios(
            payload.projectId,
            payload.idListToMix
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const getMergedAudioFile = createAsyncThunk (
    'sessionProjectPlayerManagement/getMergedAudioFile',
    async (payload: GetMergedData, {rejectWithValue}) => {
        const response = await getMergedAudioFromProject(
            payload.projectId
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const createProjectContentComment = createAsyncThunk (
    'sessionProjectPlayerManagement/createProjectContentComment',
    async (payload: CreateProjectContentCommentData, {rejectWithValue}) => {
        const response = await createProjectContentCommentWithData(
            payload.headers,
            payload.routeParams,
            payload.body
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const getProjectContentComments = createAsyncThunk (
    'sessionProjectPlayerManagement/getProjectContentComments',
    async (payload: GetProjectContentCommentsRequestInfo, {rejectWithValue}) => {
        const response = await getProjectContentCommentsWithRequestParams(
            payload.headers,
            payload.urlParams
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const deleteProjectContentComment = createAsyncThunk (
    'sessionProjectPlayerManagement/deleteProjectContentComment',
    async (payload: DeleteProjectContentCommentInfo, {rejectWithValue}) => {
        const response = await deleteProjectContentCommentWithRequestParams(
            payload.headers,
            payload.urlParams
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } else {
            return rejectWithValue(response)
        }
    }
)

export const sliceProjectPlayer = createSlice({
    name: 'sessionProjectPlayerManagement',
    initialState,
    reducers: {
        // playProjectContent: (state, action: PayloadAction<any>) => {
        //     state.projectContent.id = action.payload.id;
        //     state.playing = true;
        //     state.loading = false;
        // },
        // pauseProjectContent: (state, action: PayloadAction<any>) => {
        //     state.projectContent.id = action.payload.id;
        //     state.playing = false;
        //     state.loading = false;
        // },
        deselectProjectContent: ( state, action: PayloadAction<any> ) => {
            const idToRemove = action.payload;
            const newListItemRemoved = state.projectContentSelected.filter(item => item != idToRemove);
            state.projectContentSelected = newListItemRemoved;
            state.projectContentsToMix = state.projectContentsToMix.filter(item => item != idToRemove);
            state.listUsefulSelected = state.listUsefulSelected.filter(item => item != idToRemove);
            state.listUselessSelected = state.listUselessSelected.filter(item => item != idToRemove);
            
            if (state.listUsefulSelected.length == 0) {
                state.boolUsefulProjectContentSelected = false;
            }

            if (state.listUselessSelected.length == 0) {
                state.boolUselessProjectContentSelected = false;
            }
        },
        clearSelection: ( state ) => {
            state.projectContentSelected = []; 
            state.somethingSelected = false;
            state.projectContentsToMix = [];
            state.errors = [];
            state.boolUsefulProjectContentSelected = false;
            state.boolUselessProjectContentSelected = false;
        }
    },
    extraReducers: (builder) => {
        builder
        // .addCase(playAudio.pending, (state) => {
        //     state.loading = true;
        // })
        // .addCase(playAudio.fulfilled, (state, action: any) => {
        //     state.projectContent.id = action.meta.arg.id;
        //     state.playing = action.meta.arg.playing;
        //     state.errors=[];
        //     state.loading = false;
        // })
        .addCase(selectProjectContent.pending, (state, action: any) => {
            state.somethingSelected = false;

            if (action.meta.arg.useful == true){
                state.loadingScreenUseful = true;
            } else {
                state.loadingScreenUseless = true;
            }

            state.loadingSelection = true;
        })
        .addCase(selectProjectContent.fulfilled, (state, action: any) => {
            state.somethingSelected = true;
            state.projectContentSelected = [convertKeysToCamelCase(action.payload.id), ...state.projectContentSelected];
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
            state.loadingSelection = false;

            if (action.payload.useful == true) {
                state.listUsefulSelected = [convertKeysToCamelCase(action.payload.id), ...state.listUsefulSelected];
                state.boolUsefulProjectContentSelected = true;
            }

            if (action.payload.useful == false) {
                state.listUselessSelected = [convertKeysToCamelCase(action.payload.id), ...state.listUselessSelected];
                state.boolUselessProjectContentSelected = true;
            }
        })
        .addCase(selectProjectContent.rejected, (state) => {
            state.somethingSelected = false;
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
            state.loadingSelection = false;
        })
        .addCase(selectAllProjectContents.pending, (state) => {
            state.somethingSelected = false;
            state.loadingScreenUseful = true;
            state.loadingScreenUseless = false;
            state.loadingSelection = true;
        })
        .addCase(selectAllProjectContents.fulfilled, (state, action: any) => {
            state.somethingSelected = true;
            const allProjectContents = convertKeysToCamelCase(action.payload)
            const allUsefulProjectContents = allProjectContents.usefulContents
            state.projectContentSelected = allUsefulProjectContents.map((item: ProjectContent) => item.id);
            state.listUsefulSelected = allUsefulProjectContents.map((item: ProjectContent) => item.id);
            state.boolUsefulProjectContentSelected = true;
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
            state.loadingSelection = false;
        })
        .addCase(selectAllProjectContents.rejected, (state, action: any) => {
            state.errors = action.payload;
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
            state.loadingSelection = false;
        })
        .addCase(getProjectContentsToMix.pending, (state) => {
            state.loadingScreenUseful = true;
            state.loadingScreenUseless = true;

            state.loadingSelection = true;
        })
        .addCase(getProjectContentsToMix.fulfilled, (state, action: any) => {
            const listIdSelected: number[] = action.meta.arg.listProjectContentsId;

            state.projectContentsToMix = listIdSelected;
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;

            state.loadingSelection = false;
        })
        .addCase(getProjectContentsToMix.rejected, (state) => {
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
            state.loadingSelection = false;
        })
        .addCase(patchProjectContent.pending, (state) => {
            state.errors=[];
        })
        .addCase(patchProjectContent.fulfilled, (state, action: any) => {
            state.errors=[];
            state.loadingScreenUseful = false;
        })
        .addCase(patchProjectContent.rejected, (state, action: any) => {
            state.errors = action.payload;
            state.loadingScreenUseful = false;
        })
        .addCase(mixSelectedProjectContents.pending, (state) => {
            state.loadingScreenUseful = true;
            state.loadingScreenUseless = true;
        })
        .addCase(mixSelectedProjectContents.fulfilled, (state, action: any) => {
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
        })
        .addCase(mixSelectedProjectContents.rejected, (state, action: any) => {
            state.loadingScreenUseful = false;
            state.loadingScreenUseless = false;
        })
        .addCase(getMergedAudioFile.pending, (state) => {
            state.loading = false;
        })
        .addCase(getMergedAudioFile.fulfilled, (state, action: any) => {
            const responseCamelCase = convertKeysToCamelCase(action.payload);
            state.mergedAudioUrl = responseCamelCase.mergedFileUrl
            state.loading = false;
        })
        .addCase(getMergedAudioFile.rejected, (state) => {
            state.loading = false;
        })
        .addCase(getProjectContent.pending, (state) => {
            state.loadingProjectContentsInfo = true;
        })
        .addCase(getProjectContent.fulfilled, (state, action: any) => {
            state.currentProjectContentToShow = convertKeysToCamelCase(action.payload);
            state.loadingProjectContentsInfo = false;
        })
        .addCase(getProjectContent.rejected, (state) => {
            state.loadingProjectContentsInfo = false;
        })
        .addCase(createProjectContentComment.pending, (state) => {
            state.lodingMoreProjectContensComments = true;
        })
        .addCase(createProjectContentComment.fulfilled, (state, action: any) => {
            state.newCurrentProjectContentComments = [...state.newCurrentProjectContentComments,convertKeysToCamelCase(action.payload)]
            state.lodingMoreProjectContensComments = false;
        })
        .addCase(createProjectContentComment.rejected, (state) => {
            state.lodingMoreProjectContensComments = false;
        })
        .addCase(getProjectContentComments.pending, (state, action: any) => {
            if (action.meta.arg.urlParams.projectContentCommentsPage == "1"){
                state.currentProjectContentCommentsToShow = [];
                state.newCurrentProjectContentComments = [];
            }
            state.loadingProjectContentsCommments = true;
            state.lodingMoreProjectContensComments = true;
        })
        .addCase(getProjectContentComments.fulfilled, (state, action: any) => {
            const projectContentCommentsConverted = [...state.currentProjectContentCommentsToShow,...convertKeysToCamelCase(action.payload)];
            state.currentProjectContentCommentsToShow = projectContentCommentsConverted;
            state.loadingProjectContentsCommments = false;
            state.lodingMoreProjectContensComments = false;
        })
        .addCase(getProjectContentComments.rejected, (state) => {
            state.loadingProjectContentsCommments = false;
            state.lodingMoreProjectContensComments = false;
        })
        .addCase(deleteProjectContentComment.pending, (state) => {
            state.loading;
        })
        .addCase(deleteProjectContentComment.fulfilled, (state, action: any) => {
            const deletedCommentId = action.meta.arg.urlParams.projectContentCommentId.toString();
            state.currentProjectContentCommentsToShow = state.currentProjectContentCommentsToShow.filter(projectContentComment =>
                projectContentComment.id.toString() !== deletedCommentId
            );
            state.newCurrentProjectContentComments = state.newCurrentProjectContentComments.filter(projectContentComment =>
                projectContentComment.id.toString() !== deletedCommentId
            );
        })
        .addCase(deleteProjectContentComment.rejected, (state) => {
            state.loading;
        })
    }
});

export const manageProjectPlayerSliceReducers = sliceProjectPlayer.reducer;
export const {deselectProjectContent,clearSelection} = sliceProjectPlayer.actions;
// export const {playProjectContent,pauseProjectContent,deselectProjectContent,clearSelection} = sliceProjectPlayer.actions;

export function convertKeysToCamelCase(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
        if (Array.isArray(obj)) {
            return obj.map(item => convertKeysToCamelCase(item));
        } else if (obj.constructor === Object) {
            const newObj: { [key: string]: any } = {};
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
                        letter.toUpperCase()
                    );
                    newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
                }
            }
            return newObj;
        }
    }
    return obj;
}