import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PersistLogin from '../app/features/sessions/PersistLogin';
import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from './PublicOnlyRoute';
import Contents from '../pages/Private/contents/Contents';
import SignUp from '../pages/Public/authentication/SignUp';
import SignIn from '../pages/Public/authentication/SignIn';
import Error from '../pages/Public/error/Error';
import ResponsiveAppBar from '../app/features/appbar/ResponsiveAppBar';
import ManageProjects from '../pages/Private/projects/ManageProjects/ManageProjects';
import * as URL from '../app/api/requestRequirements';
import MyContentsFooter from '../app/components/myContentsFooter/MyContentsFooter';
import ManageProject from '../pages/Private/projects/ManageProject/ManageProject';
import Search from '../pages/Private/search/Search';
import Profile from '../pages/Private/user/profile/Profile';
import { UserConfigurations } from '../pages/Private/user/Configurations/UserConfigurations';
import MemberGroups from '../pages/Private/groups/memberGroups/MemberGroups';
import { Group } from '../pages/Private/groups/group/Group';

function RoutesApp() {

  return (
          <BrowserRouter>
            <ResponsiveAppBar/>
            <Routes>
              <Route element={<PersistLogin />}>
                <Route path={URL.GROUP_ENDPOINT} element={
                  <PrivateRoute>
                    <Group/>
                  </PrivateRoute>
                } />
              </Route>

              <Route element={<PersistLogin />}>
                <Route path={URL.MEMBER_GROUPS_ENDPOINT} element={
                  <PrivateRoute>
                    <MemberGroups/>
                  </PrivateRoute>
                } />
              </Route>

              <Route element={<PersistLogin />}>
                <Route path={URL.HOME_ENDPOINT} element={
                  <PrivateRoute>
                    <Contents/>
                  </PrivateRoute>
                } />
              </Route>

              <Route element={<PersistLogin/>}>
                <Route path={URL.USER_CONFIG} element={
                  <PrivateRoute>
                    <UserConfigurations/>
                  </PrivateRoute>
                } />
              </Route>

              <Route element={<PersistLogin/>}>
                <Route path={URL.PROFILE_ENDPOINT} element={
                  <PrivateRoute>
                    <Profile/>
                  </PrivateRoute>
                } />
              </Route>

              <Route element={<PersistLogin/>}>
                <Route path={URL.MANAGE_PROJECTS_ENDPOINT} element={
                  <PrivateRoute>
                    <ManageProjects/>
                  </PrivateRoute>
                } />
              </Route>

              <Route element={<PersistLogin/>}>
                <Route path={URL.MANAGE_PROJECT_ENDPOINT} element={
                  <PrivateRoute>
                      <>
                        <ManageProject />
                        <MyContentsFooter />
                      </>
                  </PrivateRoute>
                } />
              </Route> 

              <Route element={<PersistLogin />}>
                <Route path="/" element={
                  <PrivateRoute>
                    <SignIn />
                  </PrivateRoute>
                } />
              </Route> 

              <Route element={<PersistLogin />}>
                <Route path={URL.SEARCH} element={
                  <PrivateRoute>
                    <Search/>
                  </PrivateRoute>
                } />
              </Route> 

              <Route path={URL.SIGNUP_ENDPOINT} element={
                <PublicOnlyRoute>
                  <SignUp />
                </PublicOnlyRoute>
              } />

              <Route path={URL.SIGNIN_ENDPOINT} element={
                <PublicOnlyRoute>
                  <SignIn />
                </PublicOnlyRoute>
              } />

              <Route element={<PersistLogin />}>
                <Route path="*" element={
                  <PrivateRoute>
                    <Error/>
                  </PrivateRoute>
                } />
              </Route>


            </Routes>
          </BrowserRouter>
  );
}

export default RoutesApp;