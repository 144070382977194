import { Dispatch, SetStateAction, useState } from "react";
import { FiX } from "react-icons/fi";
import { Group, GroupMusicalGenre, deleteGroup } from "../../../features/sessions/sessionGroups/sessionGroups";
import './groupDetailsModal.css';
import { format } from "date-fns";
import IconDelete from '../../../assets/icon-delete-30-30-white.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { useParams } from "react-router-dom";
import { DeleteGroupDialogBox } from "../dialogBox/group/DeleteGroupDialogBox";

interface GroupDetailsModalProps {
    group: Group | undefined;
    open: Dispatch<SetStateAction<boolean>>;
    enterGroup: () => void;
}

export function GroupDetailsModal({ group, open, enterGroup }: GroupDetailsModalProps){
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const [deleteDialogBoxOpen,setDeleteDialogBoxOpen] = useState(false);
    const { userNicknameRoute } = useParams();

    function handleFormatDate(date:any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    async function handleDeleteGroup(){
        if (authHeaders && group && group.id){
            const response = dispatch(deleteGroup({authHeaders: authHeaders, groupId: `${group.id}`}))
        }
    }

    async function handleEnterGroup(){
        enterGroup()
    }

    return (
        <>
            <div id='container-modal-group-details'>
                <div id='container-group-details'>
                    <button id='close-group-details-modal' onClick={() => open(false)}>
                            <FiX size={25} color="#FFF"/>
                    </button>
                    {group?
                        <div id='container-group-details'>
                            <div id='container-top-group-details-modal'>
                                <div id='container-title-group-details'>
                                    <h2>{group.name}</h2>
                                </div>
                                {group.privacity === 0 ?
                                    <i style={{color: "#239400"}}>PUBLIC</i> 
                                    : 
                                    <i style={{color:"#e70000"} }>PRIVATE</i>
                                }
                            </div>
                            <div id='container-middle-group'>
                                <div id="container-group-descriptions">
                                    <pre>{group.description}</pre>
                                </div>
                                <div id='container-dates-nmembers-delete-enter'>
                                    <div id='container-left-dates-nmembers-delete-enter'>
                                        <div id='container-date-usabilty'>
                                            <div id='container-date-column-group-details'>
                                                <div id='container-date-row-group-details-modal'>
                                                    <label>Created At </label><h5>{handleFormatDate(group.createdAt)}</h5>
                                                </div> 
                                                <div id='container-date-row-group-details-modal'>
                                                    <label>Updated At </label><h5>{handleFormatDate(group.updatedAt)}</h5>
                                                </div>
                                            </div>
                                            <div id='container-nmembers-group-details-modal'>
                                                <div id='container-row-nmembers-group-details-modal'>
                                                    <label>Members:</label><h5>{group.nMembers}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id='container-right-dates-nmembers-delete-enter'>
                                        { currentUser?.nickname == userNicknameRoute && (
                                            <>
                                                <button id='button-delete-group' onClick={() => setDeleteDialogBoxOpen(true)} >
                                                    <img src={IconDelete}/>
                                                    Delete
                                                </button>
                                                { deleteDialogBoxOpen && (
                                                    <DeleteGroupDialogBox groupName={group.name}
                                                                        onDeleteGroup={handleDeleteGroup}
                                                                        onCloseDeleteGroupDialogBox={() => setDeleteDialogBoxOpen(false)}
                                                                        onCloseGroupDetailsModal ={() => {open(false)}}/>
                                                    
                                                    )
                                                }
                                            </>)
                                        }

                                        <button id='button-enter-group' onClick={handleEnterGroup} >
                                            Enter
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div id='container-bottom-group-details'>
                                <ul id='ul-musical-genres-group-details-modal'>
                                    {group && group.groupMusicalGenres.length > 0 ? (
                                                    group.groupMusicalGenres.map((item: GroupMusicalGenre, index: any) => (
                                                        <li id='li-musical-genres-group-details' key={index}>
                                                            <h5 id="project-player-project-track-title">{item.musicGenre.description}</h5>
                                                        </li>
                                                    ))
                                                )
                                        :
                                        null
                                    }
                                </ul>
                            </div>
                        </div>
                    :
                    
                    null

                    }
                </div>
            </div>
        </>
    )
}