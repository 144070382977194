import { Navigate, useLocation, useNavigate,Link } from 'react-router-dom';
import './styles.css';
import React, { Children, useEffect, useState } from 'react';
import * as URL from '../../../app/api/requestRequirements';
import { AppDispatch, RootState } from '../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUserFromStorage } from '../../../app/features/sessions/sessionSlice';
import { handlePrivateRoutes } from '../../../app/api/requestRequirements';

function Error(){
    const navigate = useNavigate();
    const loading = useSelector((state : RootState) => state.session.loading);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const loggedIn = useSelector((state: RootState) => state.session.loggedIn);
    const location = useLocation();
    const fromLocation = (location.state as any)?.from;
    const dispatch = useDispatch<AppDispatch>();
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const previousLocation = fromLocation ? fromLocation : { pathname: '/'};

    useEffect(() => {
        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }
        authenticateUser();

    }, [userValidated, dispatch]);

    function handleBackHome(){
        if (currentUser){
            navigate(`/users/${currentUser.nickname}/contents`);
        } else {
            navigate('/auth/sign_in');
        }
    }

    return( 
        <div className='error-container'>
            <h2 className="page-not-found-error-message">ERROR PAGE NOT FOUND</h2>
            <button className='back-home-button'
                onClick={handleBackHome}>Back Home
             </button>
        </div>
    )

}

export default Error;