import { useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";

export const useWavesurfer = (containerRef: any, options: any) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>()

  useEffect(() => {
    if (!containerRef.current) return

    const ws = WaveSurfer.create({
      ...options,
      barRadius: 10,
      barHeight: 1,
      height: 80,
      waveColor: 'rgb(153, 153, 153)',
      progressColor: 'rgb(0, 0, 0)',
      cursorColor: 'rgb(255, 165, 0)',
      container: containerRef.current,
      scrollParent: true
    })

    setWavesurfer(ws)

    return () => {
      ws.destroy()
    }
  }, [options, containerRef])

  return wavesurfer
}