import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthHeaders, GetGroupPostsRouteParams, Group, Project, User } from "../../../#interfaces/interfaces";
import { createCommentOnPostWithData, createPostWithGroupIdAndUserNickname, deleteCommentInPost, deletePostInGroup, getGroupPostsWithGroupIdAndUserNickname, showPostWithComments } from "../../../api/sessionAPI";

interface CreatePostData {
  authHeaders: AuthHeaders;
  createPostRouteParams: CreatePostRouteParams;
  body: PostCreationBody;
}

interface GetGroupPostsData {
  authHeaders: AuthHeaders;
  getGroupPostsRouteParams: GetGroupPostsRouteParams;
}

export interface PostCreationBody {
    userId?: number | string;
    groupId?: number | string;
    projectId?: number | string;
    body?: string;
}

export interface CreatePostRouteParams {
  userNickname?: string;
  groupId?: number | string;
}

export interface CreateCommentInPostData {
  authHeaders: AuthHeaders;
  createCommentInPostRouteParams: CreateCommentInPostRouteParams;
  body: CreateCommentInPostBody;
}

export interface CreateCommentInPostBody{
  postId: string;
  body?: string;
}

export interface CreateCommentInPostRouteParams{
  postId?: string;
  userNickname?: string;
}

export interface CreatePostBody {
  userId?: string;
  postId?: string;
  body?: string;
}

export interface Post {
  body?: string;
  createdAt?: string;
  group?: Group;
  groupId?: number;
  id?: number;
  project?: Project;
  projectId?: number;
  updatedAt?: string;
  user?: User;
  userId?: number;
}

export interface PostComment {
  id: number | string;
  userId: number | string;
  postId: number | string;
  body: number | string;
  createdAt: number | string;
  updatedAt: number | string;
  user: User;
}

interface PostWithComments {
  id: number | string;
  postComments: PostComment[];
}

export interface ShowPostWithCommentsData{
  authHeaders: AuthHeaders;
  routeParams: ShowPostWithCommentsRouteParams
}

export interface DeleteCommentData{
  authHeaders: AuthHeaders;
  routeParams: DeletePostCommentRouteParams;
}

export interface DeletePostData{
  authHeaders: AuthHeaders;
  routeParams: DeletePostRouteParams;
}

export interface DeletePostRouteParams{
  userNickname: string;
  postId: string;
  groupId: string;
}

export interface DeletePostCommentRouteParams{
  userNickname: string;
  postCommentId: string;
  postId: string;
}

export interface ShowPostWithCommentsRouteParams{
    userNickname: string;
    groupId: string;
    postId: string;
    postCommentsPage: string;
}

interface GroupPosts {
  projectSelectedId?: number;
  groupPosts: Post[];
  newPosts: Post[];
  errors: string[];
  postComments: PostComment[];
  loadingMorePosts: boolean;
  loadingMorePostComments: boolean;
  loadingCreatePost: boolean;
  loadingPostComment: boolean;
  loadingDeletePost: boolean;
  postPostComments: PostWithComments[];
}

const initialState: GroupPosts = {
    projectSelectedId: undefined,
    groupPosts: [],
    newPosts: [],
    errors: [],
    postComments: [],
    loadingMorePosts: false,
    loadingMorePostComments: false,
    loadingCreatePost: false,
    loadingPostComment: false,
    loadingDeletePost: false,
    postPostComments: []
}

export const createPost = createAsyncThunk(
  'postProjectSlice/createPost',
  async (payload: CreatePostData, {rejectWithValue}) => {
      const response = await createPostWithGroupIdAndUserNickname(
          payload.authHeaders,
          payload.createPostRouteParams,
          payload.body
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getGroupPosts = createAsyncThunk(
  'postProjectSlice/getGroupPosts',
  async (payload: GetGroupPostsData, {rejectWithValue}) => {
      const response = await getGroupPostsWithGroupIdAndUserNickname(
          payload.authHeaders,
          payload.getGroupPostsRouteParams
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const createCommentInPost = createAsyncThunk(
  'postProjectSlice/createCommentInPost',
  async (payload: CreateCommentInPostData, {rejectWithValue}) => {
      const response = await createCommentOnPostWithData(
          payload.authHeaders,
          payload.createCommentInPostRouteParams,
          payload.body

      )

      if (response.status >= 200 && response.status < 300) {
          return response.data
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getPostWithComments = createAsyncThunk(
  'postProjectSlice/getPostWithComments',
  async (payload: ShowPostWithCommentsData, {rejectWithValue}) => {
      const response = await showPostWithComments(
          payload.authHeaders,
          payload.routeParams

      )

      if (response.status >= 200 && response.status < 300) {
          return response.data
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const deleteComment = createAsyncThunk(
  'postProjectSlice/deleteComment',
  async (payload: DeleteCommentData, {rejectWithValue}) => {
      const response = await deleteCommentInPost(
          payload.authHeaders,
          payload.routeParams

      )

      if (response.status >= 200 && response.status < 300) {
          return response.data
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const deletePost = createAsyncThunk(
  'postProjectSlice/deletePost',
  async (payload: DeletePostData, {rejectWithValue}) => {
      const response = await deletePostInGroup(
          payload.authHeaders,
          payload.routeParams

      )

      if (response.status >= 200 && response.status < 300) {
          return response.data
      } else {
        return rejectWithValue(response.data)
      }
  }
)

const postProjectSlice = createSlice({
    name: 'postProjectSlice',
    initialState,
    reducers: {
        selectDeselectProjectPost: ( state, action: PayloadAction<number | undefined> ) => {
            if (state.projectSelectedId != action.payload) {
                state.projectSelectedId = action.payload;
            } else if (state.projectSelectedId == action.payload) {
                state.projectSelectedId = undefined;
            }
        }
    },
    extraReducers: (builder) => {
      builder
      .addCase(createPost.pending, (state) => {
        state.loadingCreatePost = true;
      })
      .addCase(createPost.fulfilled, (state, action: any) => {
        state.groupPosts = [...state.groupPosts, ...convertKeysToCamelCase(action.payload)];
        state.projectSelectedId = undefined;
        state.loadingCreatePost = false;
      })
      .addCase(createPost.rejected, (state, action: any) => {
        const actionCamelCase = convertKeysToCamelCase(action.payload)
        state.errors = actionCamelCase.errors
        state.loadingCreatePost = false;
      })
      .addCase(getGroupPosts.pending, (state, action: any) => {
        state.loadingMorePosts = true;
        
        if (parseInt(action.meta.arg.getGroupPostsRouteParams.page) == 1){
          state.groupPosts = [];
        }
      })
      .addCase(getGroupPosts.fulfilled, (state, action: any) => {
        state.loadingMorePosts = false;

        if (convertKeysToCamelCase(action.payload).length > 0){
          if (parseInt(action.meta.arg.getGroupPostsRouteParams.page) == 1){
            state.groupPosts = [...convertKeysToCamelCase(action.payload)];
          } else if (parseInt(action.meta.arg.getGroupPostsRouteParams.page ) > 1) {
            state.groupPosts = [...state.groupPosts,...convertKeysToCamelCase(action.payload)];
        }}
      })
      .addCase(getGroupPosts.rejected, (state) => {
        state.loadingMorePosts = false;
      })
      .addCase(createCommentInPost.pending, (state) => {
        state.loadingPostComment = true;
      })
      .addCase(createCommentInPost.fulfilled, (state, action: any) => {
        state.postComments = [...state.postComments, convertKeysToCamelCase(action.payload)];
        state.loadingPostComment = false;
      })
      .addCase(createCommentInPost.rejected, (state, action: any) => {
        state.errors = action.payload.errors
        state.loadingPostComment = false;
      })
      .addCase(getPostWithComments.pending, (state, action: any) => {
        state.loadingMorePostComments = true;

        if (action.meta.arg.routeParams.postCommentsPage.toString() === '1'){
          state.postComments = [];  
        }

      })
      .addCase(getPostWithComments.fulfilled, (state, action: any) => {
        const postCommentsConverted = convertKeysToCamelCase(action.payload);
        const postCommentsLoaded = [...state.postComments,...postCommentsConverted.postComments];
        state.postComments = convertKeysToCamelCaseAndSortByUpdatedAt(postCommentsLoaded);
        state.loadingMorePostComments = false;
      })
      .addCase(getPostWithComments.rejected, (state) => {
        state.postComments = []
        state.loadingMorePostComments = false;
      })
      .addCase(deleteComment.pending, (state, action: any) => {
        state.errors = []
      })
      .addCase(deleteComment.fulfilled, (state, action: any) => {
        state.postComments = state.postComments.filter(postComment => postComment.id != action.meta.arg.routeParams.postCommentId);
      })
      .addCase(deleteComment.rejected, (state,action: any) => {
        state.errors = action.payload.errors;
      })
      .addCase(deletePost.pending, (state, action: any) => {
        state.loadingDeletePost = true;
      })
      .addCase(deletePost.fulfilled, (state, action: any) => {
        state.newPosts = state.groupPosts.filter(post => post.id != action.meta.arg.routeParams.postId);
        state.groupPosts = state.groupPosts.filter(post => post.id != action.meta.arg.routeParams.postId);
        state.loadingDeletePost = false;
      })
      .addCase(deletePost.rejected, (state,action: any) => {
        state.loadingDeletePost = false;
      })
    }
  })

export const postProjectSliceReducers = postProjectSlice.reducer
export const {selectDeselectProjectPost} = postProjectSlice.actions

export function sortAndMergeGroups(...lists: any[][]): any[] {

    const mergedList = ([] as any[]).concat(...lists);

    const compareDates = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return  dateB.getTime() - dateA.getTime();
    };

    mergedList.sort((a, b) => compareDates(a.created_at, b.created_at));

    const mergedListConverted = convertKeysToCamelCase(mergedList);
    return mergedListConverted;
}

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

  const camelCasedObj = convertKeysToCamelCase(obj);

    if (Array.isArray(camelCasedObj)) {
      return camelCasedObj.sort((b, a) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB.getTime() - dateA.getTime();
      });
    } else {
      return camelCasedObj;
    }
}

function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}