import React from 'react';
import './yesNoDiaologModal.css';

interface YesNoDialogModalProps {
    message: string;
    onYesClick: (projectToDeleteId: string) => void;
    onCloseClick: (confirmation: boolean) => void;
}

export const YesNoDialogModal: React.FC<YesNoDialogModalProps & { onYesClick: () => void }> = ({ message, onYesClick, onCloseClick }) => {
    return (
        <div id="container-yes-no-dialog-modal">
            <div id='container-screen-transparent-yes-no-diolog'>
                <div id="container-modal-yes-no">
                    <div id='container-message-yes-no-modal'>
                        <p dangerouslySetInnerHTML={{ __html: message }}></p>
                    </div>
                    <div id='container-yes-no-diolog-modal-bottom'>
                        <div id="container-yes-no-diolog-modal-buttons">
                            <button id='button-yes-diolog-yes-no-modal' 
                                    onClick={() => {onYesClick();
                                                    onCloseClick(false)}
                                    }>
                                Yes
                            </button>
                            <button id='button-no-diolog-yes-no-modal'
                                    onClick={() => onCloseClick(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

