import React from 'react';
import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { authenticateUserFromStorage } from "../../features/sessions/sessionSlice";
import './editMinutesSecondsCentiseconds.css'
import { getProjectContents, patchCurrentProject } from "../../features/sessions/projectsManagement/sessionManageProject";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export function EditMinutesSecondsCentiseconds({projectDurationFormated, currentProject}: any){
    const dispatch = useDispatch<AppDispatch>();
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const [ minutesDurationProject, setMinutesDurationProject ] = useState('');
    const [ secondsDurationProject, setSecondsDurationProject ] = useState('');
    const [ centisecondsDurationProject, setCentisecondsDurationProject ] = useState('');
    const inputEditTitle = useRef<HTMLInputElement | null>(null);
    const inputMinutes = useRef<HTMLInputElement | null>(null);
    const inputSeconds = useRef<HTMLInputElement | null>(null);
    const inputMiliseconds = useRef<HTMLInputElement | null>(null);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const { userNicknameRoute, projectIdRoute } = useParams();
    const errors = useSelector((state : RootState) => state.sessionManageProjectContents.errors);

    useEffect(() => {
        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }

        authenticateUser();
        handleProjectDuration();

    },[projectDurationFormated])

    function handleProjectDuration(){
        
        const [minutesSplit, secondsSplit, centisecondsSplit] = projectDurationFormated.split(':').map(Number)
        const formattedMM = minutesSplit < 10 ? `0${minutesSplit}` : `${minutesSplit}`;
        const formattedSS = secondsSplit < 10 ? `0${secondsSplit}` : `${secondsSplit}`;
        const formattedCS = centisecondsSplit < 10 ? `0${centisecondsSplit}` : `${centisecondsSplit}`;
        setMinutesDurationProject(formattedMM);
        setSecondsDurationProject(formattedSS);
        setCentisecondsDurationProject(formattedCS);
    }

    async function handleSubmit(event: FormEvent<HTMLFormElement>){
        event.preventDefault();

        if (parseInt(minutesDurationProject) < 23 || 
            parseInt(secondsDurationProject) < 59 || 
            parseInt(centisecondsDurationProject) < 99){
            
            const totalCentiseconds = parseInt(minutesDurationProject)  * 6000 + 
                                      parseInt(secondsDurationProject) * 100 + 
                                      parseInt(centisecondsDurationProject)
            

            const response = await dispatch(patchCurrentProject({ authHeaders: authHeaders,
                                                                  projectRouteParams: { userNicknameRoute: userNicknameRoute,
                                                                                        projectIdRoute: projectIdRoute
                                                                  },
                                                                  patchCurrentProjectBody: {projectDuration:totalCentiseconds
                                                }}))
            if (response.payload.errors != null){
                response.payload.errors.map((item: string) => {toast.error(item)})
            }
            // await dispatch(getProjectContents({authHeaders: authHeaders, userNickname: userNicknameRoute, projectId: projectIdRoute}))
            } else {
                    if (parseInt(minutesDurationProject) > 23) {
                        toast.error("Minutes cannot be bigger than 23 seconds!")
                    }
                    if (parseInt(secondsDurationProject) > 59) {
                        toast.error("Seconds cannot be bigger than 59 seconds!")
                    }
                    if (parseInt(centisecondsDurationProject) > 99) {
                        toast.error("Centiseconds cannot be bigger than 99 seconds!")
                    }}
        

    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLFormElement>) {

        if (event.key === 'Enter') {
            handleSubmit(event as any);
        }
    }
    
    return (
        <form className='container-edit-duration' onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <input
                ref={inputMinutes}
                name='inputMinutes'
                type="text"
                className='input-project-duration'
                value={minutesDurationProject}
                onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, '').slice(0, 2);
                    setMinutesDurationProject(value);
                }}
                disabled={currentUser && (currentUser.id !== currentProject.userId)}
            />
            :
            <input
                ref={inputSeconds}
                name='inputSeconds'
                type="text"
                className='input-project-duration'
                value={secondsDurationProject}
                onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, '').slice(0, 2);
                    setSecondsDurationProject(value);
                }}
            />
            :
            <input
                ref={inputMiliseconds}
                name='inputMiliseconds'
                type="text"
                className='input-project-duration'
                value={centisecondsDurationProject}
                onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, '').slice(0, 2);
                    setCentisecondsDurationProject(value);
                }}
            />
        </form>
    )
}