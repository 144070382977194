import React from 'react';
import './userConfigSideBar.css';

export function UserConfigSideBar({optionSelected,setOptionClicked}: any){

    return(
        <div className='container-user-config-side-bar'>
            <ul>
                <li className={optionSelected === 'general' ? 'selected' : ''} onClick={() => setOptionClicked('general')}>
                    <label>General</label>
                </li>
                <li className={optionSelected === 'privacity' ? 'selected' : ''} onClick={() => setOptionClicked('privacity')}>
                    <label>Privacity</label>
                </li>
                <li className={optionSelected === 'access' ? 'selected' : ''} onClick={() => setOptionClicked('access')}>
                    <label>Accessibility</label>
                </li>
            </ul>
        </div>
    )
}