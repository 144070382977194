import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect, useMemo, useState } from "react";
import './postGroupCreate.css'
import { useParams } from "react-router-dom";
import { getGenericUserInfo } from "../../../features/sessions/sessionProfileNavigation";
import DefaultProfileImage  from '../../../assets/profile-default-red.png';
import { SelectProjectModal } from "../../modal/attachProjectSelectionModal/SelectProjectModal";
import { format } from "date-fns";
import { ProjectPlayer } from "../../audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer";
import { DescriptionPostComponent } from "./DescriptionPostComponent";
import { createPost } from "../../../features/sessions/sessionPostProject/sessionPostProject";
import React from "react";
import { toast } from 'react-toastify';
import { getUserProjects } from "../../../features/sessions/projectsManagement/sessionManageProjects";

export function PostGroupCreate(){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const allLoggedUserProjects = useSelector((state: RootState) => state.sessionManageProjects.allUserRouteProjects);
    const [projectSelectedId, setProjectSelectedId] = useState<string | number | undefined>(undefined);
    const [buttonSelectProjectClicked, setButtonSelectProjectClicked] = useState(false);
    const avatar = useSelector((state: RootState) => state.session.userMoreInfo.avatarUrl);
    const dispatch = useDispatch<AppDispatch>();
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);
    const [inputPostDescriptionForm, setInputPostDescriptionForm] = useState<string>('');
    const {userNicknameRoute, groupId} = useParams();
    const [userProjectsPage, setUserProjectsPage] = useState<number>(1);
    const errors = useSelector((state: RootState) => state.sessionProjectPosts.errors);

    useEffect(()=>{

        function fetchUserProjects(){
            if (authHeaders && userNicknameRoute) {
                    const response = dispatch(getUserProjects({authHeaders: authHeaders, 
                                                               urlParams: {
                                                                userNickname: userNicknameRoute,
                                                                page: userProjectsPage
                                                              }}))
                }
        }

        function getUserMoreInfo(){
            if (authHeaders && userNicknameRoute && currentUser && currentUser.nickname) {
                const response = dispatch(getGenericUserInfo({authHeaders: authHeaders, userNickname: currentUser?.nickname}));
            }
        }

        fetchUserProjects();
        getUserMoreInfo();

    },[userNicknameRoute,buttonSelectProjectClicked])

    function handleFormatDate(date:any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    async function handleCreatePost(){
        if (authHeaders && currentUser?.nickname ){
            const response = await dispatch(createPost({authHeaders: authHeaders, 
                                                  createPostRouteParams: {
                                                    userNickname: currentUser.nickname,
                                                    groupId: `${groupId}`
                                                  },
                                                  body: {
                                                    userId: currentUser.id,
                                                    groupId: groupId,
                                                    projectId: projectSelectedId,
                                                    body: inputPostDescriptionForm
                                                  }
                             }))

            // response.then((result) => {
            //     setProjectSelectedId(undefined);
            //     setInputPostDescriptionForm('');
            // });

            if (response && response.meta.requestStatus == "fulfilled"){
                setProjectSelectedId(undefined);
                setInputPostDescriptionForm('');
            }

            if (response.payload.errors && response.payload.errors.length > 0 ){
                response.payload.errors.map((item: string) => {toast.error(item)})
            }
            
        }
    }

    const handleDescriptionChange = (text: string) => {
        setInputPostDescriptionForm(text);
    };

    return (
        <div id='container-form-labels-create-post'>
            <>
              {buttonSelectProjectClicked === true ?
              
                <SelectProjectModal onOpenModal={setButtonSelectProjectClicked} setProjectSelectedId={setProjectSelectedId}/>
                
                :

                null
              }  
            </>
            <div id='container-top'>
                <div id='container-profile-pic-text-area'>
                    {avatar ?
                        <img id='img-profile-image' src={`${avatar}`}/>
                        
                        :
                        
                        <img id='img-profile-image' src={DefaultProfileImage}/>
                    }

                    <DescriptionPostComponent inputPostDescriptionForm={inputPostDescriptionForm} onDescriptionChange={handleDescriptionChange} />

                </div>
                <div id='container-middle'>
                    <div id='container-project-selected-button-selection'>
                        {projectSelectedId ? 
                            <div id='container-project-selected-post'>
                                    <div id='container-title-description'>
                                        <div id='container-project-top'> 
                                            <div id='container-project-title'>
                                                <h3>{allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.project.title}</h3>
                                            </div>
                                            <div id='container-privacity-project'>
                                                {allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.project.privacity ==  0 ?
                                                    <i style={{color: "#239400"}}>PUBLIC</i> 
                                                    :
                                                    <i style={{color:"#e70000"} }>PRIVATE</i>
                                                }
                                            </div>
                                            <div id='container-usability'>
                                                <div id='container-usability-label'>
                                                    <label>Useful:</label>
                                                    <label>{allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.project.nUsefulContents}</label>
                                                </div>
                                                <div id='container-usability-label'>
                                                    <label>Useless: </label>
                                                    <label>{allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.project.nUselessContents}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles ? 
                                        <div id='container-project-player-projects-selection-post'>
                                                <div id='container-title-project-modal-select'>
                                                    {allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles && allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles[0] ?
                                                    
                                                    <div id="container-player-projects-track-title-modal-select-project">
                                                            <h5>{handleFormatDate(allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles[0].createdAt)}</h5>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                {allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles && 
                                                 allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles[0]  ?
                                                 <MemorizedProjectPlayer
                                                 fileUrl={`${allLoggedUserProjects.find(project => project.project.id === projectSelectedId)?.masterFiles[0].signedUrl}`}/>
                                                :
                                                null
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                <div id='container-no-project-selected'>
                                    <h2> No Project Selected </h2>
                                </div>
                            }
                        <div id='container-button-select-project'>
                            <button id='button-select-project'  onClick={() => {setButtonSelectProjectClicked(!buttonSelectProjectClicked)}}>
                                Select Project
                            </button>
                        </div>
                    </div>
                </div>
                <div id='container-bottom'>
                    <button id='button-post' onClick={handleCreatePost}>
                        Post
                    </button>
                </div>
            </div>
        </div>
    )
}