import React from 'react';
import './loadingAnimation.css'

function LoadingAnimation(){
    return(
        <div className='container-loading-spinner'>
            <div className='loader-spinner'>
                <span className='spinner-stroke'></span>
                <span className='spinner-stroke'></span>
                <span className='spinner-stroke'></span>
                <span className='spinner-stroke'></span>
                <span className='spinner-stroke'></span>
                <span className='spinner-stroke'></span>
                <span className='spinner-stroke'></span>
            </div>
        </div>
    )
}

export default LoadingAnimation;