import React from 'react';
import { useState } from 'react'
import './accessibilityUserConfigForm.css'
import { sendRedefinitionMail } from '../../../api/sessionAPI';

export function AccessibilityUserConfigForm(){
    const [idiomInput, setIdiomInput] = useState();

    const handleClickSendRedefinitionMail = async () => {
        const headers = {}; // Adicione seus cabeçalhos aqui, se necessário
        const propertyToSearch = 'email'; // Substitua pelo tipo de propriedade que você está pesquisando
        const fieldInput = 'filipedasilva94.1@gmail.com'; // Substitua pelo valor do campo de entrada
    
        const response = await sendRedefinitionMail();
    
        // Faça algo com a resposta, por exemplo, exiba uma mensagem para o usuário
      };

    return(
        <div id='container-form-access-config'>
            <label id='title-accessibility'>Change Accessibility</label>
            <div className='container-access-idiom-change'>
                <label style={{color: 'rgb(220,220,220)'}}>Idiom</label>
                <input id='change-idiom-input'
                disabled/>
            </div>

            <div id='container-bottom-access-config'>
                <div id='container-change-password-link'>
                    <label id='label-password-change' onClick={handleClickSendRedefinitionMail}>Change Password Information?</label>
                    {/* <div className='container-input-form-access-config'>
                        <label>E-mail</label>
                        <input className='input-form-access-config'/>
                    </div>
                    <div className='container-input-form-access-config'>
                        <label>Password</label>
                        <input className='input-form-access-config'/>
                    </div>
                    <div className='container-input-form-access-config'>
                        <label>Password Confirmation</label>
                        <input className='input-form-access-config'/>
                    </div> */}
                </div>
                <div id='container-button-confirm-access-configurations'>
                    <button className='button-confirm-form-access-config'>Confirm</button>
                </div>
            </div>
        </div>
    )
}