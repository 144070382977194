import React, { useMemo } from 'react';
import { WaveSurferPlayer } from "./WaveSurferPlayer";

export function ProjectContentPlayer({projectContentObj, 
                                      fileUrl, 
                                      startsIn, 
                                      showYesNoRemoveProjectFunction,
                                      functionProjectIdToRemove,
                                      setShowInfoModal,
                                      setShowToggleBoxComments,
                                      functionSetIdToShowInfo,
                                      boxCommentsOpened,
                                      setProjectContentToComment}: any){

    return (
      <>
        <WaveSurferPlayer
          projectContentObj={projectContentObj}
          startsIn={startsIn}
          url={fileUrl}
          handleShowYesNoModal={showYesNoRemoveProjectFunction}
          handleSetProjectIdToRemove={functionProjectIdToRemove}
          handleSetShowProjectContenInfoModal={setShowInfoModal}
          handleShowToggleBoxComments={setShowToggleBoxComments}
          handleSetIdToShowInfo={functionSetIdToShowInfo}
          boxCommentsOpened={boxCommentsOpened}
          setProjectContentToComment={setProjectContentToComment}
        />
      </>
    )
  }