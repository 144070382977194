import React from 'react';
import { useEffect, useState } from 'react'
import './privacityUserConfigForm.css'
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { updatePrivacityConfig } from '../../../features/sessions/sessionProfileNavigation';

export function PrivacityUserConfigForm(){
    const { userNicknameRoute } = useParams<string>();
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const contentsPrivacityId = useSelector((state: RootState) => state.sessionUserConfig.userPrivacityConfigurations.contents.userPrivacityId);
    const projectsPrivacityId = useSelector((state: RootState) => state.sessionUserConfig.userPrivacityConfigurations.projects.userPrivacityId);
    const [inputContentsPrivacityType,setInputContentsPrivacityType] = useState(contentsPrivacityId);
    const [inputProjectsTypePrivacityType,setInputProjectsPrivacityType] = useState(projectsPrivacityId);

    async function hundleChangePrivacityConfig(){
        const paramsBody = {
                            contents: {
                                id: contentsPrivacityId,
                                userPrivacityId: inputContentsPrivacityType
                            },
                            projects: {
                                id: projectsPrivacityId,
                                userPrivacityId: inputProjectsTypePrivacityType
                            }
        }

        // if (authHeaders && userNicknameRoute){
        // const response = await dispatch(updatePrivacityConfig({authHeaders: authHeaders, 
        //                                                        userNickname: userNicknameRoute, 
        //                                                        patchBody: paramsBody}))}
    } 

    return(
        <div id='container-form-privacity-config'>
            <label id='title-privacity-config'>Change Privacities</label>
            <div id='container-inputs-form-privacity-config'>
                <label id='label-title-contents-privacity'>Contents</label>
                <div id='container-contents-privacity-config'>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="contentPrivacity"
                            value={1}
                            onChange={()=>setInputContentsPrivacityType(1)}
                            checked={inputContentsPrivacityType == 1}
                        />
                        <label>Everybody</label>
                    </div>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="contentPrivacity"
                            value={2}
                            onChange={() => setInputContentsPrivacityType(2)}
                            checked={inputContentsPrivacityType == 2}
                            disabled
                        />
                        <label style={{color: 'rgb(220,220,220)'}}>Fellows Only</label>
                    </div>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="contentPrivacity"
                            value={3}
                            onChange={() => setInputContentsPrivacityType(3)}
                            checked={inputContentsPrivacityType == 3}
                            disabled
                        />
                        <label style={{color: 'rgb(220,220,220)'}}>Selected Fellas</label>
                    </div>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="contentPrivacity"
                            value={4}
                            onChange={() => setInputContentsPrivacityType(4)}
                            checked={inputContentsPrivacityType == 4}
                            disabled
                        />
                        <label style={{color: 'rgb(220,220,220)'}}>Nobody</label>
                    </div>
                </div>
 
                <label id='label-title-projects-privacity'>Projects</label>
                <div id='container-contents-privacity-config'>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="projectPrivacity"
                            value={1}
                            onChange={() => setInputProjectsPrivacityType(1)}
                            checked={inputProjectsTypePrivacityType == 1}
                        />
                        <label>Everybody</label>
                    </div>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="projectPrivacity"
                            value={2}
                            onChange={() => setInputProjectsPrivacityType(2)}
                            checked={inputProjectsTypePrivacityType == 2}
                            disabled
                        />
                        <label style={{color: 'rgb(220,220,220)'}}>Fellows Only</label>
                    </div>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="projectPrivacity"
                            value={3}
                            onChange={() => setInputProjectsPrivacityType(3)}
                            checked={inputProjectsTypePrivacityType == 3}
                            disabled
                        />
                        <label style={{color: 'rgb(220,220,220)'}}>Selected Fellas</label>
                    </div>
                    <div id='container-label-radio-buttom'>
                        <input
                            type="radio"
                            name="projectPrivacity"
                            value={4}
                            onChange={() => setInputProjectsPrivacityType(4)}
                            checked={inputProjectsTypePrivacityType == 4}
                            disabled
                        />
                        <label style={{color: 'rgb(220,220,220)'}}>Nobody</label>
                    </div>                   
                </div>
            </div>
            <div id='container-button-submit-privacity-configurations'>
                <button onClick={hundleChangePrivacityConfig} >Submit</button>
            </div>
        </div>
    )
}