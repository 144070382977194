import React from 'react';
import { useEffect, useRef, useState, useContext } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardActions, CardContent, CircularProgress, Container, Divider,
         FormControl, FormGroup, IconButton, Input, InputAdornment, InputLabel, 
         Typography} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { signInUser } from '../../../app/features/sessions/sessionSlice';
import { AppDispatch } from '../../../app/store';
import jinnerLogo from '../../../app/assets/logo-red-name-red-subtitle-black-portrait.png';
import { FormControlEdited, LinkEdited } from "../../../app/features/sessions/EditedTemplate";
import { RootState } from '../../../app/store';
import * as REQUEST_REQUIREMENTS from '../../../app/api/requestRequirements';
import './signin.css';

const theme = createTheme({
    palette: {
        primary: {
          main: '#A52A2A'
        }
    }
  });

function SignIn() {
    const emailRef = useRef<HTMLInputElement>();
    const passwordRef = useRef<HTMLInputElement>();
    let errorMessages: string[] = [];
    const [errors, setErrors] = useState<string[]>([])
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const loading = useSelector((state: RootState) => state.session.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const [PRIVATE_ROUTES,setPrivateRoutes]= useState<REQUEST_REQUIREMENTS.EndPoints>();
    const location = useLocation();
    const fromLocation = (location.state as any)?.from;
    const previousLocation = fromLocation ? fromLocation : { pathname: '/auth/sign_in'};

    useEffect(() => {
        emailRef?.current?.focus();
        if (errorMessages.length > 0) {
            setErrors(errorMessages);
            errorMessages = [];
        }

        if (currentUser){
            
            const routeParams = {
                userNickname: currentUser?.nickname
            }
    
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
      
            setPrivateRoutes(PRIVATE_ROUTES);
        }
      


        }, [currentUser, navigate]);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setErrors([]);
        if (emailRef?.current === undefined
            || emailRef.current.value === ""
            || passwordRef?.current === undefined
            || passwordRef.current.value === "") {
            return setErrors(["Please fill out all fields"])
        }
        const payload = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }
        const response = await dispatch(signInUser(payload));
        
        if (response.payload.success == false) {
            errorMessages = response.payload.errors
            return setErrors(errorMessages);
        } else {
            
            const routeParams=  {
                userNickname: response.payload.data.nickname
            }
      
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

            setPrivateRoutes(PRIVATE_ROUTES);
            if (PRIVATE_ROUTES && PRIVATE_ROUTES.HOME){
                navigate(PRIVATE_ROUTES.HOME);
            }
        }

    }

    const passwordInput = <Input id='password' 
                                    type={showPassword ? 'text' : 'password'}
                                    inputRef={passwordRef}                               
                                    endAdornment={<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={() => setShowPassword(!showPassword)}
                                                    edge="end"> 
                                                        { showPassword ? <Visibility /> : <VisibilityOff/> }
                                                </IconButton>
                                                </InputAdornment>
    }/>;

    return (
        <section style={{marginTop:"5em"}}>
                        <Container maxWidth="sm">
                            <Card sx={{boxSShadow:1, maxWidth: 'sm'}}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            id='logo'
                                            src={jinnerLogo}
                                            alt="Logo"
                                        />
                                    </Box>
                                    <Container maxWidth="sm">
                                        <Typography variant="h4" color="text.primary" gutterBottom>
                                            Sign In
                                        </Typography>
                                        {errors.length > 0 ?
                                            <Alert severity="error" aria-live="assertive">
                                                {errors.map((error, index) => {
                                                    return <p key={`alert-${index}`}>
                                                        {error}
                                                    </p>
                                                })}    
                                            </Alert>
                                        : <></>}
                                        <form onSubmit={handleSubmit}>
                                            <FormGroup row={true} id="email-group" sx={{marginTop: "1em"}}>
                                                <FormControlEdited fullWidth>
                                                    <InputLabel required htmlFor="email" id="email-label">Email Address</InputLabel>
                                                    <Input id="email" type="email" inputRef={emailRef}/>
                                                </FormControlEdited>
                                            </FormGroup>
                                            <FormGroup row={true} id="password-group" sx={{marginTop: "1em"}}>
                                                <FormControlEdited fullWidth className='password-field'>
                                                    <InputLabel required htmlFor="password" id="password-label">Password</InputLabel>
                                                    {passwordInput}
                                                </FormControlEdited>
                                            </FormGroup>
                                            <FormGroup row={true} id="submit-group" sx={{marginTop: "1em"}}>
                                                <FormControl fullWidth>
                                                    <ThemeProvider theme={theme}>
                                                        <Button
                                                            disabled={loading}
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            id="submit-button">
                                                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Signin'}
                                                        </Button>
                                                    </ThemeProvider>
                                                </FormControl>
                                            </FormGroup>
                                        </form>
                                    </Container>
                                </CardContent>
                                <Divider light={false}/>
                                <CardActions sx={{marginTop: "1em", justifyContent:'center'}} disableSpacing >
                                    <Box>
                                        <Typography variant="body2" color="text.primary" align="center" >
                                                {/* <LinkEdited to="/forgot-password">Forgot Password?</LinkEdited> */}
                                            </Typography>
                                        <LinkEdited to="/auth">Create an Account</LinkEdited>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Container>
        </section>
    )
}

export default SignIn;