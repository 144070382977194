import { FiX } from 'react-icons/fi';
import './contentShowEdit.css';
import { format } from 'date-fns';
import { FaLock, FaUnlock } from 'react-icons/fa';
import DeleteIcon from '../../../assets/icon-delete-30-30-white.png';
import { useEffect, useMemo, useState } from 'react';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserContent, getInstruments, patchContent } from '../../../features/sessions/sessionAudioContent/sliceUserContents';
import { Content, Instrument } from '../../../#interfaces/interfaces';
import { ContentPlayer } from '../../audioPlayer/WaveSurfer/contentPlayer/ContentPlayer';
import React from 'react';
import { toast } from 'react-toastify';
import { YesNoDialogModal } from '../dialogBox/group/yesNo/YesNoDialogModal';
import LoadingModal from '../loadingModal/LoadingModal';

interface ContentShowEditProps{
    contentObj: Content | undefined;
    onCloseClick: (confirmation: boolean) => void;

}

export default function ContentShowEdit({ contentObj, onCloseClick }: ContentShowEditProps){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const errors = useSelector((state: RootState) => state.sessionUserContents.errors);
    const dispatch = useDispatch<AppDispatch>();
    const [titleForm, setTitleForm] = useState<string | undefined>(contentObj?.title);
    const [descriptionForm, setDescriptionForm] = useState<string | undefined>(() => {
        return contentObj?.description ?? undefined;
      });
    const [downloadAbledForm, setDownloadAbledForm] = useState<boolean | undefined>(contentObj?.downloadAbled);
    const [instrumentIdSelected, setInstrumentIdSelected] = useState<number | undefined>(contentObj?.instrument?.id);
    const [editButtonClicked, setEditButtonClicked] = useState<boolean>(false);
    const [cancelEditButtonClicked, setCancelEditButtonClicked] = useState<boolean>(false);
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);
    const instrumentsForm = useSelector((state: RootState) => state.sessionUserContents.instrumentsForm);
    const loadingUpdade = useSelector((state: RootState) => state.sessionUserContents.loadingUpdate);
    const loadingDelete = useSelector((state: RootState) => state.sessionUserContents.loadingDelete);
    const MemorizedContentPlayer = useMemo(() => React.memo(ContentPlayer), []);

    function handleFormatDate(date: any) {
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate;
    }

    useEffect(()=>{

        async function handleInstrumentsComboBox() {
            await dispatch(getInstruments());
        }

        handleInstrumentsComboBox();

    },[])

    function centisecondsToTime(centiseconds: number | undefined) {

        if (typeof(centiseconds) == "number"){
            const centisecondsInt = typeof centiseconds === 'string' ? parseInt(centiseconds) : centiseconds;
        
            if (!Number.isInteger(centisecondsInt)) {
                throw new Error('O valor deve ser um número inteiro.');
            }
        
            const totalSeconds = Math.floor(centisecondsInt / 100);
        
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            const remainingCentiseconds = centisecondsInt % 100;
        
            const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(remainingCentiseconds).padStart(2, '0')}`;
        
            return formattedTime;
        } else {
            return undefined
        }

    }

    async function updateContent(){
        if (authHeaders){
            const response = await dispatch(patchContent({authHeaders: authHeaders,
                                                    urlParams:{
                                                        contentId: contentObj?.id,
                                                        userNickname: contentObj?.user?.nickname
                                                    },
                                                    body: {title: titleForm, 
                                                    description: descriptionForm,
                                                    downloadAbled: downloadAbledForm,
                                                    instrumentId: instrumentIdSelected
                                                  }}))
                                                

            if (response.meta.requestStatus == 'fulfilled'){
                setEditButtonClicked(false)
            } else {
                response.payload.map((item: string) => { toast.error(item) });
            }
        }
            
        
    }

    async function deleteContent(){
        if (authHeaders){
            const response = await dispatch(deleteUserContent({authHeaders: authHeaders,
                                                                     urlParams:{
                                                                        contentId: contentObj?.id,
                                                                        userNickname: contentObj?.user?.nickname
                                                                     }}))
                                                

            if (response.meta.requestStatus == 'fulfilled'){
                setEditButtonClicked(false);
                onCloseClick(false);
            } else {
                response.payload.map((item: string) => { toast.error(item) });
            }
        }
            
        
    } 
    
    return(
        <>
            {
                loadingUpdade == true || loadingDelete == true ?

                <LoadingModal/>
                
                :
                
                null
            }

            {
                deleteClicked ?

                <YesNoDialogModal message={'You will delete this content. Are You sure?'}
                                  onYesClick={deleteContent}
                                  onCloseClick={setDeleteClicked}/>

                :

                null
            }
            <div id="container-content-info-edit-modal">
                <div id='container-screen-transparent-content-info-modal'>
                    <div id='container-content-info-modal'>
                        <div id='container-top-content-informations'>
                            <div id='container-title-date-project-content'>
                                {editButtonClicked == false ?
                                    <h1>
                                        {titleForm}
                                    </h1>

                                        :
                                    <div id='container-title-input-content-modal'>
                                        <label>
                                            Title:
                                        </label>
                                        <input type="text" 
                                               placeholder='content title'
                                               name='title'
                                               value={titleForm} 
                                               onChange={(event)=>{setTitleForm(event.target.value)}}
                                        />
                                    </div>
                                    }
                                <h6>
                                    {handleFormatDate('2024-06-11T20:22:27.532Z')}
                                </h6>
                            </div>
                            <div id='continer-download-abled-button-close'>
                                <div id='container-download-abled-icon'>

                                {editButtonClicked == false ?
                                    <div>
                                        <label>Download abled:</label>
                                        {
                                            downloadAbledForm == true ?
                                                <FaUnlock/>
                                            :
                                                <FaLock />
                                        }
                                    </div>
                                    :
                                    <div id='container-download-abled-content-info-modal'>

                                        <label>Download abled:</label>
                                        
                                        <div>
                                            <input
                                                type='radio'
                                                name='radio-download-abled'
                                                value={1}
                                                onChange={() => {setDownloadAbledForm(true)}}
                                                checked={downloadAbledForm === true}
                                            />
                                            <FaUnlock/>

                                            <input
                                                type="radio"
                                                name="radio-download-abled"
                                                value={0}
                                                onChange={() => {setDownloadAbledForm(false)}}
                                                checked={downloadAbledForm === false}
                                            />
                                            <FaLock />
                                        </div>
                                    </div>
                                }
                                </div>
                                <button onClick={() => onCloseClick(false)}>
                                    <FiX size={20} color="#FFF"/>
                                </button>
                            </div>
                        </div>
                        <div id='container-middle-1-content-information'>
                            <div id='instrument-content-info'>
                                <label>
                                    Instrument:
                                </label>
                                {editButtonClicked == false ?
                                    <h6>

                                         {instrumentsForm.find(instrument => instrument.id === Number(instrumentIdSelected))?.name}
                                        
                                    </h6>

                                    :

                                    <select id="instruments-combo-box" 
                                            name="filter-projects" 
                                            value={instrumentIdSelected}
                                            onChange={(event: any) => setInstrumentIdSelected(event.target.value)}
                                    >
                                            { instrumentsForm && instrumentsForm.map(instrument => (
                                        <option key={instrument.id} value={instrument.id}>
                                            {instrument.name}
                                        </option>
                                        ))}
                                    </select>
                                }
                            </div>
                            <div id='duration-content-info'>
                                <label>
                                    Duration:
                                </label>
                                <h6>
                                    {centisecondsToTime(contentObj?.file?.blob.metadata.centiseconds)}
                                </h6>
                            </div>
                        </div>
                        <div id='container-middle-2-content-information'>

                            {editButtonClicked == false ?
                                <pre>
                                    {descriptionForm}
                                </pre>
                                :
                                <div id='container-label-input-description'>
                                    <label>
                                        Description:
                                    </label>
                                    <textarea placeholder='Description' 
                                              name='description'
                                              value={descriptionForm} 
                                              onChange={(event) => {setDescriptionForm(event.target.value)}}
                                    />
                                </div>
                            }

                            <div id='container-content-player'>
                                <MemorizedContentPlayer
                                    fileUrl={contentObj?.fileUrl}
                                />
                            </div>
                        </div>
                        <div id='container-bottom-content-information'>
                            <div id='container-buttons-content-information-modal'>
                                <div id='container-button-edit-cancel'>
                                    { editButtonClicked == true ?    
                                        <div>
                                            <button onClick={()=>{setEditButtonClicked(false)}}>
                                                Cancel
                                            </button>
                                            <button onClick={()=>{updateContent()}}>
                                                Confirm
                                            </button>
                                        </div>
                                    
                                        :
                                        <div>
                                            <button onClick={()=>{setEditButtonClicked(true)}}>Edit</button>
                                        </div>
                                    }
                                </div>
                                <div id='container-button-delete'>
                                    <button onClick={() => {setDeleteClicked(true)}}>
                                        <img src={DeleteIcon} alt="delete-icon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
