import React, { useState } from "react";

interface DescriptionPostComponentProps {
    inputPostDescriptionForm: string;
    onDescriptionChange: (description: string) => void;
}

export function DescriptionPostComponent({ inputPostDescriptionForm, onDescriptionChange }: DescriptionPostComponentProps) {

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        onDescriptionChange(text);
    };

    return (
        <textarea
            name='inputPostDescription'
            id='text-area-form-post-create'
            value={inputPostDescriptionForm}
            placeholder="Say something"
            onChange={handleChange}
        />
    );
}
