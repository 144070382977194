import React, { useMemo } from 'react';
import { FiX } from 'react-icons/fi';
import './projectDetails.css';
import { format } from 'date-fns';
import UploadIconWhite from '../../../../assets/upload-white.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { authenticateUserFromStorage } from '../../../features/sessions/sessionSlice';
import * as REQUEST_REQUIREMENTS from '../../../api/requestRequirements'
import { getProjectContents, manageProjectContentsSliceActions } from '../../../features/sessions/projectsManagement/sessionManageProject';
import { ProjectPlayer } from '../../audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer';
import { MasterFiles } from '../../../features/sessions/projectsManagement/sessionManageProjects';
import LoadingModal from '../loadingModal/LoadingModal';

export default function ProjectDetailsModal({projectInfoAndUrls, close}: any){
    const authHeaders = useSelector((state : RootState) => state.session.authHeaders);
    const currentUser = useSelector((state : RootState) => state.session.currentUser);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const navigate = useNavigate();
    const [PRIVATE_ROUTES,setPrivateRoutes]= useState<REQUEST_REQUIREMENTS.EndPoints>()
    const dispatch = useDispatch<AppDispatch>();
    const {userNicknameRoute} = useParams();
    const loading = useSelector((state: RootState) => state.sessionProjectPlayerManagement.loading || 
                                                      state.sessionManageProjectContents.loading);
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);

    useEffect(()=>{
        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }
      
        if (currentUser){

            const routeParams={
                userNickname: userNicknameRoute
            }
        
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
        
            setPrivateRoutes(PRIVATE_ROUTES);
        }
      
          authenticateUser();
    },[])

    function handleFormatDate(date:any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    async function handleGetProjectContents(projectSelected: any){
        const getParams = {
            authHeaders: authHeaders,
            userNickname: projectSelected.user.nickname,
            projectId: projectSelected.id
        }

        manageProjectContentsSliceActions.loadingPage();
        
        const response = await dispatch(getProjectContents(getParams))
        
        if (response) {
            const routeParams={
                userNickname: projectSelected.user.nickname,
                projectId: projectSelected.id
            }
    
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
    
            if (PRIVATE_ROUTES){
                navigate(PRIVATE_ROUTES.PROJECT_CONTENTS)
            }
        }
        
    }

    return (
        <>
        {
            loading ?
                <LoadingModal/> :
        
            <div id='project-description-modal'>
                <div id='container-button-close-project-details-modal'>
                    <button id='buttpn-close-project-details-modal' onClick={ close }>
                            <FiX size={25} color="#FFF"/>
                    </button>
                </div>
                <div id='modal-project-details-container'>
                    <div id="container-row-title">
                        <h2>{projectInfoAndUrls.project.title}</h2>
                        {projectInfoAndUrls.project.privacity === 0 ?
                            <i style={{color: "#239400"}}>PUBLIC</i> 
                            : 
                            <i style={{color:"#e70000"} }>PRIVATE</i>
                        }
                    </div>
                    <main>
                        <div id="row-description">
                            <p>{projectInfoAndUrls.project.description}</p>
                        </div>
                    </main>
                    {projectInfoAndUrls.project.createdAt? 
                    
                    <div id='container-bottom-row-project-details-modal'>
                        {projectInfoAndUrls.project.createdAt != null && 
                         projectInfoAndUrls.project.nUsefulContents != null && 
                         projectInfoAndUrls.project.updatedAt != null && 
                         projectInfoAndUrls.project.nUselessContents != null 
                         
                         ?

                        <div id='container-bottom-date-tracks'>
                            <div id='container-dates-usability-button-add'>
                                <div id='container-date-usabilty'>
                                    <div id='container-date-column'>
                                        <div id='container-date-row'>
                                            <label>Created At </label><h5>{handleFormatDate(projectInfoAndUrls.project.createdAt)}</h5>
                                        </div> 
                                        <div id='container-date-row'>
                                            <label>Updated At </label><h5>{handleFormatDate(projectInfoAndUrls.project.updatedAt)}</h5>
                                        </div>
                                    </div>
                                    <div id='container-usability-column'>   
                                        <div id='container-usabilty-row'>
                                            <label>Useful Contents </label><h5>{projectInfoAndUrls.project.nUsefulContents}</h5>
                                        </div>
                                        <div id='container-usabilty-row'>
                                            <label>Useless Contents </label><h5>{projectInfoAndUrls.project.nUselessContents}</h5>
                                        </div>
                                    </div>  
                                </div>

                                <button id='button-manage-project' onClick={() => {handleGetProjectContents(projectInfoAndUrls.project)}} >
                                    Manage/Contribute
                                </button>
                            </div>
                            <div id='container-tracks-project-details-modal'>
                                <ul id='unordered-list-projects-modal'>
                                {projectInfoAndUrls.masterFiles && projectInfoAndUrls.masterFiles.length > 0 ? (
                                    projectInfoAndUrls.masterFiles.map((item: MasterFiles, index: any) => (
                                        <li id='li-content-modal' key={index}>
                                            <div id="container-player-project-track-title">
                                                <h5 id="project-player-project-track-title">{handleFormatDate(item.createdAt)}</h5>
                                            </div>
                                            <MemorizedProjectPlayer fileUrl={`${item.signedUrl}`} />
                                        </li>
                                    ))
                                ) : (
                                    <h2 id="no-master-files-yet">This project don&apos;t have projects yet</h2>
                                )}
                                </ul>
                            </div>                  
                        </div>
                        : null}
                    </div>:null}
                </div>
        </div>
        }
        </>
    )
}
