import React from 'react';
import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { authenticateUserFromStorage } from "../../../../app/features/sessions/sessionSlice";
import { useParams } from "react-router-dom";
import { patchCurrentProject } from "../../../../app/features/sessions/projectsManagement/sessionManageProject";
import { toast } from "react-toastify";
import './formCurrentProject.css'


export function FormCurrentProject({handleEditTitleClicked}: any){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const [ newTitle, setNewTitle ] = useState<string>('');
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const dispatch = useDispatch<AppDispatch>();
    const { userNicknameRoute, projectIdRoute } = useParams();
    const inputEditTitle = useRef<HTMLInputElement | null>(null);
    const errors = useSelector((state: RootState) => state.sessionManageProjectContents.errors);

    useEffect(() => {

        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }

        authenticateUser();

    },[])

    
    async function handleSubmit(event: FormEvent<HTMLFormElement>){
        event.preventDefault();
        if (inputEditTitle.current) {
            const inputValue = inputEditTitle.current.value;
            const response = await dispatch(patchCurrentProject( {authHeaders: authHeaders,
                                                projectRouteParams: {userNicknameRoute: userNicknameRoute,
                                                                    projectIdRoute: projectIdRoute
                                                },
                                                patchCurrentProjectBody: {title:inputValue
                                                }}))
            
            if (response.payload.errors){
                response.payload.errors.map((item: string) => {toast.error(item)})
            } else {
                handleEditTitleClicked();
            }
        }
    }

    return(
        <div>
            <form onSubmit={handleSubmit}>
                                    <input
                                        id='project-name-field-project-management-route'
                                        name="input-edit-title"
                                        ref={inputEditTitle}
                                        type="text"
                                        value={newTitle}
                                        onChange={(event) => setNewTitle(event.target.value)}
                                    />
                                </form>
        </div>
    )
}