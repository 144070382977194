import React from 'react';
import RoutesApp from './routes/RoutesApp';
import ResponsiveAppBar from './app/features/appbar/ResponsiveAppBar';
//import Dashboard from './app/features/dashboard/Dashboard';
//import Logout from './app/features/sessions/Logout';
//import UpdateProfile from './app/features/sessions/UpdateProfile';
import { ToastContainer } from 'react-toastify';
import './app.css'

import 'react-toastify/dist/ReactToastify.css';

function app() {
  return (
    <div className="app">
        <ToastContainer autoClose={3000} />
        <RoutesApp/>
    </div>
  );
}

export default app;