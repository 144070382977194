import { useState } from "react";
import './deleteGroupDialogBox.css';
import IconDelete from '../../../../assets/icon-delete-30-30-white.png';

interface DeleteGroupDialogProps {
    groupName: string;
    onDeleteGroup: () => void;
    onCloseDeleteGroupDialogBox: () => void;
    onCloseGroupDetailsModal: () => void;
}

export function DeleteGroupDialogBox({groupName,onDeleteGroup,onCloseDeleteGroupDialogBox,onCloseGroupDetailsModal}:DeleteGroupDialogProps){
    const [inputGroupNameQuestion, setInputGroupNameQuestion] = useState('');

    function handleConfirmDelete(){
        if (inputGroupNameQuestion === groupName){
            onDeleteGroup();
            onCloseDeleteGroupDialogBox();
            onCloseGroupDetailsModal();
        }
    }

    return(
        <div id='container-modal-delete-group-dialog'>
            <div id='container-form-delete-group-dialog'>
                <label>Type the group title <strong>&apos;{groupName}&apos;</strong> to ensure the delete action. This action cannot be undone.</label>
                <div id='container-input-button-delete-group-dialog'>
                    <input type="text" 
                                    placeholder='Group name'
                                    name='groupName'
                                    value={inputGroupNameQuestion} 
                                    onChange={(event)=>{setInputGroupNameQuestion(event.target.value)}}
                    />
                    <button id='button-delete-group' onClick={handleConfirmDelete}>
                        <img src={IconDelete}/>
                        Confirm
                    </button>
                </div>
                
            </div>
        </div>
    )
}