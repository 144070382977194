import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthHeaders, Group, Project, User } from "../../../#interfaces/interfaces";
import { getUserGenericConfigurations, patchAvatar, searchResultsWithQueryAndPagination } from "../../../api/sessionAPI";

export interface GetSearchResultsRouteParams{
    queryType: string;
    query: string;
    page: number;
}

export interface GetSearchResults{
    headers: AuthHeaders;
    routeParams: GetSearchResultsRouteParams
}

interface SearchResult{
    users: User[];
    groups: Group[];
    projects: Project[];
    errors: [];
    loadingMoreResults: boolean;
}

const initialState: SearchResult = {
    users: [],
    groups: [],
    projects: [],
    errors: [],
    loadingMoreResults: false
}


export const getSearchResults = createAsyncThunk(
    'sessionSearch/getSearchResults',
    async (payload: GetSearchResults, {rejectWithValue}) => {
        const response = await searchResultsWithQueryAndPagination(
            payload.headers,
            payload.routeParams
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
  )

const searchSlice = createSlice({
    name: 'sessionSearch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getSearchResults.pending, (state, action) => {
        
        if (action.meta.arg.routeParams.page === 1){
          state.users = [];
          state.groups = [];
          state.projects = [];
        }
        state.loadingMoreResults = true;
      })
      .addCase(getSearchResults.fulfilled, (state, action: any) => {

        if (action.meta.arg.routeParams.queryType == "users"){
          state.users = convertKeysToCamelCase(action.payload);
        }
        else if (action.meta.arg.routeParams.queryType == "groups"){
          state.groups = [...state.groups,...convertKeysToCamelCase(action.payload)];
        }
        else if (action.meta.arg.routeParams.queryType == "projects"){
          state.projects = [...state.projects,...convertKeysToCamelCase(action.payload)];
        }

        state.loadingMoreResults = false;
      })
      .addCase(getSearchResults.rejected, (state, action: any) => {
        state.loadingMoreResults = false;
      })
    }
    })

export const searchSliceReducers = searchSlice.reducer
export const searchSliceActions = searchSlice.actions

export function sortAndMergeGroups(...lists: any[][]): any[] {

    const mergedList = ([] as any[]).concat(...lists);

    const compareDates = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return  dateB.getTime() - dateA.getTime();
    };

    mergedList.sort((a, b) => compareDates(a.created_at, b.created_at));

    const mergedListConverted = convertKeysToCamelCase(mergedList);
    return mergedListConverted;
}

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}

function convertKeysToSnakeCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToSnakeCase(item));
    } else {
      if (obj.constructor === Object) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const snakeCaseKey = key === 'accessToken' ? key : key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
            newObj[snakeCaseKey] = obj[key];
          }
        }
        return newObj;
      }
    }
  }
  return obj;
}