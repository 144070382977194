import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthHeaders, MusicalGenre, UserMoreInfo } from "../../../#interfaces/interfaces";
import { deleteGroupWithGroupId, getMusicGenres, getRouteUserGroups, getUserGenericConfigurations, patchAvatar, patchGerneralConfig, patchPrivacityConfig, postCreateGroup } from "../../../api/sessionAPI";

interface GetMusicalGenresData {
  authHeaders: AuthHeaders;
}

interface GetUserGroupsData {
  authHeaders: AuthHeaders;
  userNickname: string;
  page: string;
}

interface PostCreateGroupsData{
  authHeaders: AuthHeaders;
  userNickname: string;
  postBody: PostCreateGroupsBody;
}

interface DeleteGroupData{
  authHeaders: AuthHeaders;
  groupId: string;
}

interface PostCreateGroupsBody {
  name: string;
  description: string;
  privacity: number;
  musical_genres: number[];
}

export interface User {
  email?: string;
  provider?: string;
  uid?: string;
  id?: number;
  allowPasswordChange?: boolean;
  name?: string;
  image?: string;
  nickname?: string;
  role?: string;
  confirmed?: string;
  updatedAt?: string;
  createdAt?: string;
  refreshToken?: string;
  userMoreInfo?: UserMoreInfo;
}

export interface Group {
    id: number;
    description: string;
    privacity: number;
    nMembers: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    groupMusicalGenres: GroupMusicalGenre[];
}

export interface GroupMusicalGenre {
  createdAt?: string;
  updatedAt?: string;
  groupId?: number;
  id?: number;
  musicGenreId?: number;
  musicGenre: MusicalGenre;
}

export interface MemberGroup {
    group: Group;
    groupId: number;
    createdAt: number;
    id: number;
    isAdmin: boolean;
    isCreator: boolean;
    updatedAt: string;
    userId: number;
    user: User;
}

interface Groups {
    group?: Group;
    userGroups: MemberGroup[];
    newUserGroups: MemberGroup[];
    loading?: boolean;
    errors: string[];
    musicalGenres: MusicalGenre[];
    loadingScreen: boolean;
}

const initialState: Groups = {
    group: undefined,
    userGroups: [],
    newUserGroups: [],
    errors: [],
    loading: false,
    musicalGenres: [],
    loadingScreen: false
}

export const getAllMusicGenres = createAsyncThunk(
  'groupsSlice/getAllMusicGenres',
  async (payload: GetMusicalGenresData, {rejectWithValue}) => {
      const response = await getMusicGenres(
          payload.authHeaders
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getUserGroups = createAsyncThunk(
    'groupsSlice/getUserGroups',
    async (payload: GetUserGroupsData, {rejectWithValue}) => {
        const response = await getRouteUserGroups(
            payload.authHeaders,
            payload.userNickname,
            payload.page
        )

        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
  )

export const createGroup = createAsyncThunk(
  'groupsSlice/createGroup',
  async (payload: PostCreateGroupsData, {rejectWithValue}) => {
      const response = await postCreateGroup(
          payload.authHeaders,
          payload.userNickname,
          payload.postBody
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const deleteGroup = createAsyncThunk(
  'groupsSlice/deleteGroup',
  async (payload: DeleteGroupData, {rejectWithValue}) => {
      const response = await deleteGroupWithGroupId(
          payload.authHeaders,
          payload.groupId
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

const groupsSlice = createSlice({
    name: 'groupsSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getUserGroups.pending, (state, action: any) => {
        state.errors = [];
        state.loading = true;

        if (action.meta.arg.page === "1"){
          state.newUserGroups = [];
        }
      })
      .addCase(getUserGroups.fulfilled, (state, action: any) => {
        if (convertKeysToCamelCase(action.payload).length > 0){
          state.userGroups = [...state.userGroups, ...convertKeysToCamelCase(action.payload)];
        }
        state.loading = false;
      })
      .addCase(getUserGroups.rejected, (state) => {
        state.errors = [];
        state.loading = false;
      })
      .addCase(getAllMusicGenres.pending, (state) => {
        state.errors = [];
        state.loading = true;
      })
      .addCase(getAllMusicGenres.fulfilled, (state, action: any) => {
        state.musicalGenres = convertKeysToCamelCase(action.payload)
        state.loading = false;
      })
      .addCase(getAllMusicGenres.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createGroup.pending, (state) => {
        state.errors = [];
        state.newUserGroups = [];
        state.loadingScreen = true;
      })
      .addCase(createGroup.fulfilled, (state, action: any) => {
        state.newUserGroups = [...convertKeysToCamelCase(action.payload),...state.newUserGroups];
        state.loadingScreen = false;
      })
      .addCase(createGroup.rejected, (state, action: any) => {
        const payloadConverted = convertKeysToCamelCase(action.payload);

        state.errors = payloadConverted.errors
        state.loadingScreen = false;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.errors = [];
        state.loadingScreen = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action: any) => {

        state.userGroups = state.userGroups.filter(memberGroup => memberGroup.group.id !== action.payload.id);

        state.newUserGroups = state.newUserGroups.filter(memberGroup => memberGroup.group.id !== action.payload.id);

        state.errors = [];
        state.loadingScreen = false;
      })
      .addCase(deleteGroup.rejected, (state) => {
        state.errors = [];
        state.loadingScreen = false;
      })
    }
})



export const groupsSliceReducers = groupsSlice.reducer
export const groupsSliceActions = groupsSlice.actions

export function sortAndMergeGroups(...lists: any[][]): any[] {

    const mergedList = ([] as any[]).concat(...lists);

    const compareDates = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return  dateB.getTime() - dateA.getTime();
    };

    mergedList.sort((a, b) => compareDates(a.created_at, b.created_at));

    const mergedListConverted = convertKeysToCamelCase(mergedList);
    return mergedListConverted;
}

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}

function convertKeysToSnakeCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToSnakeCase(item));
    } else {
      if (obj.constructor === Object) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const snakeCaseKey = key === 'accessToken' ? key : key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
            newObj[snakeCaseKey] = obj[key];
          }
        }
        return newObj;
      }
    }
  }
  return obj;
}