import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { toast } from 'react-toastify';
import { getInstruments, uploadContent } from '../../../features/sessions/sessionAudioContent/sliceUserContents';
import { FiX } from 'react-icons/fi';
import './createAudioContentRecorded.css'
import { ContentPlayer } from '../../audioPlayer/WaveSurfer/contentPlayer/ContentPlayer';
import LoadingModal from '../loadingModal/LoadingModal';

interface CreateAudioContentRecordedProps {
    onClose: (isOpen: boolean) => void;
    audioRecorded: string | null;
}

export default function CreateAudioContentRecorded({onClose ,audioRecorded}: CreateAudioContentRecordedProps ){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const [titleForm, setTitleForm] = useState<string>();
    const [descriptionForm, setDescriptionForm] = useState<string>();
    const [typeIdForm, setTypeIdForm] = useState<number>();
    const [downloadAbledForm, setDownloadAbledForm] = useState<string>('true');
    const errors = useSelector((state: RootState) => state.sessionUserContents.errors);
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector((state: RootState) => state.sessionUserContents.loading);
    const instrumentsForm = useSelector((state: RootState) => state.sessionUserContents.instrumentsForm);
    const [instrumentSelected, setInstrumentSelected] = useState<number>(1);
    const MemorizedContentPlayer = useMemo(() => React.memo(ContentPlayer), []);
    const loadingRecordedAudioCreation = useSelector((state: RootState) => state.sessionUserContents.loading);

    useEffect(() => {
        async function handleInstrumentsComboBox() {
            await dispatch(getInstruments());
        }

        handleInstrumentsComboBox();
    },[])

    async function handlePostContent(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (audioRecorded) {
            const response = await fetch(audioRecorded);
            const blob = await response.blob();
            const file = new File([blob], 'recorded-audio.ogg', { type: 'audio/ogg' });

            const requestBody = {
                title: titleForm,
                description: descriptionForm,
                typeId: typeIdForm,
                file: file,
                downloadAbled: downloadAbledForm,
                instrumentId: instrumentSelected
            };

            const responseUpload = await dispatch(uploadContent({ authHeaders, currentUser, requestBody }));

            if (responseUpload.meta.requestStatus === "fulfilled"){
                handleBlankForm();
                onClose(false);
            } else if (responseUpload.payload.errors && responseUpload.payload.errors.length > 0) {
                responseUpload.payload.errors.map((item: string) => { toast.error(item) });
            }
        }
    }

    const handleBlankForm = () => {
        setTitleForm('');
        setDescriptionForm('');
        setTypeIdForm(1);
    }

    return(
        <>
        
        {
            loadingRecordedAudioCreation === false ?

            <div id='container-modal-create-audio-recorded'>

                <div id='container-screen-transparent-audio-content-modal'>
                    <div id='container-form-labels-create-content-recorded-modal'>
                        <div id='container-top-form-labels-create-content-recorded-modal'>
                            <h1>Create Content</h1>

                            <button onClick={() => onClose(false)}>
                                <FiX size={20} color="#FFF"/>
                            </button>
                        </div>
                        <div id='container-middle-form-labels-create-content-recorded-modal'>
                            <MemorizedContentPlayer fileUrl={audioRecorded}/>
                        </div>
                        <div id='container-bottom-form-labels-create-content-recorded-modal'>
                            <form id='container-form-add-content-recorded' onSubmit={handlePostContent} encType="multipart/form-data">
                                <div id='container-title'>
                                    <label>Title</label>
                                    <input type="text" 
                                        placeholder='content title'
                                        name='title'
                                        value={titleForm} 
                                        onChange={(event)=>{setTitleForm(event.target.value)}}
                                    />
                                </div>

                                <div id='container-description'>
                                    <label>Description</label>
                                    <textarea placeholder='Description' 
                                            name='description'
                                            value={descriptionForm} 
                                            onChange={(event) => {setDescriptionForm(event.target.value)}}/>
                                </div>

                                <div id='container-content-type'>
                                    <label>Content Type</label>
                                    <div id='content-type'>
                                        <input
                                            type='radio'
                                            name='radio'
                                            value={1}
                                            onChange={() => {setTypeIdForm(1)}}
                                            checked={typeIdForm === 1}
                                        />
                                        <span>Audio</span>

                                        <input
                                            type="radio"
                                            name="radio"
                                            value={2}
                                            onChange={() => {() => {console.log("content type field 2")}}}
                                            checked={typeIdForm === 2}
                                            disabled
                                        />
                                        <span style={{color: "gray"}} >Text</span>

                                        <input
                                            type="radio"
                                            name="radio"
                                            value={3}
                                            onChange={() => {() => {console.log("content type field 3")}}}
                                            checked={typeIdForm === 3}
                                            disabled
                                        />
                                        <span style={{color: "gray"}} >Video</span>
                                    </div>
                                </div>
                                <div id='container-download-abled'>
                                    <label>Download Abled</label>
                                    <div id='download-abled'>
                                        <input
                                            type='radio'
                                            name='radio-download-abled'
                                            value={'true'}
                                            onChange={() => {setDownloadAbledForm('true')}}
                                            checked={downloadAbledForm === 'true'}
                                        />
                                        <span>True</span>

                                        <input
                                            type="radio"
                                            name="radio-download-abled"
                                            value={'false'}
                                            onChange={() => {setDownloadAbledForm('false')}}
                                            checked={downloadAbledForm === 'false'}
                                        />
                                        <span>False</span>
                                    </div>
                                </div>
                                <div id='container-instrument-selection'>
                                    <label>Instrument</label>
                                    <select id="instruments-combo-box" name="filter-projects" onChange={(event: any) => setInstrumentSelected(event.target.value)}>
                                        {instrumentsForm && instrumentsForm.map(instrument => (
                                        <option key={instrument.id} value={instrument.id}>{instrument.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div id='submit-button-container'>
                                    <button type='submit' id='upload-button'>Upload</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            :

            <LoadingModal/>
            
            }
        </>

    )
}