import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './app';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { authenticateUserFromStorage } from './app/features/sessions/sessionSlice';

const container = document.getElementById('root')!;
const root = createRoot(container);

export async function checkAuthentication() {

  try {
    await store.dispatch(authenticateUserFromStorage());
  } catch (error) {
    console.error('Erro ao verificar a autenticação:', error);
  }
}

// Chame a função de verificação de autenticação ao inicializar o aplicativo
checkAuthentication();

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();