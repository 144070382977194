
import { useEffect, useRef, useState } from 'react';
import { useDispatch, 
         useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../app/store';
import { authenticateUserFromStorage } from '../../../../app/features/sessions/sessionSlice';
import { Group, MemberGroup, getUserGroups } from '../../../../app/features/sessions/sessionGroups/sessionGroups';
import './memberGroups.css'
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateGroupForm } from '../../../../app/components/forms/groups/CreateGroupForm';
import MoreDetailsIcon from '../../../../app/assets/more-details-white.png';
import { GroupDetailsModal } from '../../../../app/components/modal/groupModal/GroupDetailsModal';
import LoadingModalScreenContainer from '../../../../app/components/modal/loadingModalScreenContainer/LoadingModalScreenContainer';
import * as REQUEST_REQUIREMENTS from '../../../../app/api/requestRequirements';

function MemberGroups(){
    const { userNicknameRoute } = useParams();
    const navigate = useNavigate();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const userGroups = useSelector((state: RootState) => state.sessionGroups.userGroups);
    const newUserGroups = useSelector((state: RootState) => state.sessionGroups.newUserGroups);
    const dispatch = useDispatch<AppDispatch>();
    const userGroupsCreated = [1]
    const noGroupsCondition = (userGroups && userGroups.length === 0) && (userGroupsCreated && userGroupsCreated.length === 0);
    const containerClasses = classNames('groups-container', {
        'no-groups-style': noGroupsCondition
    });
    const [page, setPage] = useState(1);
    const [loadingMoreGroups,setLoadingMoreGroups] = useState(false);
    const containerRef = useRef(null);
    const nmembers = useSelector((state: RootState) => state.sessionGroups.group?.nMembers);
    const visibleItems = 10;
    const [showGroupModal,setShowGroupModal] = useState(false);
    const [groupSelectedToShowDetails, setGroupSelectedToShowDetails] = useState<Group>();
    const [memberGroupSelected,setMemberGroupSelectedToShowDetails] = useState<MemberGroup>();
    const loadingScreen =  useSelector((state: RootState) => state.sessionGroups.loadingScreen);

    useEffect(() => {
        async function authUserOnRoute() {
            if (!userValidated) {
                await dispatch(authenticateUserFromStorage());
            }
        }

        authUserOnRoute();
    }, [userValidated]);

    useEffect(() => {
        async function fetchData() {
            if (authHeaders && userNicknameRoute && !loadingMoreGroups && !noGroupsCondition) {
                setLoadingMoreGroups(true);
                try {
                    await dispatch(getUserGroups({ authHeaders: authHeaders, userNickname: userNicknameRoute, page: `${page}` }));
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoadingMoreGroups(false);
                }
            }
        }

        fetchData();
    }, [authHeaders, userNicknameRoute, page, noGroupsCondition]);

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('container-ul-member-groups');
            if (!container) return;
    
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight && !loadingMoreGroups) {
                setPage(page + 1);
            }
        };
    
        setTimeout(() => {
            const container = document.getElementById('container-ul-member-groups');
            if (container){
                container.addEventListener('scroll', handleScroll);
                return () => container.removeEventListener('scroll', handleScroll);
            }
        }, 100);
    }, [loadingMoreGroups]);


    async function handleNavigateEnterGroup(){

        if (groupSelectedToShowDetails){
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: {userNickname: memberGroupSelected?.user.nickname, 
                                                                                            groupId: groupSelectedToShowDetails.id} })

            navigate(PRIVATE_ROUTES.GROUP_UPDATE_SHOW)
        }
    }

    return (
        
        <div id='container-page-member-groups'>

            {
                showGroupModal && groupSelectedToShowDetails ?
                <GroupDetailsModal group={groupSelectedToShowDetails} 
                                   open={setShowGroupModal}
                                   enterGroup={handleNavigateEnterGroup}
                />
                :

                null
            }
            <>
                <div id='container-member-groups-left'>

                </div>

                <div id='container-member-groups-center'>
                    <div id='container-form-and-member-groups'>
                        {currentUser && currentUser.nickname === userNicknameRoute ?
                            <CreateGroupForm/>
                            :
                            null
                        }
                        <div id='container-member-groups'>
                        
                            <div className={containerClasses}>
                                { noGroupsCondition ?
                                    <div id='container-ul-member-groups'>
                                        <h3 id='no-groups-yet'>
                                            You don&apos;t have groups yet
                                        </h3>
                                    </div>
                                    :
                                    <div id='container-ul-member-groups'>
                                        {loadingScreen?
        
                                        <LoadingModalScreenContainer/>

                                        :

                                        <ul id='ul-member-groups'>
                                        {newUserGroups && newUserGroups.map((item, index) => (
                                                <div id='container-li-member-groups' key={index}>
                                                    <li id='li-member-groups' key={index}>
                                                        <div id='container-li-top'>
                                                            <div id='container-top-left'>
                                                                <div id='container-group-title'>
                                                                    <h4>{item.group.name}</h4>
                                                                </div>
                                                                <div id='container-group-info'>
                                                                    <span id='members-span'>Members:</span>
                                                                    <span>{item.group.nMembers}</span>
                                                                </div>
                                                            </div>
                                                            <div id='container-owner'>
                                                                <img id='img-avatar' src={item.user.userMoreInfo?.avatarUrl}/>
                                                                <span>{item.user.nickname}</span>
                                                            </div>
                                                            <div id='container-button-more'>
                                                                <button id='button-more-info-group'
                                                                 onClick={() => {setShowGroupModal(true);
                                                                                 setGroupSelectedToShowDetails(item.group);
                                                                                 setMemberGroupSelectedToShowDetails(item);}}>
                                                                    <img src={MoreDetailsIcon}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id='container-li-middle'>
                                                            <pre>{item.group.description}</pre>
                                                        </div>
                                                        <div id='container-li-bottom'>
                                                            <ul>
                                                                {item.group.groupMusicalGenres &&
                                                                    item.group.groupMusicalGenres.slice(0, visibleItems).map((groupMusicalGenre, index2) => (
                                                                        <li key={index2}>
                                                                            <h6>{groupMusicalGenre.musicGenre.description}</h6>
                                                                        </li>
                                                                    ))
                                                                }
                                                                {item.group.groupMusicalGenres.length > 10 && (
                                                                    <li key="remaining">
                                                                        <label>...</label>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                    </div>
                                                    </li>
                                                </div>
                                            ))}
                                            {userGroups && userGroups.map((item, index) => (
                                                <div id='container-li-member-groups' key={index}>
                                                    <li id='li-member-groups' key={index}>
                                                        <div id='container-li-top'>
                                                            <div id='container-top-left'>
                                                                <div id='container-group-title'>
                                                                    <h4>{item.group.name}</h4>
                                                                </div>
                                                                <div id='container-group-info'>
                                                                    <span id='members-span'>Members:</span>
                                                                    <span>{item.group.nMembers}</span>
                                                                </div>
                                                            </div>
                                                            <div id='container-owner'>
                                                                <img id='img-avatar' src={item.user.userMoreInfo?.avatarUrl}/>
                                                                <span>{item.user.nickname}</span>
                                                            </div>
                                                            <div id='container-button-more'>
                                                                
                                                                <button id='button-more-info-group'
                                                                        onClick={() => {setShowGroupModal(true);
                                                                                        setGroupSelectedToShowDetails(item.group);
                                                                                        setMemberGroupSelectedToShowDetails(item);}}>
                                                                    <img src={MoreDetailsIcon}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id='container-li-middle'>
                                                            <pre>{item.group.description}</pre>
                                                        </div>
                                                        <div id='container-li-bottom'>
                                                            <ul>
                                                                {item.group.groupMusicalGenres &&
                                                                    item.group.groupMusicalGenres.slice(0, visibleItems).map((groupMusicalGenre, index2) => (
                                                                        <li key={index2}>
                                                                            <h6>{groupMusicalGenre.musicGenre.description}</h6>
                                                                        </li>
                                                                    ))
                                                                }
                                                                {item.group.groupMusicalGenres.length > 10 && (
                                                                    <li key="remaining" id='li-ellipsis'>
                                                                        <h6>...</h6>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </div>
                                            ))}
                                            {loadingMoreGroups
                                            
                                            ?
                                                <div id='container-loading-spineer'>
                                                    <CircularProgress color='secondary' sx={{ color: 'brown' }}/>
                                                </div>
                                            :
                                            null
                                            }
                                        </ul>
                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div id='container-member-groups-right'>

                </div>
            </>
        </div>
        
    )
}

export default MemberGroups;