import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import MicrofoneIcon from '../../../../app/assets/microfone-white-icon.png';

const pulseAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const RecordButton = styled.button<{ isRecording: boolean }>`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  background-color: ${({ isRecording }) => (isRecording ? 'rgb(156, 41, 41)' : 'rgb(220, 57, 57)')};
  cursor: pointer;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: -32px;
    left: -32px;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: 2px solid #ff0000;
    opacity: 0;
    animation: ${({ isRecording }) => (isRecording ? css`${pulseAnimation} 1s infinite` : 'none')};
  }
`;

const MicrophoneIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
`;

interface AudioContentRecorderProps {
  onOpenRecorderModal: React.Dispatch<React.SetStateAction<boolean>>;
  onAudioRecordedUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

const AudioContentRecorder: React.FC<AudioContentRecorderProps> = ({ onOpenRecorderModal, onAudioRecordedUrl }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const chunks = useRef<Blob[]>([]);
  const audioRef = useRef<HTMLAudioElement>(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream, { mimeType: 'audio/ogg' });
      mediaRecorder.current.ondataavailable = handleDataAvailable;
      mediaRecorder.current.start();
      setIsRecording(true);
      chunks.current = []; // Clear the chunks array at the start of each recording
      setAudioBlob(null); // Clear the audio blob
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
  };

  const handleDataAvailable = (event: BlobEvent) => {
    if (event.data.size > 0) {
      chunks.current.push(event.data);
      const blob = new Blob(chunks.current, { type: 'audio/ogg' });
      setAudioBlob(blob);
      const audioUrl = URL.createObjectURL(blob);
      onAudioRecordedUrl(audioUrl);
    }
  };

  const handleToggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    if (audioBlob && audioRef.current) {
      // audioRef.current.src = URL.createObjectURL(audioBlob);
      // audioRef.current.play();
      onAudioRecordedUrl(URL.createObjectURL(audioBlob));

      onOpenRecorderModal(true);
    }
  }, [audioBlob]);

  return (
    <div className="audio-recorder">
      <RecordButton className={`record-button ${isRecording ? 'recording' : ''}`} isRecording={isRecording} onClick={handleToggleRecording}>
        <MicrophoneIcon src={MicrofoneIcon} alt="Microphone Icon" />
      </RecordButton>
      <audio controls ref={audioRef} style={{ display: 'none' }} />
    </div>
  );
};

export default AudioContentRecorder;
