import { useEffect, useState } from "react";
import { ModalSelectGroupGenres } from "../../modal/modalSelectGroupGenres/ModalSelectGroupGenres";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { createGroup, getAllMusicGenres } from "../../../features/sessions/sessionGroups/sessionGroups";
import { authenticateUserFromStorage } from "../../../features/sessions/sessionSlice";
import { toast } from 'react-toastify';
import './createGroupForm.css';

export function CreateGroupForm(){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const [createGroupClicked, setCreateGroupClicked] = useState<boolean>(false);
    const [groupNameForm,setGroupNameForm] = useState('');
    const [descriptionGroupForm,setDescriptionGroupForm] = useState('');
    const [groupPrivacityForm, setGroupPrivacityForm] = useState(0);
    const [selectGroupGenreModalOpenClose, setSelectGroupGenreModalOpenClose] = useState(false);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const [selectedGenres, setSelectedGenres] = useState<number[]>([]);

    useEffect(() => {
        async function authUserOnRoute() {
            if (!userValidated) {
                await dispatch(authenticateUserFromStorage());
            }
        }

        authUserOnRoute();
    }, [userValidated]);


    useEffect(() => {
        async function getMusicalGenres(){
            if (authHeaders){
                await dispatch(getAllMusicGenres({authHeaders: authHeaders}))
            }
        } 

        getMusicalGenres();
    },[])

    async function handleCreateGroup(){

        if (authHeaders && currentUser && currentUser.nickname){
            const response = await dispatch(createGroup({authHeaders:authHeaders, 
                                        userNickname: currentUser.nickname,
                                        postBody:{
                                            name: groupNameForm,
                                            description: descriptionGroupForm,
                                            privacity: groupPrivacityForm,
                                            musical_genres: selectedGenres
                                        }}))
                                        
            if (response.payload.errors && response.payload.errors.length > 0){
                response.payload.errors.map((item: string) => {toast.error(item)})
            }
        }
    }


    return(
        <div id='container-form-labels-create-group'>
            {selectGroupGenreModalOpenClose ?
                <ModalSelectGroupGenres selectedGenres={selectedGenres}
                                        setSelectedGenres={setSelectedGenres}
                                        close={setSelectGroupGenreModalOpenClose}/>
                :
                null
            }

            {createGroupClicked == true
                ? 
                <div id='container-form-create-group'>
                    <h3 style={{display:"flex", marginTop: "0px", marginBottom: "5px"}}>Create Group</h3>
                    <div id='container-title'>
                        <label>Group name</label>
                        <input type="text" 
                            placeholder='Group name'
                            name='groupName'
                            value={groupNameForm} 
                            onChange={(event)=>{setGroupNameForm(event.target.value)}}
                        />
                    </div>

                    <div id='container-description'>
                        <label>Description</label>
                        <textarea placeholder='Description' 
                                name='description'
                                value={descriptionGroupForm} 
                                onChange={(event) => {setDescriptionGroupForm(event.target.value)}}/>
                    </div>

                    <div id='container-privacity'>
                        <label>Privacity</label>
                        <div id='group-privacity'>
                            <input
                                type='radio'
                                name='radio'
                                value={0}
                                onChange={() => {setGroupPrivacityForm(0)}}
                                checked={groupPrivacityForm === 0}
                            />
                            <span>Public</span>

                            <input
                                type="radio"
                                name="radio"
                                value={1}
                                onChange={() => {() => {setGroupPrivacityForm(1)}}}
                                checked={groupPrivacityForm === 1}
                            />
                            <span>Private</span>
                        </div>
                    </div>
                    <div id='container-select-group-genre'>
                        <button id="button-select-group-genres" onClick={()=>{setSelectGroupGenreModalOpenClose(true)}}>
                            Select Genres
                        </button>
                    </div>

                    <div id='container-button-create-container'>
                        <button id='button-create-group' onClick={()=>{handleCreateGroup()}}>
                            Create
                        </button>
                    </div>
                </div>
                :
                <button id="button-open-form" onClick={()=>{setCreateGroupClicked(!createGroupClicked)}}>
                    Create Group
                </button>
            }
        </div>
    )
}