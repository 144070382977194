import { FormControl, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const FormControlEdited = styled(FormControl)({
        '& label.Mui-focused': {
        color: 'Black',
        },
        '& .MuiInput-underline:after': {
        borderBottomColor: 'Black',
        }
    });

export const LinkEdited = styled(Link)({
    textDecoration: 'none', 
    "&:hover": {
        textDecoration: "underline"
    }
});

export const ButtonEdited = styled(Link)({
    backgroundColor: "brown", 
    color: "white",
    "&:hover": {
        textDecoration: "darkbrown"
    } 
});

// const LinkStyled = styled(Link)({
//     textDecoration: 'none', 
//     "&:hover": {
//         textDecoration: "underline"
//     }
//   });