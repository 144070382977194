import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { createFellowshipBetweenCurrentAndRouteUser, patchFellowshipBetweenCurrentAndRouteUser, getContents, getInstrumentsAvailable, getMusicGenres, getProjects, getUser, getUserFellowsWithUserId, getUserInstrumentWithUserNickname, 
         getUserMusicalGenresWithNickname, patchPrivacityConfig, 
         patchUserInstrumentWithNicknameAndInstrumentId, patchUserMusicalGenreWithNicknameAndMusicalGenreId} from "../../api/sessionAPI"
import { AuthHeaders, Content, Fellowship, Instrument, MusicalGenre, Project, User, UserInstrument, UserMoreInfo, UserMusicalGenre, UserPrivacityConfig, UserPropertyConfig } from "../../#interfaces/interfaces";


interface PatchProfileData {
  authHeaders: AuthHeaders;
  userNickname: string;
  patchBody: UserMoreInfo;
}

export interface GetUserProjectsRequestData{
  authHeaders: AuthHeaders;
  urlParams: GetProjectsRouteParams;
}

export interface GetProjectsRouteParams{
  userNickname: string;
  page: number;
}

interface GetProfileData {
    authHeaders: AuthHeaders;
    userNickname: string;
}

interface GetUserContentsData {
  authHeaders: AuthHeaders;
  routeParams: GetContentsRouteParams;
}

export interface GetContentsRouteParams{
  userNickname?: string; 
  contentsPage: number;
}


interface PatchPrivacityConfigData {
  authHeaders: AuthHeaders;
  userNickname: string;
  patchBody: PatchPrivacityConfigBody;
}

export interface PatchPrivacityConfigBody {
  contents?: PropertyBody;
  projects?: PropertyBody;
}

interface PropertyBody {
  id?: number;
  userPrivacityId?: number;
}

export interface GetUserMusicalGenresUrlParams {
  userNickname: string;  
}

export interface GetUserMusicalGenresRequestParams{
  headers: AuthHeaders;
  urlParams: GetUserMusicalGenresUrlParams;
}

export interface PatchUserInstrumentUrlParams{
  userNickname: string;
  userInstrumentId: number;
}

export interface PatchUserInstrumentBodyData{
  selected: boolean;
}

export interface PatchUserInstrumentsRequestParams{
  headers: AuthHeaders;
  urlParams: PatchUserInstrumentUrlParams;
  requestBodyData: PatchUserInstrumentBodyData; 
}

export interface CreateUserFellowshipRequestBodyData{
  profileNickname?: string;
}

export interface CreateUserFellowshipRequestParams{
  authHeaders: AuthHeaders;
  requestBodyData: CreateUserFellowshipRequestBodyData; 
}

export interface PatchUserFellowshipRequestUrlParams{
  profileNickname?: string;
  currentUserId?: number;
}

export interface PatchBodyUserFellowship{
  active: boolean
}

export interface PatchUserFellowshipRequestParams{
  authHeaders: AuthHeaders;
  urlParams: PatchUserFellowshipRequestUrlParams; 
  patchBody: PatchBodyUserFellowship;
}

export interface GetUserInstrumentUrlParams{
  userNickname: string;
}

export interface GetUserInstrumentsRequestParams{
  headers: AuthHeaders;
  urlParams: GetUserInstrumentUrlParams;
}


interface GetMusicalGenresRequestParams {
  authHeaders: AuthHeaders;
}

export interface PatchUserMusicalGenreUrlParams {
  userNickname: string;
  userMusicalGenreId: number
}

export interface PatchUserMusicalGenreBodyData {
  selected: boolean;
}

export interface PatchMusicalGenresRequestParams{
  headers: AuthHeaders;
  urlParams: PatchUserMusicalGenreUrlParams;
  requestBodyData: PatchUserMusicalGenreBodyData;
}

export interface GetCurrentUserFelowsUrlParams{
  currentUserId: number;
}

export interface GetCurrentUserFelowsRequestParams{
  authHeaders: AuthHeaders;
  urlParams: GetCurrentUserFelowsUrlParams;
}

export interface AddRemoveUserInstrument{
  userInstrumentId: number;
  selected: boolean;
}

export interface AddRemoveUserMusicalGenre{
  userMusicalGenreId: number;
  selected: boolean;
}

interface ProfileData {
    user?: User;
    userMoreInfo: UserMoreInfo;
    userPrivacityConfiguration: UserPrivacityConfig;
    errors: [];
    userContents: Content[];
    userProjects: Project[];
    loading?: boolean;
    instrumentsAvailable: Instrument[];
    userInstruments: UserInstrument[];
    loadingProfilePage: boolean;
    musicalGenresAvailable: MusicalGenre[];
    userMusicalGenres: UserMusicalGenre[];
    loadingMoreUserContents: boolean;
    loadingMoreUserProject: boolean;
    currentUserFellows: Fellowship[];
}

const initialState: ProfileData = {
    user: undefined,
    userMoreInfo: {  
      name: '',
      nickname: '',
      email: '',
      phoneNumber: '',
      website: '',
      lastName: '',
      city: '',
      state: '',
      country: '',
      about: '',
      avatarUrl: '',
      cover: undefined
    },
    userPrivacityConfiguration: {
      contents: {},
      projects: {}
    },
    errors: [],
    userContents: [],
    userProjects: [],
    loading: false,
    instrumentsAvailable: [],
    musicalGenresAvailable: [],
    currentUserFellows: [],
    userInstruments: [],
    userMusicalGenres: [],
    loadingMoreUserContents: false,
    loadingProfilePage: false,
    loadingMoreUserProject: false
}

export const getGenericUserInfo = createAsyncThunk(
    'sessionProfile/getGenericUserInfo',
    async (payload: GetProfileData, {rejectWithValue}) => {
        const response = await getUser(
            payload.authHeaders,
            payload.userNickname,
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
)

export const getUserContents = createAsyncThunk(
  'sessionProfile/getUserContents',
  async (payload: GetUserContentsData, {rejectWithValue}) => {
      const response = await getContents(
          payload.authHeaders,
          payload.routeParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getRouteProjects = createAsyncThunk(
  'sessionProfile/getRouteProjects',
  async (payload: GetUserProjectsRequestData, {rejectWithValue}) => {
      const response = await getProjects(
          payload.authHeaders,
          payload.urlParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getUserMusicalGenres = createAsyncThunk(
  'sessionProfile/getUserMusicalGenresWithNickname',
  async (payload: GetUserMusicalGenresRequestParams, {rejectWithValue}) => {
      const response = await getUserMusicalGenresWithNickname(
          payload.headers,
          payload.urlParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getInstruments = createAsyncThunk(
  'sessionProfile/getInstruments',
  async (_, { rejectWithValue }) => {
      const response = await getInstrumentsAvailable()

      if (response.status >= 200 && response.status<300){
        return response.data
      } else {
        return rejectWithValue(response)
      }
  }
)

export const getUserInstruments = createAsyncThunk(
  'sessionProfile/getUserInstruments',
  async (payload: GetUserInstrumentsRequestParams, {rejectWithValue}) => {
      const response = await getUserInstrumentWithUserNickname(
          payload.headers,
          payload.urlParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getUserInstrumentsProfile = createAsyncThunk(
  'sessionProfile/getUserInstrumentsProfile',
  async (payload: GetUserInstrumentsRequestParams, {rejectWithValue}) => {
      const response = await getUserInstrumentWithUserNickname(
          payload.headers,
          payload.urlParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getMusicalGenresAvailable = createAsyncThunk(
  'groupsSlice/getMusicalGenresAvailable',
  async (payload: GetMusicalGenresRequestParams, {rejectWithValue}) => {
      const response = await getMusicGenres(
          payload.authHeaders
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const patchUserMusicalGenre = createAsyncThunk(
  'groupsSlice/patchUserMusicalGenre',
  async (payload: PatchMusicalGenresRequestParams, {rejectWithValue}) => {
      const response = await patchUserMusicalGenreWithNicknameAndMusicalGenreId(
          payload.headers,
          payload.urlParams,
          payload.requestBodyData
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const patchUserInstrument = createAsyncThunk(
  'sessionProfile/createUserInstrument',
  async (payload: PatchUserInstrumentsRequestParams, {rejectWithValue}) => {
      const response = await patchUserInstrumentWithNicknameAndInstrumentId(
          payload.headers,
          payload.urlParams,
          payload.requestBodyData
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getCurrentUserFelows = createAsyncThunk(
  'sessionProfile/getCurrentUserFelows',
  async (payload: GetCurrentUserFelowsRequestParams, {rejectWithValue}) => {
      const response = await getUserFellowsWithUserId(
          payload.authHeaders,
          payload.urlParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const createUserFellowship = createAsyncThunk(
  'sessionProfile/createUserFellowship',
  async (payload: CreateUserFellowshipRequestParams, {rejectWithValue}) => {
      const response = await createFellowshipBetweenCurrentAndRouteUser(
          payload.authHeaders,
          payload.requestBodyData
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const patchUserFellowship = createAsyncThunk(
  'sessionProfile/patchUserFellowship',
  async (payload: PatchUserFellowshipRequestParams, {rejectWithValue}) => {
      const response = await patchFellowshipBetweenCurrentAndRouteUser(
          payload.authHeaders,
          payload.urlParams,
          payload.patchBody
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

const profileSlice = createSlice({
    name: 'sessionProfile',
    initialState,
    reducers: {
      addRemoveUserInstrument: (state, action: PayloadAction<AddRemoveUserInstrument>) => {
        const { userInstrumentId, selected } = action.payload;
          const existingInstrumentIndex = state.userInstruments.findIndex(
            (userInstrument) => userInstrument.id === userInstrumentId
        );
    
        if (existingInstrumentIndex !== -1) {
            state.userInstruments[existingInstrumentIndex].selected = selected;
        }
      },
      addRemoveUserMusicalGenre: (state, action: PayloadAction<AddRemoveUserMusicalGenre>) => {
        const { userMusicalGenreId, selected } = action.payload;
          const existingMusicGenreIndex = state.userMusicalGenres.findIndex(
            (userMusicalGenre) => userMusicalGenre.id === userMusicalGenreId
        );
    
        if (existingMusicGenreIndex !== -1) {
            state.userMusicalGenres[existingMusicGenreIndex].selected = selected;
        }
      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getGenericUserInfo.pending, (state) => {
        state.loadingProfilePage = true
      })
      .addCase(getGenericUserInfo.fulfilled, (state, action: any) => {
        const responseCamelCase = convertKeysToCamelCaseAndSortByUpdatedAt(action.payload);
        const responseMoreInfo = responseCamelCase.userMoreInfo;
        const responsePrivacityConfiguration = responseCamelCase.userPrivacityConfiguration;
        const contents = responsePrivacityConfiguration.find((obj: UserPropertyConfig) => obj.userPropertyTypeId === 1);
        const projects = responsePrivacityConfiguration.find((obj: UserPropertyConfig) => obj.userPropertyTypeId === 2);
        
        state.userMoreInfo = responseMoreInfo;
        state.userPrivacityConfiguration.contents = contents;
        state.userPrivacityConfiguration.projects = projects;
        state.loadingProfilePage = false;
      })
      .addCase(getGenericUserInfo.rejected, (state) => {
        state.loadingProfilePage = false
      })
      .addCase(getUserContents.pending, (state, action: any) => {

        if (action.meta.arg.routeParams.contentsPage == 1){
          state.userContents = [];
        }

        state.loadingMoreUserContents = true;
      })
      .addCase(getUserContents.fulfilled, (state, action: any) => {

        state.userContents = convertKeysToCamelCaseAndSortByUpdatedAt(action.payload);
        state.loadingMoreUserContents = false;
      })
      .addCase(getUserContents.rejected, (state) => {
        state.loadingMoreUserContents = false;
      })
      .addCase(getRouteProjects.pending, (state) => {
        state.userProjects = [];
        state.loadingProfilePage = true;
        state.loadingMoreUserProject = true;
      })
      .addCase(getRouteProjects.fulfilled, (state, action: any) => {
        const allUserProjects = convertKeysToCamelCase(action.payload)
        const publicUserProjects = allUserProjects.userRoutePublicPrivate[0]
        const privateUserProjects = allUserProjects.userRoutePublicPrivate[1]
        const publicProjectsUserAsContributor = allUserProjects.userRouteContributorPublicPrivate[0]
        const privateProjectsUserAsContributor = allUserProjects.userRouteContributorPublicPrivate[1]
        state.userProjects = sortAndMergeGroups(publicUserProjects,
                                                privateUserProjects,
                                                publicProjectsUserAsContributor,
                                                privateProjectsUserAsContributor)
        state.loadingProfilePage = false;
        state.loadingMoreUserProject = false;
      })
      .addCase(getRouteProjects.rejected, (state) => {
        state.loadingProfilePage = false;
        state.loadingMoreUserProject = false;
      })
      .addCase(getInstruments.pending, (state) => {
          state.instrumentsAvailable = [];
      })
      .addCase(getInstruments.fulfilled, (state, action: any) => {
          state.instrumentsAvailable = convertKeysToCamelCase(action.payload);
      })
      .addCase(getInstruments.rejected, (state) => {
          state.instrumentsAvailable = [];
      })
      .addCase(getUserInstrumentsProfile.pending, (state) => {
        state.loadingProfilePage = true;
      })
      .addCase(getUserInstrumentsProfile.fulfilled, (state, action: any) => {
        const filteredInstruments = convertKeysToCamelCase(action.payload).filter(
          (userInstrument: UserInstrument) => userInstrument.selected === true
        );

        state.userInstruments = filteredInstruments;
        state.loadingProfilePage = false;
      })
      .addCase(getUserInstrumentsProfile.rejected, (state) => {
        state.loadingProfilePage = false;
      })
      .addCase(getUserInstruments.pending, (state) => {
        state.loading = false;
      })
      .addCase(getUserInstruments.fulfilled, (state, action: any) => {
        state.userInstruments = convertKeysToCamelCase(action.payload);
        state.loading = false;
      })
      .addCase(getUserInstruments.rejected, (state) => {
        state.loading = false;
      })
      .addCase(patchUserInstrument.pending, (state) => {
        state.loading = false;
      })
      .addCase(patchUserInstrument.fulfilled, (state, action: any) => {
        state.loading = false;
      })
      .addCase(patchUserInstrument.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMusicalGenresAvailable.pending, (state) => {
        state.loading = false;
      })
      .addCase(getMusicalGenresAvailable.fulfilled, (state, action: any) => {
        state.loading = false;
      })
      .addCase(getMusicalGenresAvailable.rejected, (state) => {
        state.loading = false;
      })
      .addCase(patchUserMusicalGenre.pending, (state) => {
        state.loading = false;
      })
      .addCase(patchUserMusicalGenre.fulfilled, (state, action: any) => {
        state.loading = false;
      })
      .addCase(patchUserMusicalGenre.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserMusicalGenres.pending, (state) => {
        state.loading = false;
      })
      .addCase(getUserMusicalGenres.fulfilled, (state, action: any) => {
        state.userMusicalGenres = convertKeysToCamelCase(action.payload);
        state.loading = false;
      })
      .addCase(getUserMusicalGenres.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUserFellowship.pending, (state) => {
        state.loading = false;
      })
      .addCase(createUserFellowship.fulfilled, (state, action: any) => {
        state.currentUserFellows = [...state.currentUserFellows,convertKeysToCamelCase(action.payload)];
        state.loading = false;
      })
      .addCase(createUserFellowship.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCurrentUserFelows.pending, (state) => {
        state.loading = false;
      })
      .addCase(getCurrentUserFelows.fulfilled, (state, action: any) => {
        state.currentUserFellows = convertKeysToCamelCase(action.payload);
        state.loading = false;
      })
      .addCase(getCurrentUserFelows.rejected, (state) => {
        state.loading = false;
      })

      .addCase(patchUserFellowship.pending, (state) => {
        state.loading = false;
      })
      .addCase(patchUserFellowship.fulfilled, (state, action: any) => {
        state.currentUserFellows = state.currentUserFellows.map(fellowship =>
          fellowship.id === action.payload.id
              ? { ...fellowship, ...convertKeysToCamelCase(action.payload) }
              : fellowship
      );
        state.loading = false;
      })
      .addCase(patchUserFellowship.rejected, (state) => {
        state.loading = false;
      })
      }
    })

export const { addRemoveUserInstrument, addRemoveUserMusicalGenre } = profileSlice.actions;

export const profileSliceReducers = profileSlice.reducer
export const profileSliceActions = profileSlice.actions

export function sortAndMergeGroups(...lists: any[][]): any[] {

  const mergedList = ([] as any[]).concat(...lists);

  const compareDates = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return  dateB.getTime() - dateA.getTime();
  };

  mergedList.sort((a, b) => compareDates(a.created_at, b.created_at));

  const mergedListConverted = convertKeysToCamelCase(mergedList);
  return mergedListConverted;
}

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}

function convertKeysToSnakeCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToSnakeCase(item));
    } else {
      if (obj.constructor === Object) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const snakeCaseKey = key === 'accessToken' ? key : key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
            newObj[snakeCaseKey] = obj[key];
          }
        }
        return newObj;
      }
    }
  }
  return obj;
}

export function convertKeysToCamelCaseAndSortByName(objList: any[]): any[] {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

  return objList
    .map(convertKeysToCamelCase)
    .sort((a, b) => a.name.localeCompare(b.name));
}