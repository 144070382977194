import { useEffect, useRef, useState } from 'react';
import DefaultProfileImage from '../../assets/profile-default-red.png'
import './commentsInPost.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { deleteComment, getPostWithComments } from '../../features/sessions/sessionPostProject/sessionPostProject';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import IconDelete from '../../assets/icon-delete-30-30-white.png';
import { toast } from "react-toastify";

export default function PostCommentsInPost({ groupId, postId }: { groupId: string, postId: string }){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const postCommentsList = useSelector((state: RootState) => state.sessionProjectPosts.postComments);
    const loadingMorePostComments = useSelector((state: RootState) => state.sessionProjectPosts.loadingMorePostComments);
    const [postCommentsPage,setPostCommentsPage] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();
    
    useEffect(() => {

        async function showPostWithComments(){
            if (authHeaders && currentUser?.nickname && groupId && postId){

                await dispatch(getPostWithComments({
                                                      authHeaders: authHeaders,
                                                      routeParams: {
                                                          userNickname: currentUser?.nickname,
                                                          groupId: groupId,
                                                          postId: postId,
                                                          postCommentsPage: `${postCommentsPage}`
                                                      }
                                                    }
                                                  )
                              )
            }                  
        }

        showPostWithComments();

    },[postCommentsPage])

    function handleFormatDate(date:any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('container-ul-post-comments');
            if (!container) return;
    
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight && !loadingMorePostComments) {
                setPostCommentsPage(postCommentsPage + 1);
            }
        };
    
        setTimeout(() => {
            const container = document.getElementById('container-ul-post-comments');
            if (container){
                container.addEventListener('scroll', handleScroll);
                return () => container.removeEventListener('scroll', handleScroll);
            }
        }, 100);
    }, [loadingMorePostComments]);
   
    
    async function handleDeleteComment(postCommentId: string, postId: string){

      if (authHeaders && currentUser?.nickname){
        const response = await dispatch(deleteComment({
                                                  authHeaders: authHeaders,
                                                  routeParams: {
                                                      userNickname: currentUser?.nickname,
                                                      postCommentId: postCommentId,
                                                      postId: postId
                                                  }
                                            }))

        if (response.payload && 
            response.payload.errors && 
            response.payload.errors.length > 0) {

            response.payload.errors.map(
              (item: string) => {toast.error(item)}
            )
        }
        }
      }


    
    return (
        <>
          <div id='container-ul-post-comments' ref={containerRef}>
            <ul id='ul-post-comments'>
              { postCommentsList.length === 0 && !loadingMorePostComments ?
                <div id='container-no-post-comments-yet'>
                  <h1>No comments yet</h1>
                </div>

                :

                <div id='container-post-with-comments'>
                  { postCommentsList.map((item, index) => (
                    <div id='container-post-comment' key={index}>
                      <li id='li-post-comment' key={index}>
                        <div id='container-post-comment-top' key={index}>
                          <div id='container-top-left'>
                            <div id='container-post-comment-image-nickname-owner' key={index}>
                              <div id='container-post-comment-profile-image'>
                                {item.user.userMoreInfo?.avatarUrl ? (
                                  <img src={item.user.userMoreInfo?.avatarUrl}/> 
                                ) : (
                                  <img src={DefaultProfileImage}/>
                                )}
                              </div>
                              <h4>{item.user.nickname}</h4>
                            </div>
                            <div id='container-post-comment-created-at'>
                                <h6>{handleFormatDate(item.createdAt)}</h6>
                            </div>
                            
                          </div>
                          {
                            item.user.id === currentUser?.id ?
    
                              <div id='container-button-delete-post-comment'>
                                <button onClick={() => handleDeleteComment(`${item.id}`,`${item.postId}`)}>
                                  <img src={IconDelete}/>
                                </button>
                              </div>
                            :
    
                            null
                          
                          }
                        </div>
                      </li>
          
                      <div id='container-post-comment-middle'>
                        <pre>{item.body}</pre>
                      </div>
    
                      <div id='container-post-comment-bottom'>
    
                      </div>
                    </div>    
                  ))}
                </div>
              }
              
            </ul>
              {loadingMorePostComments && (
                <div id='container-loading-spinner'>
                    <CircularProgress color='secondary' sx={{ color: 'brown' }}/>
                </div>
              )}
          </div>
        </>
      );          
}