import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUserFromStorage } from './sessionSlice';
import { RootState, AppDispatch } from '../../store';
import { Outlet } from 'react-router-dom';
import LoadingAnimation from '../../components/modal/LoadingAnimation';
import LogoRed from '../../assets/logo-red.png';
import './persistLogin.css';

function PersistLogin() {
  const loading = useSelector((state: RootState) => state.session.loading);
  const userValidated = useSelector((state: RootState) => state.session.loggedIn);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!userValidated) {
      dispatch(authenticateUserFromStorage());
    }
  }, [userValidated, dispatch]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default PersistLogin;
