import React from 'react';
import { useCallback, useState } from "react"
import { WaveSurferPlayer } from "./WaveSurferPlayer";

export function ContentPlayer({fileUrl}: any){
    return (
      <>
        <WaveSurferPlayer
          url={fileUrl}
        />
      </>
    )
  }