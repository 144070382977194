import React from 'react';
import UsersSearch from '../users/UsersSearch';
import { GroupsSearch } from '../groups/GroupsSearch';
import { ProjectsSearch } from '../projects/ProjectsSearch';

interface SearchList {
    searchType: string | undefined;
    query: string | null;
  }

export default function SearchList({ searchType, query }: SearchList){
    return(
        <>
            <div>
              {(searchType) == 'users' && 
                <UsersSearch searchType={searchType} query={query}/>
              }
              
              {(searchType) == 'groups' && 
                <GroupsSearch searchType={searchType} query={query}/>
              }

              {(searchType) == 'projects'  && 
                <ProjectsSearch searchType={searchType} query={query}/>
              }
            </div>
        </>
    )
}