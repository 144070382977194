import React from 'react';
import { useCallback, useEffect, useRef, useState } from "react";
import { useWavesurfer } from "./useWavesurfer";
import { Duration } from "./Duration";
import playIcon from '../../../../assets/play-icon.png';
import pauseIcon from '../../../../assets/pause-icon.png';
import './waveSurferPlayer.css';

export const WaveSurferPlayer = (props: any) => {
    const containerRef = useRef<any>()
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const wavesurfer = useWavesurfer(containerRef, props)
    const [waveformWidth, setWaveformWidth] = useState(duration * 100);
  
    // On play button click
    const onPlayClick = useCallback(() => {
      wavesurfer?.isPlaying() ? wavesurfer?.pause() : wavesurfer?.play()
    }, [wavesurfer])
  
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    useEffect(() => {
      containerRef.current.style.width = "100%";
      containerRef.current.style.height = "10%";
      if (!wavesurfer) return
  
      setCurrentTime(0)
      setIsPlaying(false)

      setDuration(wavesurfer.getDuration());
      setWaveformWidth(duration * 100)
      const subscriptions = [
        wavesurfer.on('play', () => setIsPlaying(true)),
        wavesurfer.on('pause', () => setIsPlaying(false)),
        wavesurfer.on('timeupdate', (currentTime: any) => {
          const currentTimeCentiseconds = Math.round(currentTime * 100);
          setCurrentTime(currentTimeCentiseconds)})
      ]

      wavesurfer.on('ready', () => {
        setDuration(Math.round(wavesurfer.getDuration() * 100));
      });
  
      return () => {
        subscriptions.forEach((unsub) => unsub())
      }
    }, [wavesurfer])
  
    return (
      <>
        <div className="container-wave-surfer">
            {isPlaying ? <img className="play-pause-button" onClick={onPlayClick} src={pauseIcon}/> 
                        : <img className="play-pause-button" onClick={onPlayClick} src={playIcon}/>}
    
            <div className="container-time-progress">
                <div className="container-time-from-start">
                    <Duration centiseconds={currentTime} />
                </div>
                <div className="container-time-to-end">
                    -<Duration centiseconds={duration - currentTime} />
                </div>
            </div>
            <div className="container-wave-top-project">
              <div className="wave-surfer-project" ref={containerRef}/>
            </div>

        </div>
  
      </>
    )
  }