import React, { useEffect } from 'react';
import './addRemoveUserMusicalGenres.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { patchUserMusicalGenre, getMusicalGenresAvailable, getUserMusicalGenres, addRemoveUserMusicalGenre } from '../../../../features/sessions/sessionProfileNavigation';
import { UserMusicalGenre } from '../../../../#interfaces/interfaces';

export default function AddRemoveUserMusicalGenres({userNickname,onClose}:any){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const userMusicalGenres = useSelector((state: RootState) => state.sessionProfile.userMusicalGenres);

    
    useEffect(() => {

        async function fetchMusicalGenresAvailable() {
            if (authHeaders){
                const response = await dispatch(getMusicalGenresAvailable({authHeaders: authHeaders}))
            }
        }


        function fetchUserRouteMusicalGenres(){
            if (authHeaders && userNickname){
                const response = dispatch(getUserMusicalGenres({headers: authHeaders, 
                                                                urlParams: {
                                                                    userNickname: userNickname}
                                                                }
                                                              )
                                         )
            }
        }

        // async function fetchUserInstruments() {
        //     if (authHeaders){
        //         const response = await dispatch(getUserInstruments({
        //                                                             headers: authHeaders,
        //                                                             urlParams:{
        //                                                                 userNickname: userNickname
        //                                                             }
        //                                                            }
        //                                                           )
        //                                                         )
        //                                                     }
                                                                
        //                                                 }
        
        // fetchUserInstruments();
        fetchMusicalGenresAvailable();

    },[])

    async function handleUpdateUserMusicGenre(musicalGenreId: number, selected: boolean)
    {

        if (authHeaders && userNickname)
        {
            await dispatch(addRemoveUserMusicalGenre({userMusicalGenreId: musicalGenreId,
                                                      selected: selected
                                                    }))

            const response = await dispatch(patchUserMusicalGenre(
                                                                 {headers: authHeaders,
                                                                  urlParams: {
                                                                    userNickname: userNickname,
                                                                    userMusicalGenreId: musicalGenreId
                                                                  },
                                                                  requestBodyData: {
                                                                    selected: selected
                                                                  }
                                                                 }
                                                                )
                                            )
        }
    }


    return(
            <>
                <div id='container-add-remove-user-musical-genres-background-modal'>
                    <div id='container-screen-transparent-add-remove-user-musical-genres-modal'>
                        <div id='container-add-remove-user-musical-genres-modal'>
                            <div id='container-top-add-remove-user-musical-genres-modal'>
                                <h2>Genres Preferences</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-add-remove-user-musical-genres-modal'>
                                <ul>
                                    {userMusicalGenres && userMusicalGenres.map((userMusicalGenre: UserMusicalGenre, index: number) => {
                                        const isSelected = userMusicalGenre.selected === true;
                                        return (
                                        <React.Fragment key={index}>
                                            {isSelected ? (
                                            <li id='li-user-music-genres-already-selected' 
                                                key={`${index}-selected`} 
                                                onClick={() => {
                                                        handleUpdateUserMusicGenre(userMusicalGenre.id, false);
                                                }}>
                                                <h3>
                                                    {userMusicalGenre.musicGenre.description}
                                                </h3>
                                            </li>
                                            ) : (
                                            <li id='li-music-genres-available' 
                                                key={`${index}-available`} 
                                                onClick={() => handleUpdateUserMusicGenre(userMusicalGenre.id, true)}>
                                                <h3>
                                                    {userMusicalGenre.musicGenre.description}
                                                </h3>
                                            </li>
                                            )}
                                        </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}