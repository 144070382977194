import React from 'react';
import './userInstrumentsModal.css';
import { FiX } from 'react-icons/fi';

export default function UserInstrumentsModal({userNickname,onClose}:any){
    const instruments = ["guitar", "classical guitar", "sax","drums", "kalimba", "bass", 
                         "crystal glass", "percussion", "clarinet", "piano", "keyboards"]
    return(
            <>
                <div id='container-user-instruments-background-modal'>
                    <div id='container-screen-transparent-instruments-genres-modal'>
                        <div id='container-user-instruments-modal'>
                            <div id='container-top-user-instruments-modal'>
                                <h2>Instruments</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-user-instruments-modal'>
                                <ul>
                                    {instruments && instruments.map((item: any, index :any) => (
                                            <li key={index} title="Not allowed yet">
                                                <h3>{item}</h3>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}