import React from 'react';
import './userMusicalGenresModal.css';
import { FiX } from 'react-icons/fi';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

export default function UserMusicalGenresModal({userNickname,onClose}:any){
    const userMusicgenres = useSelector((state: RootState) => state.sessionProfile.userMusicalGenres);
    
    return(
            <>
                <div id='container-user-musical-genres-background-modal'>
                    <div id='container-screen-transparent-user-musical-genres-modal'>
                        <div id='container-user-musical-genres-modal'>
                            <div id='container-top-user-musical-genres-modal'>
                                <h2>Musical Genres</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-user-musical-genres-modal'>
                                <ul>
                                    {userMusicgenres && userMusicgenres.map((item: any, index :any) => (
                                            <li key={index} title="Not allowed yet">
                                                <h3>{item.musicGenre.description}</h3>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}