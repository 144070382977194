import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Post, createCommentInPost, deletePost, getGroupPosts } from "../../../../app/features/sessions/sessionPostProject/sessionPostProject";
import './postscomponent.css';
import { CircularProgress } from "@mui/material";
import MoreDetailsIcon from '../../../../../src/app/assets/more-details-white.png';
import ProjectDetailsModal from "../../../../app/components/modal/projectModal/ProjectDetails";
import { ProjectPlayer } from "../../../../app/components/audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer";
import { format } from "date-fns";
import LikeIconBlack from '../../../../../src/app/assets/like-icon-black.png';
import LikeIconGray from '../../../../../src/app/assets/like-icon-gray.png';
import CommentIconGray from '../../../../../src/app/assets/comment-icon-gray.png';
import React from "react";
import CommentsInPost from "../../../../app/components/comments/CommentsInPost";
import CreateCommentInPostForm from "../../../../app/components/forms/post/comment/CreateCommentInPostForm";
import DeleteIconWhite from "../../../../../src/app/assets/icon-delete-30-30-white.png";
import LoadingModal from "../../../../app/components/modal/loadingModal/LoadingModal";

export default function PostsComponent(){
    const groupPosts = useSelector((state: RootState) => state.sessionProjectPosts.groupPosts);
    const newPosts = useSelector((state: RootState) => state.sessionProjectPosts.newPosts);
    const [postsPage, setPostsPage] = useState(1);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const loadingDeleteComment = useSelector((state: RootState) => state.sessionProjectPosts.loadingDeletePost);
    const dispatch = useDispatch<AppDispatch>();
    const [projectSelected, setProjectSelected] = useState();
    const { userNicknameRoute, groupId } = useParams();
    const [loadingMorePostsPages,setLoadingMorePostsPages] = useState(false);
    const [showProjectDetails, setShowProjectDetails ] = useState(false);
    const [buttomCommentClicked, setButtomCommentClicked] = useState(false);
    const [postToCommentSelected, setPostToCommentSelected] = useState('');
    const loadingMorePosts = useSelector((state: RootState) => state.sessionProjectPosts.loadingMorePosts);
    const loadingCreatePost = useSelector((state: RootState) => state.sessionProjectPosts.loadingCreatePost);
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);

    useEffect(() => {
        async function getPosts() {
            if (authHeaders && userNicknameRoute && groupId) {
                const response = await dispatch(getGroupPosts({authHeaders: authHeaders, 
                                              getGroupPostsRouteParams: {
                                                                    userNickname: userNicknameRoute,
                                                                    groupId: groupId,
                                                                    page: `${postsPage}`
                                                                    }
                                        }));
            }
        }
        
        getPosts();

        if (groupPosts.length == 0) {
            getPosts();
        }

    },[postsPage])


    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('container-ul-group-posts');
            if (!container) return;
    
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight && !loadingMorePostsPages) {
                setPostsPage(postsPage + 1);
            }
        };
    
        setTimeout(() => {
            const container = document.getElementById('container-ul-group-posts');
            if (container){
                container.addEventListener('scroll', handleScroll);
                return () => container.removeEventListener('scroll', handleScroll);
            }
        }, 100);
    }, [loadingMorePosts,loadingCreatePost]);
    
    function toggleDescriptionModal(projectSelected: any){
        setShowProjectDetails(!showProjectDetails);
        setProjectSelected(projectSelected);
    }

    function handleFormatDate(date: any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    function openCommentContainer(postId: string){

        if (postId != postToCommentSelected){
            setPostToCommentSelected(postId)
            setButtomCommentClicked(true)
        } else {
            setPostToCommentSelected('')
            setButtomCommentClicked(false)
        }
    }

    async function handleDeletePost(postId: string, groupId: string){

        if (authHeaders && currentUser?.nickname){
            const response = dispatch(deletePost({
                                         authHeaders: authHeaders,
                                         routeParams:{
                                            userNickname: currentUser.nickname, 
                                            postId: postId,
                                            groupId: groupId
                                         }
                                        }))

        }
    }


    return (
        <>
        {loadingDeleteComment === true || loadingCreatePost === true ?

            <LoadingModal/>
            
            :
        
            <div id='container-ul-group-posts'>
                <ul id='ul-group-posts'>

                    {newPosts.map((item, index) => ( 
                        <div id='container-li-group-posts' key={index}>
                            <li id='li-post' key={index} >
                                <div id='container-post-top'> 
                                    <div id='container-title-description'>
                                        <div id='container-user-info-date'>
                                            <div id='container-user-img-nickname'>
                                                <div id='container-image'>
                                                    <img src={item.user?.userMoreInfo?.avatarUrl}/>
                                                </div>

                                                <h3>{item.user?.userMoreInfo?.nickname}</h3>
                                            </div>
                                            <div id='container-date'>
                                                <h6>{handleFormatDate(item.createdAt)}</h6>
                                            </div>
                                        </div>
                                         
                                        <div id="container-delete-post">
                                            <button onClick={() => handleDeletePost(`${item.id}`,`${item.groupId}`)}>
                                                <img src={DeleteIconWhite}/>
                                            </button>
                                        </div>
                                    </div>

                                    <div id='container-post-top-pre'>
                                        <pre>{item.body}</pre>
                                    </div>
                                    
                                </div>

                                {item.project?.project != undefined

                                ?

                                    <div id='container-post-middle'>
                                        <div id='container-middle-top'>
                                            <div id='container-project-title'>
                                                <h3>{item.project.project.title}</h3>
                                            </div>
                                        
                                            <div id='container-privacity-project'>
                                                {item.project?.project.privacity === 0 ?
                                                    <i style={{color: "#239400"}}>PUBLIC</i> 
                                                    :
                                                    <i style={{color:"#e70000"} }>PRIVATE</i>
                                                }
                                            </div>
                                        
                                            <div id='container-usability'>
                                                <div id='container-usability-label'>
                                                    <label>Useful:</label>
                                                    <label>{item.project.project.nUsefulContents}</label>
                                                </div>
                                                <div id='container-usability-label'>
                                                    <label>Useless: </label>
                                                    <label>{item.project.project.nUselessContents}</label>
                                                </div>
                                            </div>
                                            
                                            <div id='project-actions'>
                                                <button id='button-more-details' onClick={() => {toggleDescriptionModal(`${item.id}`)}}>
                                                    <img id='more-details-icon' src={MoreDetailsIcon}/>
                                                </button>
                                            </div>
                                        </div>
                                        <div id='container-middle-middle'>
                                            {
                                                item.project.masterFiles && item.project.masterFiles.length > 0 ? 
                                                    <div id='container-project-player-projects'>
                                                        <div id="container-player-projects-track-title">
                                                            <h5>{handleFormatDate(item.project.project.updatedAt)}</h5>
                                                        </div>
                                                        <MemorizedProjectPlayer
                                                                fileUrl={`${item.project.masterFiles[0].signedUrl}`}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div id='container-middle-bottom'>
                                            {/* <button id='button-post-like'>
                                                <img src={LikeIconGray}/>
                                                <h3>Like</h3>
                                            </button> */}
                                            <button id='button-post-open-comments' onClick={() => openCommentContainer(`${item.id}`)}>
                                                <img src={CommentIconGray}/>
                                                <h3>Comment</h3>
                                            </button>
                                        </div>
                                    </div>

                                    :

                                    null

                                    }
                                    
                                    <div id='container-post-comments-component'>
                                        {buttomCommentClicked && postToCommentSelected === `${item.id}` && item.id != undefined
                                            
                                            ? 
                                            
                                            <div id='container-post-comments'>
                                                <CommentsInPost groupId={`${item.groupId}`} postId={`${item.id}`}/>
                                                
                                                <CreateCommentInPostForm postId={`${item.id}`}/>
                                            </div>


                                            :
                                            
                                            null
                                        }
                                    </div>
                            </li>
                            
                        </div>
                    ))}

                    {groupPosts.length > 0 && groupPosts.map((item, index) => ( 
                        <div id='container-li-group-posts' key={index}>
                            <li id='li-post' key={index} >
                                <div id='container-post-top'> 
                                    <div id='container-title-description'>
                                        <div id='container-user-info-date'>
                                            <div id='container-user-img-nickname'>
                                                <div id='container-image'>
                                                    <img src={item.user?.userMoreInfo?.avatarUrl}/>
                                                </div>

                                                <h3>{item.user?.userMoreInfo?.nickname}</h3>
                                            </div>
                                            <div id='container-date'>
                                                <h6>{handleFormatDate(item.createdAt)}</h6>
                                            </div>
                                        </div>

                                        <div id="container-delete-post">
                                            <button onClick={() => handleDeletePost(`${item.id}`,`${item.groupId}`)}>
                                                <img src={DeleteIconWhite}/>
                                            </button>
                                        </div>
                                    </div>

                                    <div id='container-post-top-pre'>
                                        <pre>{item.body}</pre>
                                    </div>
                                </div>

                                {item.project?.project != undefined

                                ?

                                    <div id='container-post-middle'>
                                        <div id='container-middle-top'>
                                            <div id='container-project-title'>
                                                <h3>{item.project.project.title}</h3>
                                            </div>
                                        
                                            <div id='container-privacity-project'>
                                                {item.project?.project.privacity === 0 ?
                                                    <i style={{color: "#239400"}}>PUBLIC</i> 
                                                    :
                                                    <i style={{color:"#e70000"} }>PRIVATE</i>
                                                }
                                            </div>
                                        
                                            <div id='container-usability'>
                                                <div id='container-usability-label'>
                                                    <label>Useful:</label>
                                                    <label>{item.project.project.nUsefulContents}</label>
                                                </div>
                                                <div id='container-usability-label'>
                                                    <label>Useless: </label>
                                                    <label>{item.project.project.nUselessContents}</label>
                                                </div>
                                            </div>
                                            
                                            <div id='project-actions'>
                                                <button id='button-more-details' onClick={() => {toggleDescriptionModal(item.project)}}>
                                                    <img id='more-details-icon' src={MoreDetailsIcon}/>
                                                </button>
                                            </div>
                                        </div>
                                        <div id='container-middle-middle'>
                                            {
                                                item.project.masterFiles && item.project.masterFiles.length > 0 ? 
                                                    <div id='container-project-player-projects'>
                                                        <div id="container-player-projects-track-title">
                                                            <h5>{handleFormatDate(item.project.project.updatedAt)}</h5>
                                                        </div>
                                                        <MemorizedProjectPlayer
                                                                fileUrl={`${item.project.masterFiles[0].signedUrl}`}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div id='container-middle-bottom'>
                                            <button id='button-post-like'>
                                                <img src={LikeIconGray}/>
                                                <h3>Like</h3>
                                            </button>
                                            <button id='button-post-open-comments' onClick={() => openCommentContainer(`${item.id}`)}>
                                                <img src={CommentIconGray}/>
                                                <h3>Comment</h3>
                                            </button>
                                        </div>
                                    </div>

                                    :

                                    null

                                    }
                                    
                                    <div id='container-post-comments-component'>
                                        {buttomCommentClicked && postToCommentSelected === `${item.id}` && item.id != undefined
                                            
                                            ? 
                                            
                                            <div id='container-post-comments'>
                                                <CommentsInPost groupId={`${item.groupId}`} postId={`${item.id}`}/>
                                                
                                                <CreateCommentInPostForm postId={`${item.id}`}/>
                                            </div>

                                            :
                                            
                                            null
                                        }
                                    </div>
                            </li>
                            
                        </div>
                    ))}
                </ul>

                { loadingMorePosts
                                            
                ?

                <div id='container-loading-spineer'>
                    <CircularProgress color='secondary' sx={{ color: 'brown' }}/>
                </div>

                :
                
                null

                }

                <div>
                    { showProjectDetails && (
                        <ProjectDetailsModal
                            projectInfoAndUrls={projectSelected}
                            close={ () => setShowProjectDetails(!showProjectDetails) }
                        />
                        )}
                </div>   
            </div>
        }
        </>
    )
}