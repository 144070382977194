import { useEffect } from 'react';
import NotificationsIcon from '../../assets/bell-icon-24-white.png';
import './notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotifications } from '../sessions/sessionNotification';
import { AppDispatch, RootState } from '../../store';

export default function Notifications(){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(()=>{
        async function fetchNotificationsData(){

            if (authHeaders && currentUser) {

                const response = dispatch(getUserNotifications({
                    authHeaders: authHeaders}))
            }
        }

        fetchNotificationsData();
      },[])

      
    return(
        <div id='container-notifications'>
            <img src={NotificationsIcon}/>
        </div>
    )
}