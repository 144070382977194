import { PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { deleteUserContentRequest, getContents, getInstrumentsAvailable, patchContentEdit, postContent } from '../../../api/sessionAPI';
import { AudioContent, AuthHeaders, Content, Instrument, User } from "../../../#interfaces/interfaces";


export interface PlayingContent {
    content: AudioContent;
    playing: boolean;
}

export interface GetContentsData{
    authHeaders: AuthHeaders;
    routeParams: GetContentsRouteParams;
}

export interface GetContentsRouteParams{
  userNickname?: string; 
  contentsPage: number;
}

export interface FormUpdateContentData{
    title?: string;
    description?: string;
    typeId?: number;
    file?: File ;
    downloadAbled?: string;
}

export interface PostContentData {
    requestBody?: FormUpdateContentData;
    authHeaders?: AuthHeaders;
    currentUser?: User; 
}

interface PatchContenRequestParams{
  authHeaders: AuthHeaders;
  urlParams: PatchContentUrlParams;
  body: PatchContentBody;
}

export interface PatchContentUrlParams{
  contentId?: number;
  userNickname?: string;
}

export interface PatchContentBody{
  title?: string, 
  description?: string,
  downloadAbled?: boolean,
  instrumentId?: number
}

interface DeleteContenRequestParams{
  authHeaders: AuthHeaders;
  urlParams: DeleteContentUrlParams;
}

export interface DeleteContentUrlParams{
  contentId?: number;
  userNickname?: string;
}

interface MyContentStates {
    playingContent: PlayingContent;
    userContents: Content[];
    newMyContents: Content[];
    loading: boolean;
    instrumentsForm: Instrument[];
    loadingMoreContents: boolean;
    loadingCreateContent: boolean;
    loadingUpdate: boolean;
    loadingDelete: boolean;
    errors: string[];
}

const initialState: MyContentStates = {
    playingContent: {
        content: {
            id: undefined,
            title: undefined,
            description: undefined,
            typeId: undefined,
            userId: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            fileUrl: undefined,
            instrument: undefined
        },
        playing: false
    },
    loadingUpdate: false,
    loadingDelete: false,
    loadingMoreContents: false,
    loadingCreateContent: false,
    userContents:[],
    newMyContents: [],
    loading: false,
    instrumentsForm: [],
    errors: []
}

export const playAudio = createAsyncThunk(
    'sessionUserContents/playAudio',
    async (payload: AudioContent, { rejectWithValue }) => {
        const content = payload;

        // if (content.playing === false){
        //     return rejectWithValue(content.playing)
        // }else if (content.playing === true){
        //     return content
        // }
    }
)

export const getInstruments = createAsyncThunk(
  'sessionUserContents/getInstruments',
  async (_, { rejectWithValue }) => {
      const response = await getInstrumentsAvailable()

      if (response.status >= 200 && response.status<300){
        return response.data
      } else {
        return rejectWithValue(response)
      }
  }
)

export const getUserContents = createAsyncThunk(
    'sessionUserContents/getUserContents',
    async (payload: GetContentsData, { rejectWithValue }) => {
        const response = await getContents(
            payload.authHeaders,
            payload.routeParams
        )
        if (response.status >= 200 && response.status<300){
          return response.data
        } else {
          return rejectWithValue(response)
        }
    }
)

export const uploadContent = createAsyncThunk(
    'sessionUserContents/uploadContent',
    async (payload: PostContentData, { rejectWithValue }) => {
        // const file = fileInput.files[0];

        const formBodySnake = {
            authHeaders: convertKeysToSnakeCase(payload.authHeaders),
            currentUser: convertKeysToSnakeCase(payload.currentUser),
            requestBody: convertKeysToSnakeCase(payload.requestBody)
        }

        const response = await postContent(formBodySnake.authHeaders,formBodySnake.currentUser,formBodySnake.requestBody);

        if (response.status>=200 && response.status<300){
            return response.data
        } 
        else {
            return rejectWithValue(response.data)
        }
    }
)

export const patchContent = createAsyncThunk(
  'sessionUserContents/patchContent',
  async (payload: PatchContenRequestParams, { rejectWithValue }) => {

      const response = await patchContentEdit(
        payload.authHeaders,
        payload.urlParams,
        payload.body);

      if (response.status>=200 && response.status<300){
          return response.data
      } 
      else {
          return rejectWithValue(response.data)
      }
  }
)

export const deleteUserContent = createAsyncThunk(
  'sessionUserContents/deleteUserContenContent',
  async (payload: DeleteContenRequestParams, { rejectWithValue }) => {

      const response = await deleteUserContentRequest(
        payload.authHeaders,
        payload.urlParams);

      if (response.status>=200 && response.status<300){
          return response.data
      } 
      else {
          return rejectWithValue(response.data)
      }
  }
)

export const sliceMyAudioContents = createSlice({
    name: 'sessionUserContents',
    initialState,
    reducers: {
        playContent: (state, action: PayloadAction<any>) => {
            state.playingContent.content.id = action.payload.id
            state.playingContent.playing = true
            state.loading = false
        },
        pauseContent: (state, action: PayloadAction<any>) => {
            state.playingContent.content.id = action.payload.id
            state.playingContent.playing = false
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(playAudio.pending, (state) => {
            state.loading = true;
        })
        .addCase(playAudio.fulfilled, (state, action: any) => {
            state.playingContent.content.id = action.meta.arg.id;
            state.playingContent.playing = action.meta.arg.playing;
            state.loading = false;
        })
        .addCase(uploadContent.pending, (state) => {
            state.errors=[];
            state.loadingCreateContent = true;
        })
        .addCase(uploadContent.fulfilled, (state, action: any) => {
            const newMyContentConverted = convertKeysToCamelCaseAndSortByUpdatedAt(action.payload);
            state.userContents = [newMyContentConverted,...state.userContents];
            state.loadingCreateContent = false;
            state.errors = [];
        })
        .addCase(uploadContent.rejected, (state) => {
          state.loadingCreateContent = false;
        })
        .addCase(getUserContents.pending, (state, action: any) => {

            if (action.meta.arg.routeParams.contentsPage === 1){
              state.userContents = [];
              state.newMyContents = [];
            }
            state.loadingMoreContents = true;
            state.loading = true;
        })
        .addCase(getUserContents.fulfilled, (state, action: any) => {
            state.userContents = [...state.userContents,...convertKeysToCamelCaseAndSortByUpdatedAt(action.payload)];
            state.loadingMoreContents = false;
            state.loading = false;
        })
        .addCase(getUserContents.rejected, (state, action: any) => {
            state.errors = action.payload.errors;
            state.loadingMoreContents = false;
            state.loading = false;
        })
        .addCase(getInstruments.pending, (state) => {
            state.instrumentsForm = [];
        })
        .addCase(getInstruments.fulfilled, (state, action: any) => {
            state.instrumentsForm = convertKeysToCamelCaseAndSortByName(action.payload);
        })
        .addCase(getInstruments.rejected, (state, action: any) => {
            state.instrumentsForm = [];
        })
        .addCase(patchContent.pending, (state) => {
            state.loadingUpdate = true;
        })
        .addCase(patchContent.fulfilled, (state, action: any) => {

          const actionCamelCase = convertKeysToCamelCase(action.payload);
          const index = state.userContents.findIndex(content => content.id === action.payload.id);

          if (state.newMyContents.length > 0){
            state.userContents = [...state.newMyContents,...state.userContents ]
          }

          if (index !== -1) {
            state.userContents[index].title = actionCamelCase.title;
            state.userContents[index].description = actionCamelCase.description;
            state.userContents[index].downloadAbled = actionCamelCase.downloadAbled;
            state.userContents[index].instrument = actionCamelCase.instrument;
          }

          state.loadingUpdate = false;
        })
        .addCase(patchContent.rejected, (state) => {
          state.loadingUpdate = false;
        })
        .addCase(deleteUserContent.pending, (state) => {
          state.loadingDelete = true;
        })
        .addCase(deleteUserContent.fulfilled, (state, action: any) => {
          state.userContents = state.userContents.filter(content => content.id !== action.meta.arg.urlParams.contentId);
          state.loadingDelete = false;
        })
        .addCase(deleteUserContent.rejected, (state) => {
          state.loadingDelete = false;
        })
    }
});

export const userContentsReducer = sliceMyAudioContents.reducer;
export const {playContent,pauseContent} = sliceMyAudioContents.actions;

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
    function convertKeysToCamelCase(inputObj: any): any {
      if (typeof inputObj === 'object' && inputObj !== null) {
        if (Array.isArray(inputObj)) {
          return inputObj.map(item => convertKeysToCamelCase(item));
        } else {
          if (inputObj.constructor === Object) {
            const newObj: { [key: string]: any } = {};
            for (const key in inputObj) {
              if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
                const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                  letter.toUpperCase()
                );
                newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
              }
            }
            return newObj;
          }
        }
      }
      return inputObj;
    }
  
    const camelCasedObj = convertKeysToCamelCase(obj);
  
    if (Array.isArray(camelCasedObj)) {
      return camelCasedObj.sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB.getTime() - dateA.getTime();
      });
    } else {
      return camelCasedObj;
    }
  }
  

function convertKeysToSnakeCase(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToSnakeCase(item));
      } else {
        if (obj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const snakeCaseKey = key === 'accessToken' ? key : key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
              newObj[snakeCaseKey] = obj[key];
            }
          }
          return newObj;
        }
      }
    }
    return obj;
  }

export function convertKeysToCamelCaseAndSortByName(objList: any[]): any[] {
    function convertKeysToCamelCase(inputObj: any): any {
      if (typeof inputObj === 'object' && inputObj !== null) {
        if (Array.isArray(inputObj)) {
          return inputObj.map(item => convertKeysToCamelCase(item));
        } else {
          if (inputObj.constructor === Object) {
            const newObj: { [key: string]: any } = {};
            for (const key in inputObj) {
              if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
                const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                  letter.toUpperCase()
                );
                newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
              }
            }
            return newObj;
          }
        }
      }
      return inputObj;
    }
  
    return objList
      .map(convertKeysToCamelCase)
      .sort((a, b) => a.name.localeCompare(b.name));
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}