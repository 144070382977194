import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import './selectProjectModal.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { getUserProjects } from '../../../features/sessions/projectsManagement/sessionManageProjects';
import { ProjectPlayer } from '../../audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer';
import { format } from 'date-fns';
import { SelectProjectPost } from './SelectProjectPost';
import LoadingModalScreenContainer from '../loadingModalScreenContainer/LoadingModalScreenContainer';
import LoadingModal from '../loadingModal/LoadingModal';
import React from 'react';

interface ProjectSelectModalProps {
    onOpenModal: Dispatch<SetStateAction<boolean>>;
    setProjectSelectedId: Dispatch<SetStateAction<any>>;
}

export function SelectProjectModal({ onOpenModal, setProjectSelectedId }: ProjectSelectModalProps){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const loadingProjectsModal = useSelector((state: RootState) => state.sessionManageProjects.loading);
    const allUserProjects = useSelector((state: RootState) => state.sessionManageProjects.allProjects);
    const projectSelectedId = useSelector((state: RootState) => state.sessionProjectPosts.projectSelectedId);
    const dispatch = useDispatch<AppDispatch>();
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);
    const [projectsPage, setProjectsPage] = useState<number>(1);
    
    useEffect(()=>{
        function getMyProjects(){
            if (authHeaders && currentUser?.nickname) {
                const response = dispatch(getUserProjects({authHeaders: authHeaders, 
                                                           urlParams:{
                                                            userNickname: currentUser.nickname,
                                                            page: projectsPage
                                                            }}))
            }
        }
        getMyProjects();
    },[authHeaders,currentUser])

    function handleFormatDate(date:any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    return(
        <>
       
        { loadingProjectsModal ?
            <LoadingModal/>

            :
            
            <div id='container-select-project-modal'>
                <div id='container-select-project'>
                    <>
                        <div id='container-h2-modal-project-selection-project-title'>
                            <h2 id='h2-modal-project-selection-project-title'>
                                Select Project
                            </h2>
                        </div>
                                <button id='close-project-selection-modal' onClick={() => onOpenModal(false)}>
                                        <FiX size={25} color="#FFF"/>
                                </button>
                            <div>
                            <ul id='ul-list-projects-selection'>
                                {allUserProjects.map((item, index) => ( 
                                    <div id='container-li-projects-selection' key={index}>
                                        <li id='li-project-selection-modal-post' key={index}>
                                            <div id='container-select-post'>
                                                <SelectProjectPost projectId={item.project.id}/>
                                            </div>
                                            <div id='container-project-modal-selection'>
                                                <div id='container-title-description'>
                                                    <div id='container-project-top'> 
                                                        <div id='container-project-title'>
                                                            <h3>{item.project.title}</h3>
                                                        </div>
                                                        <div id='container-privacity-project'>
                                                        {item.project.privacity ==  0 ?
                                                            <i style={{color: "#239400"}}>PUBLIC</i> 
                                                            :
                                                            <i style={{color:"#e70000"} }>PRIVATE</i>
                                                        }
                                                        </div>
                                                        <div id='container-usability'>
                                                            <div id='container-usability-label'>
                                                                <label>Useful:</label>
                                                                <label>{item.project.nUsefulContents}</label>
                                                            </div>
                                                            <div id='container-usability-label'>
                                                                <label>Useless: </label>
                                                                <label>{item.project.nUselessContents}</label>
                                                            </div>
                                                        </div>
                                                        {/* <div id='project-actions'>
                                                            <button id='button-more-details' onClick={() => {toggleDescriptionModal(item)}}>
                                                                <img id='more-details-icon' src={MoreDetailsIcon}/>
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                    { item.masterFiles && item.masterFiles.length > 0 ? 
                                                            <div id='container-project-player-projects-selection-post'>
                                                                <div id='container-title-project-modal-select'>
                                                                    {item.masterFiles && item.masterFiles[0] ?
                                                                    
                                                                        <div id="container-player-projects-track-title-modal-select-project">
                                                                            <h5>{handleFormatDate(item.masterFiles[0].createdAt)}</h5>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                {item.masterFiles && item.masterFiles[0]  ?
                                                                    <MemorizedProjectPlayer
                                                                            fileUrl={`${item.masterFiles[0].signedUrl}`}/>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }
                                            </div>
                                        </li>
                                    </div>
                                ))}
                                </ul>
                            </div>
                            <div id='container-button-attach-project'>
                                <button id='button-attach-project' onClick={() => {setProjectSelectedId(projectSelectedId);
                                                                                onOpenModal(false)}}>
                                    Attach Project
                                </button>
                            </div>
                    </>
                </div>
        </div> 
        }</>
    )
}