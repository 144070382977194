import React from 'react';
import { useCallback, useState } from "react"
import { WaveSurferPlayer } from "./WaveSurferPlayer";

export function ProjectPlayer({title, fileUrl}: any){

    // Swap the audio URL
  
    // Render the wavesurfer component
    // and a button to load a different audio file
    return (
      <>
        <WaveSurferPlayer
          url={fileUrl}
        />
      </>
    )
  }