import React, { useEffect, useState } from 'react';
import './search.css';
import SearchList from './searchList/SearchList';
import MenuSearchPage from './MenuSearchPage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as REQUEST_REQUIREMENTS from '../../../app/api/requestRequirements';

export default function Search(){
    const {queryType} = useParams();
    const [searchQueryType, setSearchQueryType] = useState(queryType);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const query = params.get('q');

    useEffect(()=>{
        if (query){
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: {
                queryType: searchQueryType,
                searchInputQuery: query
            }
            });

            navigate(PRIVATE_ROUTES.SEARCH_BAR_NAVIGATION);
        }
    },[searchQueryType])

    useEffect(()=>{
        setSearchQueryType('users');
    },[query])

    return (
        <>
            <div id="container-search-page">
                <div id="container-search-page-left">
                    <div id='container-side-bar'>
                        <MenuSearchPage optionClicked={searchQueryType} onClickOption={setSearchQueryType}/>
                    </div>
                </div>
                <div id="container-search-page-middle">
                    <div id='container-results-search'>
                        <div id='container-ul-search-page-middle'>
                             <SearchList searchType={searchQueryType} query={query}/>
                        </div>
                    </div>
                </div>
                <div id="container-search-page-right">

                </div>
            </div>
        </>
    )
}