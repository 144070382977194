import { useEffect, useState } from 'react';
import './groupsSearch.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../app/store';
import { getSearchResults } from '../../../../app/features/sessions/sessionSearch/sliceSearch';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';

interface GroupsSearch {
    searchType: string;
    query: string | null;
}

export function GroupsSearch({ searchType, query }: GroupsSearch) {
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const [groupsSearchPage, setGroupsSearchPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const groups = useSelector((state: RootState) => state.sessionSearch.groups);
    const loadingMoreResults = useSelector((state: RootState) => state.sessionSearch.loadingMoreResults);

    useEffect(() => {
        async function fetchGroupsData() {
            if (authHeaders && searchType && query && !isFetching) {
                setIsFetching(true);
                const response = await dispatch(getSearchResults({
                    headers: authHeaders,
                    routeParams: {
                        queryType: searchType,
                        query: query,
                        page: groupsSearchPage
                    }
                }));
                setIsFetching(false);
            }
        }

        fetchGroupsData();

    }, [authHeaders, searchType, query, groupsSearchPage, dispatch]);

    useEffect(() => {
        function handleScroll() {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return;
            setGroupsSearchPage(prevPage => prevPage + 1);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching]);

    function handleFormatDate(date: any) {
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate;
    }

    return (
        <>
            <div id='container-groups-search'>
                <ul>
                    {groups.length > 0 && groups.map((item, index) => (
                        <div id='container-li-search-group' key={index}>
                            <li id='li-group-search' key={index}>
                                <div id='container-li-top-group-search'>
                                    <div id='container-li-top-left-group-search'>
                                        <div id='container-group-title'>
                                            <h4>{item.name}</h4>
                                        </div>
                                        <div id='container-group-info'>
                                            <span id='members-span'>Members:</span>
                                            <span>{item.nMembers}</span>
                                        </div>
                                    </div>
                                </div>
                                <div id='container-li-middle'>
                                    <pre>{item.description}</pre>
                                </div>
                                <div id='container-li-bottom'>
                                    <div id='container-genres-group-search'>
                                        <h4>Musical Genres</h4>
                                        <ul>
                                            {(() => {
                                                let extraGenresCount = 0;
                                                return item.groupMusicalGenres && item.groupMusicalGenres.map((groupMusicalGenre: any, groupMusicalGenreIndex: any) => {
                                                    if (groupMusicalGenreIndex < 4) {
                                                        return (
                                                            <li key={groupMusicalGenreIndex}>
                                                                <h6>{groupMusicalGenre.musicGenre.description}</h6>
                                                            </li>
                                                        );
                                                    } else {
                                                        extraGenresCount++;
                                                        if (groupMusicalGenreIndex === item.groupMusicalGenres.length - 1) {
                                                            return (
                                                                <li key={groupMusicalGenreIndex}>
                                                                    <h6>+ {extraGenresCount}</h6>
                                                                </li>
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                });
                                            })()}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </div>
                    ))}
                </ul>
                <div id = 'container-nothing-to-show-groups-search'>
                    { groups.length == 0 && !loadingMoreResults ?
                        <h2>Nothing to show</h2>
                        :
                        null    
                    }
                </div>
                {loadingMoreResults === true &&
                    <div id='container-loading-spineer'>
                        <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                    </div>
                }
            </div>
        </>
    )
}
