import { FiX } from 'react-icons/fi';
import DeleteIconWhite  from '../../../../assets/icon-delete-30-30-white.png';
import './projectContentCommentsModal.css';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectContentComment, createProjectContentComment, deleteProjectContentComment, getProjectContentComments } from '../../../../features/sessions/sessionProjectManagementPlayer/sliceProjectManagementPlayer';
import { CircularProgress } from '@mui/material';
import { AuthHeaders } from '../../../../#interfaces/interfaces';

export function ProjectContentCommentsModal({projectContentShowedId,userNicknameRoute,currentUserNickname,onCloseClick}: any){
    const [inputComment, setInputComment] = useState('');
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const currentProjectContentComments = useSelector((state: RootState) => state
                                                                            .sessionProjectPlayerManagement
                                                                            .currentProjectContentCommentsToShow);
    const dispatch = useDispatch<AppDispatch>();
    const [ projectContentCommentsPage, setProjectContentCommentsPage ] = useState(1);
    const loadingMoreProjectContentsComments =  useSelector((state: RootState) => state
                                                                                  .sessionProjectPlayerManagement
                                                                                  .lodingMoreProjectContensComments);
    const newCurrentProjectComments = useSelector((state: RootState) => state
                                                                        .sessionProjectPlayerManagement
                                                                        .newCurrentProjectContentComments);
    
    useEffect(() =>{

        const fetchData = async () => {
            if (authHeaders && currentUser?.nickname) {
                try {
                    
                    const response = await dispatch(getProjectContentComments({
                                                                                headers: authHeaders,
                                                                                urlParams: {
                                                                                    userNickname: currentUser.nickname,
                                                                                    projectContentId: projectContentShowedId,
                                                                                    projectContentCommentsPage: `${projectContentCommentsPage}`
                                                                                }
                                                                              }
                                                                             )
                                                   );
                } catch (error) {
                    console.error('Erro ao buscar os comentários do conteúdo do projeto:', error);
                }
            }
        };
    
        fetchData();

    },[projectContentCommentsPage])

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('ul-project-content-comments-modal');
            if (!container) return;
    
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight && !loadingMoreProjectContentsComments) {

                setProjectContentCommentsPage(projectContentCommentsPage + 1);
            }
        };
    
        setTimeout(() => {
            const container = document.getElementById('ul-project-content-comments-modal');
            if (container){
                container.addEventListener('scroll', handleScroll);
                return () => container.removeEventListener('scroll', handleScroll);
            }
        }, 100);
    }, [loadingMoreProjectContentsComments]);

    function handleFormatDate(dateString: string | undefined): string | null {
        if (!dateString) return null;
    
        const date = new Date(dateString);
    
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
    
        const locale = navigator.language;
    
        const formattedDate = date.toLocaleString(locale, options);
    
        return formattedDate;
    }

    async function handleDeleteProjectContentComment(projectContentCommentId: string, 
                                                     projectContentId: string,
                                                     authHeaders: AuthHeaders | undefined,
                                                     currentUserNickname: string | undefined) {

        if (authHeaders && currentUserNickname){
        const response = await dispatch(deleteProjectContentComment({headers: authHeaders,
                                                    urlParams:{
                                                        userNickname: currentUserNickname,
                                                        projectContentId: projectContentId, 
                                                        projectContentCommentId: projectContentCommentId  
                                                    }

        }))
    
    }
    }


    async function handleCreateProjectContentComment(userNicknameRoute: string,
                                                     projectContentToComment: string,
                                                     projectContentCommentBody: string){

        if (authHeaders && userNicknameRoute && projectContentToComment){

            const response = await dispatch(createProjectContentComment({headers: authHeaders,
                                                  routeParams: {
                                                        userNickname: userNicknameRoute,
                                                        projectContentId: projectContentToComment
                                                  },
                                                  body: {
                                                    body: projectContentCommentBody
                                                  }

               }
            ))

            if(response.meta.requestStatus == "fulfilled"){
                setInputComment('');
            }
        }

    }

    return(
            <>
                <div id='container-project-content-comments-modal'>
                    
                    <div id='container-screen-transparent-project-content-comments-modal'>
                            <div id='container-project-content-comments'>
                                <div id='container-project-title-project-contents-comments'>
                                    <div id='container-top-project-content-title-button-modal'>
                                        <div id='container-title-project-content'>
                                            <h1>
                                                
                                            </h1>
                                        </div>
                                        <button onClick={() => onCloseClick(false)}>
                                            <FiX size={20} color="#FFF"/>
                                        </button>
                                    </div>
                                    <div id='container-middle-project-content-comments-and-container-text-area-buttom-modal'>
                                        <div id='container-ul-project-content-comments-modal'>
                                            <ul id='ul-project-content-comments-modal'>
                                                {
                                                    currentProjectContentComments.map((item: ProjectContentComment, index: number) => ( 

                                                        <div id='container-li-project-content-comment' key={index}>
                                                            <li key={index}>
                                                                <div id='container-li-top-project-content-comment'>
                                                                    <div id='container-user-comment-project-content-comment-modal'>
                                                                        <div id='container-user-photo-project-content-comment-modal'>
                                                                            <img src={item.user.userMoreInfo?.avatarUrl}/>
                                                                        </div>

                                                                        <div id='container-body-date-project-content-comment-modal'>
                                                                            <div id='container-nickname-body-delete-project-content-comment'>

                                                                                <div id='container-body-project-content-comment'>
                                                                                    <h2>
                                                                                        {item.user.userMoreInfo?.nickname}
                                                                                    </h2>
                                                                                    <h6>{item.body}</h6>
                                                                                </div>

                                                                                <div id='container-delete-project-content-comment' onClick={() => {
                                                                                                                                                    handleDeleteProjectContentComment(
                                                                                                                                                        `${item.id}`,
                                                                                                                                                        `${item.projectContentId}`,
                                                                                                                                                        authHeaders,
                                                                                                                                                        currentUser?.nickname
                                                                                                                                                    )
                                                                                }}>
                                                                                    <button>
                                                                                        <img src={DeleteIconWhite}/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div id='container-body-project-content-comment-modal'>
                                                                                <h6>{handleFormatDate(item.createdAt)}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div id='container-li-middle-project-content-comment'>
                                                                </div>
                                                                <div id='container-li-bottom-project-content-comment'>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))
                                                    
                                                }
                                                {
                                                    newCurrentProjectComments.map((item: ProjectContentComment, index: number) => ( 

                                                        <div id='container-li-project-content-comment' key={index}>
                                                            <li key={index}>
                                                                <div id='container-li-top-project-content-comment'>
                                                                    <div id='container-user-comment-project-content-comment-modal'>
                                                                        <div id='container-user-photo-project-content-comment-modal'>
                                                                            <img src={item.user.userMoreInfo?.avatarUrl}/>
                                                                        </div>

                                                                        <div id='container-body-date-project-content-comment-modal'>
                                                                            <div id='container-nickname-body-delete-project-content-comment'>

                                                                                <div id='container-body-project-content-comment'>
                                                                                    <h2>
                                                                                        {item.user.userMoreInfo?.nickname}
                                                                                    </h2>
                                                                                    <h6>{item.body}</h6>
                                                                                </div>

                                                                                <div id='container-delete-project-content-comment' onClick={() => {
                                                                                                                                                    handleDeleteProjectContentComment(
                                                                                                                                                        `${item.id}`,
                                                                                                                                                        `${item.projectContentId}`,
                                                                                                                                                        authHeaders,
                                                                                                                                                        currentUser?.nickname
                                                                                                                                                    )
                                                                                }}>
                                                                                    <button>
                                                                                        <img src={DeleteIconWhite}/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div id='container-body-project-content-comment-modal'>
                                                                                <h6>{handleFormatDate(item.createdAt)}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div id='container-li-middle-project-content-comment'>
                                                                </div>
                                                                <div id='container-li-bottom-project-content-comment'>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))
                                                    
                                                }
                                                    {loadingMoreProjectContentsComments && (
                                                        <div id='container-loading-spinner'>
                                                            <CircularProgress color='secondary' sx={{ color: 'brown' }}/>
                                                        </div>
                                                )}
                                            </ul>
                                            
                                        </div>
                                    </div>
                                    <div id='container-bottom-project-contentc-comments-modal'>
                                        <div id='container-form-create-project-content-comment-modal'>
                                            <div id='container-text-area-create-project-content-comment-modal'>
                                                <textarea 
                                                    value={inputComment} 
                                                    onChange={(event) => {setInputComment(event.target.value)}} 
                                                />
                                            </div>
                                            <div id='container-button-create-project-content-comment-modal'>
                                                <button onClick={() => {handleCreateProjectContentComment(userNicknameRoute,
                                                                                                          projectContentShowedId,
                                                                                                          inputComment)}}>
                                                    comment
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
    )
}