import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { authenticateUserFromStorage } from "../../../../app/features/sessions/sessionSlice";
import './group.css'
import { getGroup } from "../../../../app/features/sessions/sessionGroups/sessionGroup";
import IconUpArrow from '../../../../app/assets/slide-up-icon-black.png'
import { PostGroupCreate } from "../../../../app/components/forms/post/PostGroupCreate";
import { getGroupPosts } from "../../../../app/features/sessions/sessionPostProject/sessionPostProject";
import PostsComponent from "./PostsComponent";

export function Group(){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const groupName = useSelector((state: RootState) => state.sessionGroup.name);
    const groupDescription = useSelector((state: RootState) => state.sessionGroup.description);
    const groupNmembers = useSelector((state: RootState) => state.sessionGroup.nMembers);
    const groupCreatedAt = useSelector((state: RootState) => state.sessionGroup.createdAt);
    const groupUpdatedAt = useSelector((state: RootState) => state.sessionGroup.updatedAt);
    const currentGroupId = useSelector((state: RootState) => state.sessionGroup.id);
    const groupMusicalGenres = useSelector((state: RootState) => state.sessionGroup.groupMusicalGenres);
    const groupPosts = useSelector((state: RootState) => state.sessionProjectPosts.groupPosts);
    const [loadingMoreGroups,setLoadingMoreGroups] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { userNicknameRoute, groupId } = useParams();

    const retractShowButton = document.getElementById('retract-show-button');
    const arrowIcon = document.getElementById('arrow-icon');
    const preGroupDescription = document.getElementById('pre-group-description');

    if (retractShowButton && arrowIcon && preGroupDescription) {
        retractShowButton.onclick = function() {

            if (arrowIcon.style.transform === 'rotate(0deg)'){
                arrowIcon.style.transform = 'rotate(180deg)';
                preGroupDescription.style.height = '50px';
            } else {
                arrowIcon.style.transform = 'rotate(0deg)';
                preGroupDescription.style.height = '100%';
            }

        };
    }

    useEffect(() => {

        async function authUserOnRoute() {
            if (!userValidated) {
                await dispatch(authenticateUserFromStorage());
            }
        }

        async function getGroupInfo() {
            if (authHeaders && userNicknameRoute && groupId) {
                await dispatch(getGroup({authHeaders: authHeaders, getGroupRouteParams: {
                                                                                         userNickname: userNicknameRoute,
                                                                                         groupId: groupId
                                                                                        }
                                        }));
            }
        }

        authUserOnRoute();
        getGroupInfo();
    }, [userValidated]);

    return(
        <>
            <div id='container-page-group'>
                <>
                    <div id='container-group-left'>

                    </div>

                    <div id='container-group-center'>
                        <div id='container-header-group'>
                            <div id='container-group-info'>
                                <h1 id='h1-group-name'>{groupName}</h1>
                                <div id='container-pre-group-description'>
                                    <pre id='pre-group-description'>
                                        {groupDescription}
                                    </pre>
                                    <button id='retract-show-button'>
                                        <img id='arrow-icon' src={IconUpArrow}/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div id='container-create-post-component'>
                            <PostGroupCreate/>
                        </div>

                        <div id='container-posts-component'>
                            <PostsComponent/>
                        </div>
                    </div>

                    <div id='container-group-right'>
                    </div>
                </>
            </div>
        
        </>
    )
}
