import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../app/store';
import { getUserProjects } from '../../../../app/features/sessions/projectsManagement/sessionManageProjects';
import './manageProjects.css';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import ProjectDetailsModal from '../../../../app/components/modal/projectModal/ProjectDetails';
import LoadingModal from '../../../../app/components/modal/loadingModal/LoadingModal';
import CreateProjectForm from '../../../../app/components/forms/projects/CreateProjectForm';
import { ProjectPlayer } from '../../../../app/components/audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer';
import MoreDetailsIcon from '../../../../../src/app/assets/more-details-white.png';
import { CircularProgress } from '@mui/material';

export default function ManageProjects() {
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const [showProjectDetails, setShowProjectDetails] = useState(false);
    const [projectSelected, setProjectSelected] = useState();
    const loading = useSelector((state: RootState) => state.sessionManageProjects.loading);
    const newPublicProjects = useSelector((state: RootState) => state.sessionManageProjects.newPublicProjects);
    const newPrivateProjects = useSelector((state: RootState) => state.sessionManageProjects.newPrivateProjects);
    const userRoutePublicProjects = useSelector((state: RootState) => state.sessionManageProjects.userRoutePublicProjects);
    const userRoutePrivateProjects = useSelector((state: RootState) => state.sessionManageProjects.userRoutePrivateProjects);
    const allUserRouteProjects = useSelector((state: RootState) => state.sessionManageProjects.allProjects);
    const alienPublicProjects = useSelector((state: RootState) => state.sessionManageProjects.alienPublicProjects);
    const alienPrivateProjects = useSelector((state: RootState) => state.sessionManageProjects.alienPrivateProjects);
    const allAlienProjects = useSelector((state: RootState) => state.sessionManageProjects.allAlienProjects);
    const allPublicProjects = useSelector((state: RootState) => state.sessionManageProjects.allPublicProjects);
    const allPrivateProjects = useSelector((state: RootState) => state.sessionManageProjects.allPrivateProjects);
    const allRouteProjects = useSelector((state: RootState) => state.sessionManageProjects.allProjects);
    const [userRouteProjectsSelected, seUserRouteProjectsSelected] = useState(true);
    const [alienProjectsSelected, setAlienProjectsSelected] = useState(false);
    const [allProjectsSelected, setAllProjectsSelected] = useState(false);
    const [publicSelected, setPublicSelected] = useState(true);
    const [privateSelected, setPrivateSelected] = useState(false);
    const [manageProjectsTitle, setManageProjectsTitle] = useState('My Projects');
    const responseErrors = useSelector((state: RootState) => state.sessionManageProjects.errors);
    const { userNicknameRoute } = useParams<string>();
    const noProjectsCondition = (allUserRouteProjects && allUserRouteProjects.length === 0) ;
    const projectsContainerId = noProjectsCondition ? 'no-projects-container-style' : 'projects-container';
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);
    const [projectsPage, setProjectsPage] = useState<number>(1);
    const loadingMoreProjects = useSelector((state: RootState) => state.sessionManageProjects.loadingMoreProjects);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const loadingCreation = useSelector((state: RootState) => state.sessionManageProjects.loadingCreation);

    useEffect(() => {
        fetchProjects();
    }, [projectsPage,userNicknameRoute]);

    const fetchProjects = async () => {
        if (authHeaders && userNicknameRoute) {
            await dispatch(getUserProjects({
                authHeaders: authHeaders,
                urlParams: {
                    userNickname: userNicknameRoute,
                    page: projectsPage
                }
            }));
        }
    }

    function toggleDescriptionModal(projectSelected: any) {
        setShowProjectDetails(!showProjectDetails);
        setProjectSelected(projectSelected);
    }

    const handleSelectChangeFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setManageProjectsTitle(event.target.options[event.target.selectedIndex].innerText);
        seUserRouteProjectsSelected(event.target.value === 'userRouteProjectsOption');
        setAlienProjectsSelected(event.target.value === 'alienProjectsOption');
        setAllProjectsSelected(event.target.value === 'allProjectsOption');
    };

    const handleSelectChangeFilter2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPublicSelected(event.target.value === 'publicOption' || event.target.value === 'publicPrivateOption');
        setPrivateSelected(event.target.value === 'privateOption' || event.target.value === 'publicPrivateOption');
    };

    function handleFormatDate(date: any) {
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate;
    }

    useEffect(() => {
    
        const handleScroll = () => {
            const container = containerRef.current;
    
            if (!container) {
                return;
            }
    
            const { scrollTop, clientHeight, scrollHeight } = container;
    
            if (scrollTop + clientHeight >= scrollHeight && !loadingMoreProjects) {
                setProjectsPage((prevPage) => {
                    const newPage = prevPage + 1;
                    return newPage;
                });
            }
        };
    
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        } 
    }, [loadingMoreProjects]);

    const filteredProjects = useMemo(() => {


        if (publicSelected == true && privateSelected == false && userRouteProjectsSelected == true) {
            return allUserRouteProjects.filter(project => 
                project.project.user.userMoreInfo &&  
                project.project.user.userMoreInfo.nickname === userNicknameRoute &&
                project.project.privacity === 0
            );
        } else if (publicSelected == false && privateSelected == true && userRouteProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.user.userMoreInfo &&  
                project.project.user.userMoreInfo.nickname === userNicknameRoute &&
                project.project.privacity == 1
            );
        } else if (publicSelected == true && privateSelected == false && alienProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.user.userMoreInfo &&  
                project.project.user.userMoreInfo.nickname != userNicknameRoute &&
                project.project.privacity === 0
            );
        } else if (publicSelected == false && privateSelected == true && alienProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.user.userMoreInfo &&  
                project.project.user.userMoreInfo.nickname != userNicknameRoute &&
                project.project.privacity === 1
            );
        } else if (publicSelected == true && privateSelected == false && allProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.privacity === 0
            );
        } else if (publicSelected == false && privateSelected == true && allProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.privacity === 1
            );
        } else if (publicSelected == true && privateSelected == true && userRouteProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.user.userMoreInfo &&  
                project.project.user.userMoreInfo.nickname === userNicknameRoute
            );
        } else if (publicSelected == true && privateSelected == true && alienProjectsSelected == true){
            return allUserRouteProjects.filter(project => 
                project.project.user.userMoreInfo &&  
                project.project.user.userMoreInfo.nickname != userNicknameRoute
            );
        }

        return allUserRouteProjects;
    }, [publicSelected, userRouteProjectsSelected,userNicknameRoute,allUserRouteProjects]);

    
    return (
        <div id='container-page-projects'>
                    <div id='container-projects-left'></div>
                    <div id='container-center-projects'>
                        <div id='container-form-list-projects'>
                            {currentUser?.nickname === userNicknameRoute && <CreateProjectForm />}
                            <div id="container-manage-projects">
                                <header id='header-manage-projects'>
                                    <div id='container-projects-titles'>
                                        {
                                            userNicknameRoute == currentUser?.nickname ?

                                                <h2 id='projects-titles'>My Projects</h2>
                                            
                                                :

                                                <h2 id='projects-titles'>User Projects</h2>
                                        }
                                    </div>
                                    <div id='container-filters-top'>
                                        <select id="filter-projects" name="filter-projects" onChange={handleSelectChangeFilter}>
                                            <option value="userRouteProjectsOption">User Projects</option>
                                            <option value="alienProjectsOption">Alien Projects</option>
                                            <option value="allProjectsOption">All Projects</option>
                                        </select>
                                        <select id="filter-projects2" name="filter-projects2" onChange={handleSelectChangeFilter2}>
                                            <option value="publicOption">Public</option>
                                            <option value="privateOption">Private</option>
                                            <option value="publicPrivateOption">Public & Private</option>
                                        </select>
                                    </div>
                                </header>
                            <div id='projects-container'>
                                    <div  ref={containerRef} id='container-ul-projects'>
                                        <ul id='ul-list-projects'>
                                            {loadingCreation == true ? (
                                                    <div id='container-loading-spinner'>
                                                        <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                                    </div>
                                                ) : null}

                                            {publicSelected && userRouteProjectsSelected && 
                                             newPublicProjects && newPublicProjects.map((item, index) => ( 
                                                <div id='container-li-projects' key={index}>
                                                <li id='li-project' key={index} >
                                                    <div id='container-title-description'>
                                                    <div id='container-project-top'> 
                                                            <div id='container-project-title'>
                                                                <div id='container-title-privacity'>
                                                                    <h3>{item.project.title}</h3>
                                                                    <div id='container-privacity-project'>
                                                                    {item.project.privacity ==  0 ?
                                                                        <i style={{color: "#239400"}}>PUBLIC</i> 
                                                                        :
                                                                        <i style={{color:"#e70000"} }>PRIVATE</i>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div id='project-actions'>
                                                                    <button id='button-more-details' onClick={() => {toggleDescriptionModal(item)}}>
                                                                        <img id='more-details-icon' src={MoreDetailsIcon}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div id='container-project-another-info'>
                                                                <div id='container-usability'>
                                                                    <div id='container-usability-label'>
                                                                        <label>Useful contents:</label>
                                                                        <label>{item.project.nUsefulContents}</label>
                                                                    </div>
                                                                    <div id='container-usability-label'>
                                                                        <label>Another contents: </label>
                                                                        <label>{item.project.nUselessContents}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {
                                                            item.masterFiles && item.masterFiles.length > 0 ? 
                                                                <div id='container-project-player-projects'>
                                                                    {/* <div id="container-player-projects-track-title">
                                                                        <h5>{handleFormatDate(item.masterFiles[0].createdAt)}</h5>
                                                                    </div> */}
                                                                    <MemorizedProjectPlayer
                                                                            fileUrl={`${item.masterFiles[0].signedUrl}`}/>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                </li>

                                            </div>
                                            ))}
                                            {privateSelected && userRouteProjectsSelected && 
                                             newPrivateProjects && newPrivateProjects.map((item, index) => ( 
                                                <div id='container-li-projects' key={index}>
                                                <li id='li-project' key={index} >
                                                    <div id='container-title-description'>
                                                    <div id='container-project-top'> 
                                                            <div id='container-project-title'>
                                                                <div id='container-title-privacity'>
                                                                    <h3>{item.project.title}</h3>
                                                                    <div id='container-privacity-project'>
                                                                    {item.project.privacity ==  0 ?
                                                                        <i style={{color: "#239400"}}>PUBLIC</i> 
                                                                        :
                                                                        <i style={{color:"#e70000"} }>PRIVATE</i>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div id='project-actions'>
                                                                    <button id='button-more-details' onClick={() => {toggleDescriptionModal(item)}}>
                                                                        <img id='more-details-icon' src={MoreDetailsIcon}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div id='container-project-another-info'>
                                                                <div id='container-usability'>
                                                                    <div id='container-usability-label'>
                                                                        <label>Useful contents:</label>
                                                                        <label>{item.project.nUsefulContents}</label>
                                                                    </div>
                                                                    <div id='container-usability-label'>
                                                                        <label>Another contents: </label>
                                                                        <label>{item.project.nUselessContents}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {
                                                            item.masterFiles && item.masterFiles.length > 0 ? 
                                                                <div id='container-project-player-projects'>
                                                                    {/* <div id="container-player-projects-track-title">
                                                                        <h5>{handleFormatDate(item.masterFiles[0].createdAt)}</h5>
                                                                    </div> */}
                                                                    <MemorizedProjectPlayer
                                                                            fileUrl={`${item.masterFiles[0].signedUrl}`}/>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                </li>

                                            </div>
                                            ))}
                                            {filteredProjects && filteredProjects.map((item, index) => ( 
                                            <div id='container-li-projects' key={index}>
                                                <li id='li-project' key={index} >
                                                    <div id='container-title-description'>
                                                    <div id='container-project-top'> 
                                                            <div id='container-project-title'>
                                                                <div id='container-title-privacity'>
                                                                    <h3>{item.project.title}</h3>
                                                                    <div id='container-privacity-project'>
                                                                    {item.project.privacity ==  0 ?
                                                                        <i style={{color: "#239400"}}>PUBLIC</i> 
                                                                        :
                                                                        <i style={{color:"#e70000"} }>PRIVATE</i>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div id='project-actions'>
                                                                    <button id='button-more-details' onClick={() => {toggleDescriptionModal(item)}}>
                                                                        <img id='more-details-icon' src={MoreDetailsIcon}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div id='container-project-another-info'>
                                                                <div id='container-usability'>
                                                                    <div id='container-usability-label'>
                                                                        <label>Useful contents:</label>
                                                                        <label>{item.project.nUsefulContents}</label>
                                                                    </div>
                                                                    <div id='container-usability-label'>
                                                                        <label>Another contents: </label>
                                                                        <label>{item.project.nUselessContents}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {
                                                            item.masterFiles && item.masterFiles.length > 0 ? 
                                                                <div id='container-project-player-projects'>
                                                                    {/* <div id="container-player-projects-track-title">
                                                                        <h5>{handleFormatDate(item.masterFiles[0].createdAt)}</h5>
                                                                    </div> */}
                                                                    <MemorizedProjectPlayer
                                                                            fileUrl={`${item.masterFiles[0].signedUrl}`}/>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                </li>

                                            </div>
                                            ))}
                                            {loadingMoreProjects ? (
                                                <div id='container-loading-spinner'>
                                                    <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                                </div>
                                            ) : filteredProjects.length === 0 && newPublicProjects.length === 0 && newPrivateProjects.length === 0? (
                                                <div id='container-no-projects'>
                                                    <h3 id='no-projects-yet'>
                                                        You don&apos;t have projects yet
                                                    </h3>
                                                </div>
                                            ) : null}

                                        </ul>
                                                
                                    </div>
                            </div>
                            <div>
                                { showProjectDetails && (
                                    <ProjectDetailsModal
                                        projectInfoAndUrls={projectSelected}
                                        close={ () => setShowProjectDetails(!showProjectDetails) }
                                    />
                                    )}
                            </div>      
                        </div>
                    </div>
                </div>
            <div id='container-projects-right'>

            </div>

    </div>
    )
}