import React from 'react';
import { useEffect, useState } from 'react'
import './generalUserConfigForm.css'
import { UserMoreInfo } from '../../../#interfaces/interfaces';
import DefaultProfileImage  from '../../../assets/profile-default-red.png';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAvatar } from '../../../features/sessions/sessionSlice';
import { updateGeneralConfig } from '../../../features/sessions/sessionConfig/sessionUserConfigurations';
import * as REQUEST_REQUIREMENTS from '../../../api/requestRequirements';
import { toast } from 'react-toastify';
import LoadingModal from '../../modal/loadingModal/LoadingModal';

export const GeneralUserConfigForm: React.FC<{ userMoreInfo: UserMoreInfo }> = ({ userMoreInfo }) => {
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const avatar = useSelector((state: RootState) => state.session.userMoreInfo.avatarUrl);
    const loading = useSelector((state: RootState) => state.sessionUserConfig.loading);
    const [inputNameValue,setInputNameValue] = useState(userMoreInfo.name);
    const [inputLastNameValue,setInputLastNameValue] = useState(userMoreInfo.lastName);
    const [inputNicknameValue,setInputNicknameValue] = useState(userMoreInfo.nickname);
    const [inputPhoneNumberValue,setInputPhoneNumberValue] = useState(userMoreInfo.phoneNumber);
    const [inputWebsiteValue, setInputWebsiteValue] = useState(userMoreInfo.website);
    const [inputCityValue, setInputCityValue] = useState(userMoreInfo.city);
    const [inputStateValue, setInputStateValue] = useState(userMoreInfo.state);
    const [inputCountryValue, setInputCountryValue] = useState(userMoreInfo.country);
    const [inputAboutValue, setInputAboutValue] = useState(userMoreInfo.about);
    const [inputEmailValue, setInputEmailValue] = useState(userMoreInfo.email);
    const [inputAvatar, setInputAvatar] = useState<File | null>();
    const { userNicknameRoute } = useParams<string>();
    const [PRIVATE_ROUTES,setPrivateRoutes]= useState<REQUEST_REQUIREMENTS.EndPoints>()
    const navigate = useNavigate();
    

    async function handleAvatarChange(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];

        if (authHeaders && userMoreInfo.nickname && file != undefined) {
            await dispatch(updateAvatar({ authHeaders: authHeaders,
                                          userNickname: userMoreInfo.nickname,
                                          patchBody: file}))
        }
    }

    async function handleUpdateGeneralConfig() {

        const patchBody = {
            name: inputNameValue,
            lastName: inputLastNameValue,
            nickname: inputNicknameValue,
            email: inputEmailValue,
            phoneNumber: inputPhoneNumberValue,
            website: inputWebsiteValue,
            city: inputCityValue,
            state: inputStateValue,
            country: inputCountryValue,
            about: inputAboutValue
        }

        if (authHeaders && userMoreInfo.nickname) {

            const response = await dispatch(updateGeneralConfig({ authHeaders: authHeaders,
                                                userNickname: userMoreInfo.nickname,
                                                patchBody: patchBody}))

            if (response.meta.requestStatus === 'fulfilled'){

                const routeParams = {
                    userNickname: response.payload['user_more_info'].nickname
                }
            
                const PRIVATE_ROUTES = await REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
            
                navigate(PRIVATE_ROUTES.USER_CONFIG)

            } else {

                response.payload.map((item: string) => {toast.error(item)})

            }
        }
    } 
    

    return(
        <>
            { loading 
            
            ?

            <LoadingModal/>

            :
            
            <div id='container-form-general-config'>
                <label id='label-title'>Change General Information</label>
                    <div id='container-inputs-form-general-config'>
                        <div id='container-profile-photo'>
                            <div id='container-photo'>
                                {avatar ?
                                <img src={`${avatar}`}/>
                                
                                :
                                
                                <img src={DefaultProfileImage}/>
                                }
                                
                            </div>

                            <button id='button-upload-new-profile-photo' onClick={() => document.getElementById('inputAvatar')?.click()}>
                                new profile photo
                            </button>
                            <input type="file" id="inputAvatar" onChange={handleAvatarChange} style={{ display: "none" }} />
                        </div>
                        <div id='container-label-input'>
                            <label>Name</label>
                                <input 
                                    name='inputName'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputNameValue}
                                    onChange={(event) => {setInputNameValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>Last Name</label>
                                <input 
                                    name='inputLastNameValue'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputLastNameValue}
                                    onChange={(event) => {setInputLastNameValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>Nickname</label>
                                <input 
                                    name='inputNickname'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputNicknameValue}
                                    onChange={(event) => {setInputNicknameValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>Contact e-mail</label>
                                <input 
                                    name='inputEmail'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputEmailValue}
                                    onChange={(event) => {setInputEmailValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>Phone Number</label>
                                <input 
                                    name='inputPhoneNumber'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputPhoneNumberValue}
                                    onChange={(event) => {setInputPhoneNumberValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>Website</label>
                                <input 
                                    name='inputWebsite'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputWebsiteValue}
                                    onChange={(event) => {setInputWebsiteValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>Country</label>
                                <input 
                                    name='inputCountry'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputCountryValue}
                                    onChange={(event) => {setInputCountryValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>State</label>
                                <input 
                                    name='inputState'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputStateValue}
                                    onChange={(event) => {setInputStateValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>City</label>
                                <input 
                                    name='inputCity'
                                    type="text"
                                    id='input-form-general-config'
                                    value={inputCityValue}
                                    onChange={(event) => {setInputCityValue(event.target.value)}}
                                />
                        </div>
                        <div id='container-label-input'>
                            <label>About</label>
                                <textarea 
                                    name='inputAbout'
                                    id='input-about-form-general-config'
                                    value={inputAboutValue}
                                    onChange={(event) => {setInputAboutValue(event.target.value)}}
                                    rows={5}
                                    cols={40}
                                    maxLength={500}
                                />
                        </div>
                    </div>
                    <div id='container-button-submit-general-configurations'>
                        <button id='button-submit-form-general-config'
                                onClick={handleUpdateGeneralConfig}>Submit</button>
                    </div>
            </div>

            }
        </>
    )
}