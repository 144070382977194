import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { ProjectPlayer } from "../../../../app/components/audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer";
import { format } from "date-fns";
import './projectsSearch.css';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { getSearchResults } from "../../../../app/features/sessions/sessionSearch/sliceSearch";
import { CircularProgress } from "@mui/material";

interface ProjectsSearch {
    searchType: string;
    query: string | null;
  }

export function ProjectsSearch({ searchType, query }: ProjectsSearch){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const [projectsSearchPage, setProjectsSearchPage] = useState(1);
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);
    const projects = useSelector((state: RootState) => state.sessionSearch.projects);
    const loadingMoreResults = useSelector((state: RootState) => state.sessionSearch.loadingMoreResults);
    const [isFetching, setIsFetching] = useState(false);
    
      useEffect(()=>{

        async function fetchProjectsData() {
            if (authHeaders && searchType && query && !isFetching){
                const response = await dispatch(getSearchResults({headers: authHeaders,
                                        routeParams: {
                                        queryType: searchType,
                                        query: query,
                                        page: projectsSearchPage
                                        }
                }))
                setIsFetching(false);
            }
        }
    
        fetchProjectsData();
    
        },[searchType,projectsSearchPage])

        useEffect(() => {
            function handleScroll() {
                if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return;
                setProjectsSearchPage(prevPage => prevPage + 1);
            }
    
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, [isFetching]);


    function handleFormatDate(date:any){
      const originalDate = new Date(date);
      const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
      return formattedDate
    }

    return(
        <>
            <div id='container-projects-search'>
                <ul>
                    {projects.length > 0 && projects.map((item: any, index :any) => (
                        <div id='container-li-search-project' key={index}>
                            <li id='li-project-search' key={index} >
                                <div id='container-title-description-project-search'>
                                    <div id='container-top-li-project-search'> 
                                        <div id='container-project-title'>
                                            <h3>{item.title}</h3>
                                        </div>
                                        <div id='container-privacity-project'>
                                            {item.privacity ==  0 ?
                                                <i style={{color: "#239400"}}>PUBLIC</i> 
                                                :
                                                <i style={{color:"#e70000"} }>PRIVATE</i>
                                            }
                                        </div>
                                        <div id='container-usability'>
                                            <div id='container-usability-label'>
                                                <label>Useful:</label>
                                                <h4>{item.nUsefulContents}</h4>
                                            </div>
                                            <div id='container-usability-label'>
                                                <label>Useless: </label>
                                                <h4>{item.nUselessContents}</h4>
                                            </div>
                                        </div>
                                        {/* <div id='project-actions'>
                                            <button id='button-more-details' onClick={() => {toggleDescriptionModal(item)}}>
                                                <img id='more-details-icon' src={MoreDetailsIcon}/>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                                    {
                                        item.masterFiles && item.masterFiles.length > 0 ? 
                                            <div id='container-project-player-projects'>
                                                <div id="container-player-projects-track-title">
                                                    <h5>{handleFormatDate(item.masterFiles[0].createdAt)}</h5>
                                                </div>
                                                <MemorizedProjectPlayer
                                                        fileUrl={`${item.masterFiles[0].signedUrl}`}/>
                                            </div>
                                            :
                                            null
                                    }
                            </li>
                        </div>
                    ))}
                </ul>
                <div id = 'container-nothing-to-show-projects-search'>
                    { projects.length == 0 && !loadingMoreResults ?
                        <h2>Nothing to show</h2>
                        :
                        null    
                    }
                </div>
                {loadingMoreResults == true
                    
                    ?
                        <div id='container-loading-spineer'>
                            <CircularProgress color='secondary' sx={{ color: 'brown' }}/>
                        </div>
                    
                    :
    
                    null
                }
            </div>
        </>
    )
}