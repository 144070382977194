export const ACCEPT = "application/vnd.api+json"
export const CONTENT_TYPE = "application/json"

export const SIGN_IN_HEADERS = {
    "accept": ACCEPT,
    "content-type": CONTENT_TYPE
}

export const SIGNIN_URL = "/oauth/token";
export const SIGNOUT_URL = "/oauth/revoke";
export const SIGNUP_ENDPOINT = "/auth";
export const REDEFINITION_MAIL = "/auth/password";
export const SIGNIN_ENDPOINT = "/auth/sign_in";
export const SIGNOUT_ENDPOINT = "/auth/sign_out";
export const VALIDATE_TOKEN_ENDPOINT = "/auth/validate_token";
export const SEARCH = "/search/:queryType";
export const INSTRUMENTS = "/instruments";
export const MIX_AUDIOS = '/mix_audios';
export const MUSICAL_GENRES = '/music_genres';

export const HOME_ENDPOINT = "/users/:userNicknameRoute/contents"
export const MANAGE_PROJECTS_ENDPOINT = "/users/:userNicknameRoute/projects"
export const MANAGE_PROJECT_ENDPOINT = "/users/:userNicknameRoute/projects/:projectIdRoute/contents"
export const PROFILE_ENDPOINT = "/users/:userNicknameRoute"
export const USER_CONFIG = "/users/:userNicknameRoute/config"
export const MEMBER_GROUPS_ENDPOINT = "/users/:userNicknameRoute/member_groups"
export const GROUP_ENDPOINT = "/users/:userNicknameRoute/groups/:groupId"
export const CREATE_FELLOWHIP = "/fellowships"
export const GET_CURRENT_USER_NOTIFICATIONS = "/notifications"

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export interface User {
    email?: string;
    provider?: string;
    uid?: string;
    id?: number;
    allowPasswordChange?: boolean;
    name?: string;
    profileImage?: string;
    nickname?: string;
    role?: string;
}

export interface Project {
    id: number;
    title?: string;
    description?: string;
    privacity?: number;
    lengthUsefulContents?: number;
    lengthUselessContents?: number;
    lengthVisits?: number;
    ownerId?: number;
    createdAt?: string;
    updatedAt?: string;
    loading?: true;
}

export interface RouteParams {
    userNickname?: string | number;
    projectId?: string | number;
    projectContentId?: string | number;
    currentProejctId?: string | number;
    propertyToSearch?: string;
    searchTerm?: string;
    page?: string | number;
    groupId?: string | number;
    postId?: string | number;
    postCommentsPage?: string | number;
    postCommentId?: string | number;
    projectContentCommentsPage?: string | number;
    projectContentCommentId?: string | number;
    instrumentId?: string | number;
    userInstrumentId?: string | number;
    musicGenreId?: string | number;
    userMusicalGenreId?: string | number;
    queryType?: string;
    searchInputQuery?: string | number;
    query?: string;
    contentsPage?: number | string;
    contentId?: number;
    currentUserId?: number;
    profileNickname?: string;
}

export interface EndPoints {
    HOME?: string;
    MY_CONTENTS?: string;
    GET_USER_PROJECTS?: string;
    USER_PROJECTS?: string;
    MANAGE_MY_PROJECTS?: string;
    PROJECT_CONTENTS?: string;
    PROJECT_CONTENT?: string;
    USER?: string;
    USER_CONFIG?: string;
    UPDATE_GENERAL_CONFIG?: string;
    GET_GENERAL_CONFIG?: string;
    PRIVACITY_CONFIG?: string;
    MEMBER_GROUPS?: string;
    GROUPS?: string;
    GET_MEMBER_GROUPS?: string;
    GROUP_DELETE_UPDATE_SHOW?: string;
    POST_CREATE?: string;
    SHOW_POST_WITH_COMMENTS?: string;
    GET_GROUP_POSTS?: string;
    CREATE_POST_COMMENT?: string;
    DELETE_POST_COMMENT?: string;
    POST_DELETE?: string;
    GET_PROJECT_CONTENT_COMMENTS?: string;
    CREATE_PROJECT_CONTENT_COMMENT?: string;
    DELETE_PROJECT_CONTENT_COMMENT?: string;
    PATCH_USER_INSTRUMENT?: string;
    PATCH_USER_MUSICAL_GENRE?: string;
    GET_CURRENT_USER_FELLOWS?: string;
    DELETE_CURRENT_USER_FELLOW?: string;
}

export interface PrivateRoutesParams {
    ROUTE_PARAMS: RouteParams;
}

export function handlePrivateRoutes({ROUTE_PARAMS}: PrivateRoutesParams){

    const ENDPOINTS = {
            HOME: `/users/${ROUTE_PARAMS?.userNickname}/contents`,
            CREATE_CONTENT: `/users/${ROUTE_PARAMS?.userNickname}/contents`,
            USER_CONTENTS: `/users/${ROUTE_PARAMS?.userNickname}/contents/${ROUTE_PARAMS?.contentsPage}`,
            USER_PROJECTS: `/users/${ROUTE_PARAMS?.userNickname}/projects`,
            UPDATE_USER_CONTENT: `/users/${ROUTE_PARAMS?.userNickname}/contents/${ROUTE_PARAMS?.contentId}`,
            DELETE_USER_CONTENT: `/users/${ROUTE_PARAMS?.userNickname}/contents/${ROUTE_PARAMS?.contentId}`,
            GET_USER_PROJECTS: `/users/${ROUTE_PARAMS?.userNickname}/projects/${ROUTE_PARAMS?.page}`,
            CREATE_PROJECT: `/users/${ROUTE_PARAMS?.userNickname}/projects`,
            PROJECT_CONTENTS: `/users/${ROUTE_PARAMS?.userNickname}/projects/${ROUTE_PARAMS?.projectId}/contents`,
            PROJECT_CONTENT: `/users/${ROUTE_PARAMS?.userNickname}/project_content/${ROUTE_PARAMS?.projectContentId}`,
            PROJECT: `/users/${ROUTE_PARAMS?.userNickname}/projects/${ROUTE_PARAMS?.projectId}`,
            POST_FILE_PROJECT: `/users/${ROUTE_PARAMS?.userNickname}/projects/${ROUTE_PARAMS?.projectId}/files`,
            SEARCH: `/search/${ROUTE_PARAMS?.propertyToSearch}?q=${ROUTE_PARAMS?.searchTerm}`,
            USER: `/users/${ROUTE_PARAMS?.userNickname}`,
            USER_CONFIG: `/users/${ROUTE_PARAMS?.userNickname}/config`,
            MERGED_AUDIO_PROJECT: `/merged_audio/${ROUTE_PARAMS?.projectId}`,
            UPDATE_GENERAL_CONFIG: `/users/${ROUTE_PARAMS?.userNickname}/general_config`,
            GET_GENERAL_CONFIG: `/users/${ROUTE_PARAMS?.userNickname}`,
            PRIVACITY_CONFIG: `/users/${ROUTE_PARAMS?.userNickname}/privacity_config`,
            MEMBER_GROUPS: `/users/${ROUTE_PARAMS?.userNickname}/member_groups`,
            GET_MEMBER_GROUPS:`/users/${ROUTE_PARAMS?.userNickname}/member_groups/page/${ROUTE_PARAMS?.page}`,
            GROUPS: `/users/${ROUTE_PARAMS?.userNickname}/groups`,
            GROUP_DELETE: `/groups/${ROUTE_PARAMS?.groupId}`,
            GROUP_UPDATE_SHOW: `/users/${ROUTE_PARAMS?.userNickname}/groups/${ROUTE_PARAMS?.groupId}`,
            POST_CREATE: `/users/${ROUTE_PARAMS?.userNickname}/groups/${ROUTE_PARAMS?.groupId}/posts`,
            POST_DELETE: `/users/${ROUTE_PARAMS?.userNickname}/groups/${ROUTE_PARAMS?.groupId}/posts/${ROUTE_PARAMS?.postId}`,
            SHOW_POST_WITH_COMMENTS: `/users/${ROUTE_PARAMS?.userNickname}/groups/${ROUTE_PARAMS?.groupId}/posts/${ROUTE_PARAMS?.postId}/post_comments/page/${ROUTE_PARAMS?.postCommentsPage}`,
            GET_GROUP_POSTS: `/users/${ROUTE_PARAMS?.userNickname}/groups/${ROUTE_PARAMS?.groupId}/posts/page/${ROUTE_PARAMS?.page}`,
            CREATE_POST_COMMENT: `/users/${ROUTE_PARAMS?.userNickname}/posts/${ROUTE_PARAMS?.postId}/post_comments`,
            DELETE_POST_COMMENT: `/users/${ROUTE_PARAMS?.userNickname}/groups/${ROUTE_PARAMS?.groupId}/posts/${ROUTE_PARAMS?.postId}/post_comments/${ROUTE_PARAMS?.postCommentId}`,
            GET_PROJECT_CONTENT_COMMENTS: `/user/${ROUTE_PARAMS?.userNickname}/project_content/${ROUTE_PARAMS?.projectContentId}/project_content_comments/page/${ROUTE_PARAMS?.projectContentCommentsPage}`,
            CREATE_PROJECT_CONTENT_COMMENT: `/user/${ROUTE_PARAMS?.userNickname}/project_content/${ROUTE_PARAMS?.projectContentId}/project_content_comments`,
            DELETE_PROJECT_CONTENT_COMMENT: `/user/${ROUTE_PARAMS?.userNickname}
                                            /project_content/${ROUTE_PARAMS?.projectContentId}
                                            /project_content_comments/${ROUTE_PARAMS?.projectContentCommentId}`,
            GET_USER_MUSICAL_GENRES: `/user/${ROUTE_PARAMS?.userNickname}/musical_genres`,
            GET_USER_INSTRUMENTS: `/user/${ROUTE_PARAMS?.userNickname}/user_instruments`,
            PATCH_USER_INSTRUMENT: `/user/${ROUTE_PARAMS?.userNickname}/user_instruments/${ROUTE_PARAMS?.userInstrumentId}`,
            PATCH_USER_MUSICAL_GENRE: `/user/${ROUTE_PARAMS?.userNickname}/user_musical_genres/${ROUTE_PARAMS?.userMusicalGenreId}`,
            SEARCH_BAR_NAVIGATION: `/search/${ROUTE_PARAMS?.queryType}?q=${ROUTE_PARAMS?.searchInputQuery}`,
            GET_SEARCH: `/search/${ROUTE_PARAMS?.queryType}/page/${ROUTE_PARAMS?.page}?q=${ROUTE_PARAMS?.query}`,
            GET_CURRENT_USER_FELLOWS: `/user/${ROUTE_PARAMS?.currentUserId}/fellowships`,
            PATCH_CURRENT_USER_FELLOW: `/user/${ROUTE_PARAMS?.currentUserId}/fellowship/${ROUTE_PARAMS?.profileNickname}`
        }

    return ENDPOINTS
}