import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { createCommentInPost } from "../../../../features/sessions/sessionPostProject/sessionPostProject";
import { toast } from 'react-toastify';
import './createCommentInPostForm.css'

export default function CreateCommentInPostForm({ postId }: { postId: string }){
    const [inputComment, setInputComment] = useState<string>('');
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const errors = useSelector((state: RootState) => state.sessionProjectPosts.errors);
    const dispatch = useDispatch<AppDispatch>();

    async function handleCommentPost(){

        if (authHeaders && currentUser && currentUser.nickname){
            const response = await dispatch(createCommentInPost({authHeaders: authHeaders,
                                                                    createCommentInPostRouteParams: {
                                                                        userNickname: currentUser.nickname,
                                                                        postId: postId
                                                                    },
                                                                    body: {
                                                                        postId: postId,
                                                                        body: inputComment
                                                                    }
                                                                }));

            if (response.meta.requestStatus === 'fulfilled'){
                setInputComment('');
            }

            if (response.payload.errors && response.payload.errors.length > 0 ) {
                response.payload.errors.map((item: string) => {toast.error(item)})
            }
        }
    }

    return(
        <div id='container-create-comment'>
            <textarea
                name='inputComment'
                value={inputComment} 
                onChange={(event) => {setInputComment(event.target.value)}}
            />

            <div id='container-button-create-comment'>
                <button id='button-create-comment' onClick={handleCommentPost}>
                    Comment
                </button>
            </div>
        </div>
    )
}