import React, { useEffect } from 'react';
import './addRemoveUserInstruments.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { addRemoveUserInstrument, getInstruments, getUserInstruments, patchUserInstrument } from '../../../../features/sessions/sessionProfileNavigation';
import { Instrument, UserInstrument } from '../../../../#interfaces/interfaces';

export default function AddRemoveUserInstruments({userNickname,onClose}:any){
    const userInstruments = useSelector((state: RootState) => state.sessionProfile.userInstruments);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    
    useEffect(() => {


        async function fetchUserInstruments() {
            if (authHeaders){
                const response = await dispatch(getUserInstruments({
                                                                    headers: authHeaders,
                                                                    urlParams:{
                                                                        userNickname: userNickname
                                                                    }
                                                                   }
                                                                  )
                                                                )
                                                            }
                                                                
                                                        }
        
        fetchUserInstruments();

    },[])

    async function handleUpdateUserInstrument(userInstrumentId: number, selected: boolean) {

        if (authHeaders && userNickname)
        {
            await dispatch(addRemoveUserInstrument({userInstrumentId: userInstrumentId,
                                                    selected: selected
            }))

            const response = await dispatch(patchUserInstrument(
                                                                 {headers: authHeaders,
                                                                  urlParams: {
                                                                    userNickname: userNickname,
                                                                    userInstrumentId: userInstrumentId
                                                                  },
                                                                  requestBodyData: {
                                                                    selected: selected
                                                                  }
                                                                 }
                                                                )
                                            )
            
        }
    }

    return(
            <>
                <div id='container-add-remove-user-instruments-background-modal'>
                    <div id='container-screen-transparent-add-remove-user-instruments-modal'>
                        <div id='container-add-remove-user-instruments-modal'>
                            <div id='container-top-add-remove-user-instruments-modal'>
                                <h2>Genres Preferences</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-add-remove-user-instruments-modal'>
                                <ul>
                                    {userInstruments && userInstruments.map((userInstrument: UserInstrument, index) => {
                                        const isSelected = userInstrument.selected === true;
                                        return (
                                        <React.Fragment key={index}>
                                            {isSelected ? (
                                            <li id='li-user-instruments-already-selected'
                                                key={`${index}-selected`} 
                                                onClick={() => {
                                                        handleUpdateUserInstrument(userInstrument.id, false);
                                                }}>
                                                <h3>
                                                    {userInstrument.instrument.name}
                                                </h3>
                                            </li>
                                            ) : (
                                            <li id='li-instruments-available'
                                                key={`${index}-available`} 
                                                onClick={() => handleUpdateUserInstrument(userInstrument.id, true)}>
                                                <h3>
                                                    {userInstrument.instrument.name}
                                                </h3>
                                            </li>
                                            )}
                                        </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}