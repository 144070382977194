import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState, AppDispatch } from '../app/store';
import { authenticateUserFromStorage } from '../app/features/sessions/sessionSlice';
import LoadingModal from '../app/components/modal/loadingModal/LoadingModal';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const currentUser = useSelector((state: RootState) => state.session.currentUser);
  const loading = useSelector((state: RootState) => state.session.loading);
  const loggedIn = useSelector((state: RootState) => state.session.loggedIn);
  const location = useLocation();
  const fromLocation = (location.state as any)?.from || { pathname: '/auth/sign_in' };
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!loggedIn) {
      dispatch(authenticateUserFromStorage());
    }
  }, [loggedIn, dispatch]);

  if (loading) {
    return <LoadingModal/>
  }

  if (loggedIn && currentUser) {
    return children;
  }

  return <Navigate to={fromLocation} state={{ from: location }} replace />;
}

export default PrivateRoute;