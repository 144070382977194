import { useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";

// WaveSurfer hook
export const useWavesurfer = (containerRef: any, options: any) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>()

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!containerRef.current) return

    const ws = WaveSurfer.create({
      ...options,
      height: 80,
      barHeight: 0.5,
      waveColor: 'rgb(153, 153, 153)',
      progressColor: 'rgb(0, 0, 0)',
      cursorColor: 'rgb(255, 165, 0)',
      container: containerRef.current,
      scrollParent: true,
    })

    containerRef.current.style.width = '100%';
    containerRef.current.style.height = '100%';

    setWavesurfer(ws)

    return () => {
      ws.destroy()
    }
  }, [options, containerRef])

  return wavesurfer
}