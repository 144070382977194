import LoadingAnimation from '../LoadingAnimation';
import './loadingModalScreenContainer.css';

function LoadingModalScreenContainer(){
    return(
            <div id='container-loading-modal-screen'>
                <LoadingAnimation/>
            </div>
    )
}

export default LoadingModalScreenContainer;