import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './myContentsFooter.css';
import { AppDispatch, RootState } from '../../store';
import { useEffect } from 'react';
import { authenticateUserFromStorage } from '../../features/sessions/sessionSlice';
import * as Types from '../../#interfaces/interfaces';
import UploadIconWhite from '../../assets/upload-white.png';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentPlayer } from '../audioPlayer/WaveSurfer/contentPlayer/ContentPlayer';
import { getUserContents } from '../../features/sessions/sessionAudioContent/sliceUserContents';
import * as REQUEST_REQUIREMENTS from '../../api/requestRequirements';
import { toast } from 'react-toastify';
import { postProjectContent } from '../../features/sessions/projectsManagement/sessionManageProject';
import LoadingAnimation from '../modal/LoadingAnimation';
import slideUpIcon from '../../assets/slide-up-icon.png';
import { CircularProgress } from '@mui/material';

export default function MyContentsFooter(){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const projectSelected = useSelector((state: RootState) => state.session.loggedIn);
    const contents = useSelector((state: RootState) => state.sessionUserContents.userContents);
    const newcontents = useSelector((state: RootState) => state.sessionUserContents.newMyContents);
    const errors = useSelector((state: RootState) => state.sessionManageProjectContents.errors);
    const dispatch = useDispatch<AppDispatch>();
    const { userNicknameRoute, projectIdRoute } = useParams();
    const navigate = useNavigate();
    const loading = useSelector((state: RootState) => state.sessionUserContents.loading);
    const [slideUpClicked, setSlideUpClicked] = useState(false);
    const MemorizedContentPlayer = useMemo(() => React.memo(ContentPlayer), []);
    const [contentsPage, setContentsPage] = useState(1);
    const loadingMoreContents = useSelector((state: RootState) => state.sessionUserContents.loadingMoreContents);

    useEffect(() => {

        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }

        async function handleMyContents(authHeaders: any, currentUser: any) {
            await dispatch(getUserContents({authHeaders: authHeaders,
                                          routeParams: 
                                          {userNickname: currentUser?.nickname,
                                          contentsPage: contentsPage}}))
        }

        authenticateUser();
        handleMyContents(authHeaders, currentUser);

    }, [contentsPage]);

    async function handleUploadProjectContent(item: Types.AudioContent) {

        if (projectIdRoute){
            const postProjectContentParams = {
                                                authHeaders: authHeaders,
                                                projectContentsRouteParams: {
                                                                            userNicknameRoute: userNicknameRoute,
                                                                            projectIdRoute: projectIdRoute },
                                                postBody: {id: item.id}}

             await dispatch(postProjectContent(postProjectContentParams))

            if (errors.length == 0){

                const routeParams = {
                    userNickname: userNicknameRoute,
                    projectId: projectIdRoute
                }

                const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
                navigate(PRIVATE_ROUTES.PROJECT_CONTENTS)
            } else {
                errors.map((item) => {toast.error(item)})
                
            }
        }
    }

    function handleSlideUpClick(){
        setSlideUpClicked(!slideUpClicked);
    }

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('my-contents-footer-container');
            if (!container) return;

            const { scrollLeft, clientWidth, scrollWidth } = container;
            if (scrollLeft + clientWidth >= scrollWidth && !loadingMoreContents) {
                setContentsPage(contentsPage + 1);
            }
        };

        setTimeout(() => {
            const container = document.getElementById('my-contents-footer-container');
            if (container){
                container.addEventListener('scroll', handleScroll);
                return () => container.removeEventListener('scroll', handleScroll);
            }
        }, 100);
    }, [loadingMoreContents]);

    return(
        <>
        <div id='my-contents-footer-container' style={{ bottom: slideUpClicked ? '0' : '-110px' }}>
            <div id='container-button-contents'>
                <div id='container-ul-audios-footer'> 
                    <ul id='ul-audio-contents'>            
                        <div id='container-li-contents-footer'>
                        {newcontents.length > 0 && newcontents.map((item, index) => (
                                    <li id='li-contents-footer' key={index}>
                                        <div id="container-content-footer-instrument-name">
                                            {item.instrument ?
                                                <label>{item.instrument && item.instrument.name}</label>
                                                :
                                                <label>other</label>
                                            }  
                                        </div>
    
                                        <div id="container-title-player-content-footer">
                                            <div id="container-conteiner-title-content-footer">
                                                <div id="container-title-content-footer">
                                                    <label id='title-content-footer-label'>
                                                        {item.title}
                                                    </label>
                                                </div>
                                            </div>
                                            <div id='container-wave-surfer-player-my-contents-footer'>
                                                <MemorizedContentPlayer
                                                    fileUrl={`${item.fileUrl}`}
                                                />
                                            </div>
                                        </div>
                                        <div id='container-button-attach-content-footer'>
                                            <button id="button-attach-content" onClick={() => { handleUploadProjectContent(item);
                                                                                                handleSlideUpClick();
                                                                                        }}>
                                                <img id='upload-img' alt="GIF" src={UploadIconWhite} />
                                            </button>
                                        </div>
                                    </li>
                            ))}
                            {contents.length > 0 && contents.map((item, index) => (
                                    <li id='li-contents-footer' key={index}>
                                        <div id="container-content-footer-instrument-name">
                                            {item.instrument ?
                                                <label>{item.instrument && item.instrument.name}</label>
                                                :
                                                <label>other</label>
                                            }  
                                        </div>
    
                                        <div id="container-title-player-content-footer">
                                            <div id="container-conteiner-title-content-footer">
                                                <div id="container-title-content-footer">
                                                    <label id='title-content-footer-label'>
                                                        {item.title}
                                                    </label>
                                                </div>
                                            </div>
                                            <div id='container-wave-surfer-player-my-contents-footer'>
                                                <MemorizedContentPlayer
                                                    fileUrl={`${item.fileUrl}`}
                                                />
                                            </div>
                                        </div>
                                        <div id='container-button-attach-content-footer'>
                                            <button id="button-attach-content" onClick={() => { handleUploadProjectContent(item);
                                                                                                handleSlideUpClick();
                                                                                        }}>
                                                <img id='upload-img' alt="GIF" src={UploadIconWhite} />
                                            </button>
                                        </div>
                                    </li>
                            ))}
                            </div>

                            {loadingMoreContents

                                ?
                                
                                <div id='container-loading-spineer'>
                                    <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                </div>

                                :

                                null
                            }
                    </ul>
                </div>
                <div id='container-button-footer-slide-up'>
                    <button onClick={handleSlideUpClick}>
                        <img style={{ transform: slideUpClicked ? 'rotate(180deg)' : 'rotate(0deg)'}} src={slideUpIcon} />
                    </button>
                </div>
            </div>
        </div>
    </>
    
    )
}